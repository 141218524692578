import React, {Component} from "react";
import Map from "../../components/Map/Map";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import GoogleProductMarker from "../../components/GoogleMarker/GoogleProductMarker";
import supercluster from 'points-cluster';
import ClusterMarker from "../../components/GoogleMarker/ClusterMarker";


class MapHolder extends Component {
    state = {
        lat: 48.247590,
        searchLat: 48.247590,
        lng: 14.303290,
        bounds: null,
        zoom: 14,
        products: [],
        clusters: []
    }

    static defaultProps = {
        categoryIDs: [],
        minPrice: 0,
        maxPrice: 0,
        title: ''
    }

    mapProducts() {
        return this.state.products.map((item) => ({
            id: item.ID,
            lat: item.Latitude,
            lng: item.Longitude,
            ID: item.ID,
            Latitude: item.Latitude,
            Longitude: item.Longitude,
            Title: item.Title,
            PreviewImage: item.PreviewImage,
            CategoryTitle: item.CategoryTitle,
            Price: item.Price,
            linkTo: '/produkt/' + item.ID + '/' + item.URLTitle,
            linkTitle: 'Produkt anzeigen',
        }));
    }

    getClusters = props => {
        const clusters = supercluster(this.mapProducts(), {
            minZoom: 0,
            maxZoom: 20,
            radius: 60,
        });
        let data = clusters({
            center: {lat: this.state.lat, lng: this.state.lng},
            zoom: this.state.zoom,
            bounds: this.state.bounds
        });
        return data
    };

    createClusters = props => {
        this.setState({
            clusters: this.state.bounds
                ? this.getClusters(props).map(({wx, wy, numPoints, points}) => ({
                    lat: wy,
                    lng: wx,
                    numPoints,
                    id: `${numPoints}_${points[0].id}`,
                    points,
                }))
                : [],
        });
    };

    handleMapChange = ({center, zoom, bounds}) => {
        this.setState(
            {
                lat: center.lat,
                lng: center.lng,
                zoom: zoom,
                bounds: bounds,
            },
            () => {
                this.createClusters(this.props);
            }
        );
    };


    componentDidMount() {
        const bounds = new window.google.maps.LatLngBounds();
        this.setState({
            bounds: bounds
        }, () => this.loadProducts())

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.coords.latitude && (prevState.lat !== this.props.coords.latitude || this.props.coords.longitude != prevState.lng)) {
            this.setState({
                lat: this.props.coords.latitude,
                searchLat: this.props.coords.latitude,
                lng: this.props.coords.longitude,
            })
        }
        if ((JSON.stringify(prevProps.categoryIDs) !== JSON.stringify(this.props.categoryIDs)) ||
            (prevProps.minPrice !== this.props.minPrice) ||
            (prevProps.maxPrice !== this.props.maxPrice) ||
            (prevProps.title !== this.props.title)
        ) {
            this.loadProducts()
        }
    }


    loadProducts() {
        let filter = '&filter[Hidden]=0';
        this.props.categoryIDs.map((item) => {
            filter += '&filter[CategoryID][]=' + item
            return '';
        })

        filter += '&filter[Price:GreaterThanOrEqual]=' + this.props.minPrice;
        if (this.props.maxPrice) {
            filter += '&filter[Price:LessThanOrEqual]=' + this.props.maxPrice;
        }

        filter += '&filterAny[Title:PartialMatch]=' + this.props.title;
        filter += '&filterAny[Description:PartialMatch]=' + this.props.title;

        if (this.props.saleOnly) {
            filter += '&filter[Sale]=' + this.props.saleOnly
        }

        ApiService.client.get('/Product/?filter[VendorID:not]=0' + filter+'&mapData=1')
            .then((result) => {
                this.setState({
                    products: result.data
                })
                if (typeof this.props.setCountFunction === 'function') {
                    this.props.setCountFunction(result.data.length);
                }
                this.createClusters()
            })
            .catch((error) => {
                if (this.props.dsgvo.functionalCookies) {
                    let msg = 'Ein Fehler ist aufgetreten'
                    if (error.response && error.response.data && error.response.data.message) {
                        msg = error.response.data.message
                    }
                    this.setState({loading: false});
                    toastr.error('Fehler laden der Produkte', msg)
                }
            })
    }


    render() {
        return (
            <Map
                center={{lat: this.state.lat, lng: this.state.lng}}
                zoom={this.state.zoom}
                handleMapChange={this.handleMapChange}
                searchLat={this.state.searchLat}
            >
                {this.state.clusters.map(item => {
                    if (item.numPoints === 1) {
                        return (
                            <GoogleProductMarker
                                key={item.points[0].ID}
                                lat={item.points[0].Latitude}
                                lng={item.points[0].Longitude}
                                text={item.points[0].Title}
                                title={item.points[0].Title}
                                image={item.points[0].PreviewImage}
                                desc={item.points[0].CategoryTitle}
                                price={item.points[0].Price.toFixed(2)}
                                linkTo={'/produkt/' + item.points[0].ID + '/' + item.points[0].URLTitle}
                                linkTitle={'Produkt anzeigen'}
                            />
                        );
                    }

                    return (
                        <ClusterMarker
                            key={item.id}
                            lat={item.lat}
                            lng={item.lng}
                            points={item.points}
                        />
                    );
                })}
            </Map>
        );
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
    dsgvo: state.dsgvo
});


export default connect(
    mapStateToProps,
)(MapHolder);
