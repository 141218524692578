import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Row} from 'react-bootstrap';
import Icofont from "../../components/Icofont/Icofont";
import {ApiService} from "../../services/ApiService";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class OrderCard extends React.Component {

    state = {
        vendorOrders: [],
        remarks: '',
        billingAddress: '',
        shippingAddress: '',
        vendorLoading: true,
        total: 0
    }

    componentDidMount() {
        this.fetchBillingAddress();
        this.fetchShippingAddress();
        this.getVendorOrders();
    }

    getVendorOrders() {
        let filter = '?filter[ID][]=0';
        if (typeof this.props.order.VendorOrders !== "undefined") {
            this.props.order.VendorOrders.map((item) => {
                filter += '&filter[ID][]=' + item.ID
                return '';
            })
            ApiService.authorizedClient(this.props.authKey).get("/VendorOrder/" + filter)
                .then((response) => {
                    this.setState({
                        vendorOrders: response.data,
                    }, () => this.getVendorOrderOrderItem());
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message, loading: false});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                    }
                })
        }

    }

    getVendorOrderOrderItem() {
        const me = this;
        this.state.vendorOrders.forEach((item) => {
            let filter = '?filter[ID][]=0';
            item.OrderItems.map((item) => {
                filter += '&filter[ID][]=' + item.ID
                return '';
            })
            ApiService.authorizedClient(this.props.authKey).get("/OrderItem/" + filter)
                .then((response) => {
                    item.OrderItems = response.data;
                    me.setState({
                        vendorLoading: false
                    })
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message, loading: false});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                    }
                })
        })
    }

    fetchBillingAddress() {
        this.setState({loading: true});
        ApiService.authorizedClient(this.props.authKey).get("/Address/" + this.props.order.BillingAddressID)
            .then((response) => {
                this.setState({
                    billingAddress: response.data.Title,
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    fetchShippingAddress() {
        this.setState({loading: true});
        ApiService.authorizedClient(this.props.authKey).get("/Address/" + this.props.order.ShippingAddressID)
            .then((response) => {
                this.setState({
                    shippingAddress: response.data.Title,
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    render() {
        return (
            <div className="bg-white card mb-4 order-list shadow-sm">
                <div className="gold-members p-4">
                    {this.props.orderPaidDate ?
                        (
                            <span className="float-right text-info">Bezahlt am {this.props.orderPaidDate}
                                <Icofont icon="check-circled" className="text-success ml-1"/>
			                      </span>
                        )
                        : ""
                    }
                    <p className="text-gray mb-3">
                        <Icofont icon="list"/> Bestellung: {this.props.orderNumber}
                        <Icofont icon="clock-time" className="ml-2"/> {this.props.orderDate}
                    </p>
                    <p className="text-gray mb-1">
                        <Icofont icon="location-pin"/> Rechnungsadresse: {this.state.billingAddress}<br/>
                        <Icofont icon="location-pin"/> Lieferadresse: {this.state.shippingAddress}
                    </p>

                    <p className="text-dark">
                        {this.props.orderProducts}
                    </p>
                    <p className="text-dark">
                        Anmerkungen: {this.props.order.Remarks ?? '----'}
                    </p>
                    <hr/>
                    <Row>
                        <Col xs={6}>
                            <p className="mb-0 text-black text-primary pt-2">
                                    <span
                                        className="text-black font-weight-bold"> Gesamt:</span> €{parseFloat(this.props.order.OrderTotal).toFixed(2)}
                            </p>
                        </Col>
                        <Col xs={6} className='d-flex justify-content-end'>
                            <Button className='btn btn-primary' as={Link}
                                    to={'/myaccount/order/' + this.props.order.ID}>DETAILS</Button>
                        </Col>
                    </Row>

                </div>
            </div>
        );
    }
}

OrderCard
    .propTypes = {
    image: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
    orderNumber: PropTypes.string.isRequired,
    orderDate: PropTypes.string.isRequired,
    deliveredDate: PropTypes.string,
    orderTitle: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    orderProducts: PropTypes.string.isRequired,
    helpLink: PropTypes.string.isRequired,
    detailLink: PropTypes.string.isRequired,
    orderTotal: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
});


export default connect(
    mapStateToProps,
    null,
)(OrderCard);
