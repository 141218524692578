import React, {Component} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import LazyImage from "../LazyImage/LazyImage";
import {Link} from "react-router-dom/";
import Icofont from "../Icofont/Icofont";

export default class GoogleMarker extends Component {

    static defaultProps = {};

    state = {
        show: false,
        elementHeight: 0,
    }

    recalcHeight() {
        let elementHeight = this.ref.clientHeight;
        this.setState({
            elementHeight: elementHeight
        })
    }

    componentDidMount() {
        this.recalcHeight();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.show !== this.state.show) {
            this.recalcHeight();
        }
    }

    render() {
        if (!this.state.show) {
            return (
                <span className='customPin' onClick={() => this.setState({show: !this.state.show})}>
                    <span className='pin-fill'/>
                     <Icofont
                         ref={ref => this.ref = ref}
                         icon="location-pin"
                         className="map-pin"
                     />
                </span>
            );
        }

        return (
            <div className={'customMarker p-3'}
                 ref={ref => this.ref = ref}
                 style={{top: -(this.state.elementHeight + 20)}}
                 onClick={() => this.setState({show: !this.state.show})}
            >
                <Icofont
                    icon='close-line'
                    className='closeMapPin'
                />
                {this.state.show ?
                    <Row>
                        <Col xs={5} className='pr-1'>
                            {this.props.image ?
                                <LazyImage src={this.props.image} fluid/>
                                :
                                null}
                        </Col>
                        <Col xs={7} className='d-flex align-items-center pl-1'>
                            <h6>{this.props.title}</h6>
                        </Col>
                        {this.props.address ?
                            <Col xs={12} className='mt-3'>
                                <Icofont icon="location-pin"/> {this.props.address}
                            </Col>
                            :
                            null
                        }
                        {this.props.desc ?
                            <Col xs={12} className='mt-2'>{this.props.desc}</Col>
                            :
                            null
                        }
                        {this.props.linkTo ?
                            <Col xs={12} className='mt-3 text-center'>
                                <Button
                                    as={Link}
                                    className='btn btn-primary d-block'
                                    to={this.props.linkTo}>{this.props.linkTitle ?? 'Hier klicken'}
                                </Button>
                            </Col>
                            :
                            null
                        }
                    </Row>
                    :
                    null
                }
            </div>
        );
    }
}