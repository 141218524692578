// @flow
import React from 'react';
import {Col, Container, Image, Row} from 'react-bootstrap';
import {Link} from "@reach/router";
import NotFoundImage from "../../assets/img/404.png";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";

export default class NotFound extends React.Component {

    render() {
        return (
            <section className="section pt-5 pb-5 osahan-not-found-page">
                <CustomMetaTags
                    title={'Regionlinemarkt - Seite nicht gefunden!'}
                />
                <Container>
                    <Row>
                        <Col md={12} className="text-center pt-5 pb-5">
                            <Image className="img-fluid mb-4" style={{maxWidth: "500px"}} src={NotFoundImage}
                                   alt="404"/>
                            <h1 className="mt-2 mb-2">Seite nicht gefunden</h1>
                            <p>Oh-oh! Sieht so aus als würde die Seite nicht existieren. Bitte versuche es
                                nocheinmal.</p>
                            <Link className="btn btn-primary btn-lg" to="/">ZUR STARTSEITE</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}
