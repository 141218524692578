import React from 'react';
import {Button, Col, Form, Modal, Row, Spinner} from 'react-bootstrap';
import {toastr} from 'react-redux-toastr'
import BookmarkCollectionAction from "../../stores/bookmarkCollections/actions";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";

class AddToBookmarkCollectionModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCollectionID: 0,
            selectedCollectionTitle: '',
        }

        this.handleCollectionChange = this.handleCollectionChange.bind(this);
        this.addProduct = this.addProduct.bind(this);
    };

    handleCollectionChange(e) {
        var index = e.target.selectedIndex;
        this.setState({
            selectedCollectionID: e.target.value,
            selectedCollectionTitle: e.target[index].text ?? '',
        });

    }

    addProduct() {
        this.props.addItem(this.state.selectedCollectionID, this.props.productID,)
        toastr.success('"' + this.props.productTitle + '" wurde hinzugefügt', this.state.selectedCollectionTitle)
        this.props.onHide();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.collectionsErrorMessage !== this.props.collectionsErrorMessage) {
            if (this.props.collectionsErrorMessage) {
                toastr.error('Fehler', this.props.collectionsErrorMessage)
            }
        }
        if (!this.state.selectedCollectionID && typeof this.props.bookmarkCollections[0] !== 'undefined') {
            this.setState({
                selectedCollectionID: this.props.bookmarkCollections[0].ID,
                selectedCollectionTitle: this.props.bookmarkCollections[0].Title,
            })
        }
        if (typeof this.props.bookmarkCollections[0] !== 'undefined') {
            if (JSON.stringify(prevProps.bookmarkCollections) !== JSON.stringify(this.props.bookmarkCollections)) {
                this.setState({
                    selectedCollectionID: this.props.bookmarkCollections[0].ID,
                    selectedCollectionTitle: this.props.bookmarkCollections[0].Title,
                },)

            }
        }
        if (this.props.memberID && this.props.show && prevProps.show !== this.props.show) {
            this.props.fetchCollections()
        }
    }

    componentDidMount() {
        if (this.props.memberID && this.props.show) {
            this.props.fetchCollections()
        }

    }


    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                centered
                size="sm"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title as='h5' id="delete-address">Zur Merkliste</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {!this.props.memberID ?
                        <>
                            <p className="mb-0 text-black">Für Merklisten musst du angemeldet sein</p>
                            <Link to='/Login'>Anmelden</Link>
                        </>
                        :
                        this.props.collectionsAreLoading ?
                            <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            :
                            this.props.bookmarkCollections.length ?
                                <Form.Group controlId="title" className='m-0 d-flex'>
                                    <Form.Control as="select"
                                                  value={this.state.selectedCollectionID}
                                                  onChange={this.handleCollectionChange}
                                    >
                                        {this.props.bookmarkCollections.map((item) =>
                                            <option key={item.ID}
                                                    value={item.ID}>{item.Title + ' (' + item.Count + ')'}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                                :
                                <p>Du hast noch keine Merkliste</p>
                    }
                </Modal.Body>

                <Modal.Footer>
                    {!this.props.memberID ?
                        null
                        :
                        <Row>

                            <Col md={6}>
                                <Button
                                    type='button'
                                    variant="primary"
                                    className='d-flex text-center justify-content-center'
                                    onClick={() =>
                                        this.addProduct()
                                    }
                                >Hinzufügen</Button>
                            </Col>
                            <Col md={6}>
                                <Button type='button' onClick={this.props.onHide} variant="outline-primary"
                                        className="d-flex text-center justify-content-center">ABBRECHEN</Button>
                            </Col>
                        </Row>}

                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    bookmarkCollections: state.bookmarkCollection.bookmarkCollections,
    collectionsAreLoading: state.bookmarkCollection.collectionsAreLoading,
    collectionsErrorMessage: state.bookmarkCollection.collectionsErrorMessage
});


const mapDispatchToProps = (dispatch) => ({
    fetchCollections: () => dispatch(BookmarkCollectionAction.fetchCollections()),
    addItem: (listID, productID) => dispatch(BookmarkCollectionAction.addItem(listID, productID)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddToBookmarkCollectionModal));
