import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Card, Media} from 'react-bootstrap';
import Icofont from "../Icofont/Icofont";
import PropTypes from 'prop-types';

class AddressCard extends React.Component {


    render() {
        return (
            <Card className={"bg-white addresses-item h-100 d-flex align-item-center " + (this.props.boxClass)}>
                <div className="gold-members p-4">
                    <Media>
                        <div className="mr-3"><Icofont icon={this.props.icoIcon} className={this.props.iconclassName}/>
                        </div>
                        <div className="media-body">
                            <h6 className="mb-1 text-secondary">{this.props.title}</h6>
                            <p className="text-black">{this.props.address}
                            </p>
                            <p className="mb-0 text-black font-weight-bold">
                                <Button as={Link} variant='link' className="text-primary mr-3"
                                        to={"/myaccount/address/" + this.props.id}><Icofont
                                    icon="ui-edit"/> BEARBEITEN</Button>
                                <Button variant="link" className="text-danger" to="#"
                                        onClick={this.props.onDeleteClick}><Icofont
                                    icon="ui-delete"/> LÖSCHEN</Button></p>
                        </div>
                    </Media>
                </div>
            </Card>
        );
    }
}

AddressCard.propTypes = {
    title: PropTypes.string.isRequired,
    icoIcon: PropTypes.string.isRequired,
    iconclassName: PropTypes.string,
    address: PropTypes.string,
    onDeleteClick: PropTypes.func.isRequired
};

export default AddressCard;
