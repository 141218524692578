import {call, put, select} from 'redux-saga/effects';
import UserActions from '../stores/user/actions';
import {ApiService} from '../services/ApiService';

const getKey = (state) => state.user.authKey;
const getID = (state) => state.user.memberID;

/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* fetchUser() {
    try {
        yield put(UserActions.fetchUserLoading());
        const key = yield select(getKey);
        const id = yield select(getID);

        try {
            const user = yield call(ApiService.authorizedClient(key).get, '/Member/' + id);
            yield put(UserActions.fetchUserSuccess(user.data));
        } catch (e) {
            yield put(UserActions.fetchUserFailure(e.message));
        }
    } finally {
        yield put(UserActions.fetchUserCancel());
    }
}
