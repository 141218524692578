import React, {Component} from 'react'
import {Steps} from 'rsuite';
import {Alert, Button, Card, Col, Form, FormGroup, Row, Spinner} from "react-bootstrap";
import Select from "react-select";
import countryList from '../../custom-country-list/CustomCountryList';
import {ApiService} from "../../services/ApiService";
import {HTMLService} from "../../services/HTMLService";
import UploadField from "../UploadField/UploadField";
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {connect} from "react-redux";
import UserActions from "../../stores/user/actions";
import {toastr} from "react-redux-toastr";
import {Link, withRouter} from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from "@stripe/react-stripe-js";
import StripeSEPAForm from "./SEPAForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

class VendorRegistration extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            step: this.props.user.VendorID !== 0 ? 1 : 0,
            loading: true,
            company: '',
            companyError: '',
            companyDisabled: false,
            companyEmail: '',
            companyEmailError: '',
            companyWebsite: '',
            companyWebsiteError: '',
            phone: '',
            phoneError: '',
            shortDesc: '',
            shortDescError: '',
            desc: '',
            descError: '',
            agb: '',
            agbError: '',
            returnPolicy: '',
            returnPolicyError: '',
            branch: '',
            branchError: '',
            street: '',
            streetError: '',
            number: '',
            numberError: '',
            city: '',
            cityError: '',
            zip: '',
            zipError: '',
            country: 'AT',
            countryError: '',
            uid: '',
            uidError: '',
            images: [],
            logo: {},
            uploadError: '',
            options: countryList().getData(),
            plan: 0,
            plans: [],
            error: '',
            buttonDisabled: true,
            vendorAGB: false,
            vendorText: '',
            address: {},
            addressID: 0,
            vendor: {},
            showThankYou: false,
            stripePaymentID: '',
            showNewPayment: true
        };

        this.onChange = this.onChange.bind(this);
        this.handleLogoDelete = this.handleLogoDelete.bind(this);
        this.onNext = this.onNext.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleStripeData = this.handleStripeData.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleUIDChange = this.handleUIDChange.bind(this);
        this.handleStreetChange = this.handleStreetChange.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.checkStepCompletionState = this.checkStepCompletionState.bind(this);
        this.handleCompanyEmailChange = this.handleCompanyEmailChange.bind(this);
        this.handleCompanyWebsiteChange = this.handleCompanyWebsiteChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submitMember = this.submitMember.bind(this);
        this.getVendorAndSetData = this.getVendorAndSetData.bind(this);
        this.getAddressAndSetData = this.getAddressAndSetData.bind(this);
        this.submitUpdate = this.submitUpdate.bind(this);
        this.getImagesAndSetData = this.getImagesAndSetData.bind(this);
        this.handleImagesDelete = this.handleImagesDelete.bind(this);
        this.handleShortDescChange = this.handleShortDescChange.bind(this);
    }

    onChange = (nextStep) => {
        let realNextStep = nextStep < 0 ? 0 : nextStep > 6 ? 6 : nextStep;
        this.setState({step: realNextStep})
        this.setState({loading: false})
    };

    onNext = () => {
        this.setState({loading: true, buttonDisabled: true})
        let nextStep = this.state.step + 1;
        this.checkStepCompletionState(nextStep);
        this.onChange(nextStep);
    }

    onPrevious = () => {
        this.setState({loading: true})
        let nextStep = this.state.step - 1;
        this.checkStepCompletionState(nextStep);
        this.onChange(nextStep);
    }

    getVendorAndSetData() {
        let vendorID = this.props.user.VendorID;
        ApiService.authorizedClient(this.props.authKey).get('/Vendor/' + vendorID)
            .then((response) => {
                this.setState({
                    Vendor: response.data,
                    company: response.data.Title,
                    companyEmail: response.data.Email,
                    sepaHolderEmail: response.data.Email,
                    companyWebsite: response.data.Website,
                    phone: response.data.Tel,
                    desc: response.data.Description,
                    shortDesc: response.data.ShortDescription,
                    agb: response.data.AGB,
                    returnPolicy: response.data.ReturnPolicy,
                    uid: response.data.UID,
                    addressID: response.data.AddressID,
                    plan: response.data.PlanID,
                    stripePaymentID: response.data.StripePaymentID ?? '',
                    showNewPayment: !response.data.StripePaymentID,
                    vendorAGB: true,
                    error: ''
                });
                this.checkStepCompletionState(0)
                this.getAddressAndSetData(response.data.AddressID)
                this.getImagesAndSetData(response.data)
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })

    }

    getImagesAndSetData(data) {
        if (data.LogoID) {
            ApiService.authorizedClient(this.props.authKey).get('/Image/' + data.LogoID)
                .then((response) => {
                    this.setState({
                        logo: response.data
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message, loading: false});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                    }
                })
        }

        ApiService.authorizedClient(this.props.authKey).get('/Vendor/' + data.ID + '/many/Images/')
            .then((response) => {
                this.setState({
                    images: response.data
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    getAddressAndSetData(addressID) {
        ApiService.authorizedClient(this.props.authKey).get('/Address/' + addressID)
            .then((response) => {
                this.setState({
                    street: response.data.Street,
                    number: response.data.Number,
                    city: response.data.City,
                    country: response.data.Country,
                    zip: response.data.PostalCode,
                    error: '',
                    loading: false
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    componentDidMount() {
        this.props.fetchUser();

        ApiService.client.get('/Plan')
            .then((response) => {
                this.setState({
                    plans: response.data,
                    error: ''
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
        ApiService.client.get('/SiteConfig/1')
            .then((response) => {
                this.setState({
                    vendorAGBText: response.data.VendorRegistration,
                    agb: response.data.DefaultVendorAGB,
                    returnPolicy: response.data.DefaultVendorReturnPolicy,
                    error: '',
                    loading: false
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })

        if (this.props.user.VendorID !== 0) {
            this.getVendorAndSetData()
        }
    }

    getStepCompletionParams(stepNr) {
        switch (stepNr) {
            case 0:
                return [
                    ['vendorAGB'],
                    []
                ]
            case 1:
                return [
                    ['plan'],
                    []
                ]
            case 2:
                return [
                    ['company', 'companyEmail'],
                    ['companyError', 'phoneError', 'companyEmailError', 'uidError', 'companyWebsiteError', 'descError', 'shortDescError']
                ]
            case 3:
                return [
                    ['street', 'number', 'city', 'zip', 'country'],
                    ['streetError', 'numberError', 'cityError', 'zipError', 'countryError']
                ]
            case 4:
                return [
                    [],
                    []
                ]
            case 5:
                return [
                    [],
                    []
                ]
            case 6:
                return [
                    ['stripePaymentID'],
                    ['stripePaymentError']
                ]
            default:
                return [
                    [],
                    []
                ]
        }
    }

    checkStepCompletionState(stepNr) {
        let setDisabled = false;
        let values = this.getStepCompletionParams(stepNr)

        let mustBeFilled = values[0];
        let mustBeEmpty = values[1];
        mustBeFilled.forEach((item) => {
            if (this.state[item].length <= 0) {
                setDisabled = true;
            }
        })
        mustBeEmpty.forEach((item) => {
            if (this.state[item]) {
                setDisabled = true;
            }
        })

        this.setState({buttonDisabled: setDisabled})
    }

    getStepContent = () => {
        switch (this.state.step) {
            case 0:
                return this.getVendorAGBContent();
            case 1:
                return this.getPlanStep();
            case 2:
                return this.getCompanySteps();
            case 3:
                return this.getAdderssContent();
            case 4:
                return this.getImagesContent();
            case 5:
                return this.getLawContent();
            case 6:
                return this.getPaymentContent();
            default:
                return this.getDefaultStepContent();
        }
    }

    getVendorAGBContent() {
        return (
            <>
                {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-auto my-3 d-block align-self-center'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    <div dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.vendorAGBText)}}/>
                }

                <Form.Group controlId="formBasicCheckbox" className="mt-3">
                    <Form.Check type="checkbox"
                                label="Ich akzeptiere die AGB für Händler"
                                checked={this.state.vendorAGB}
                                onChange={() => {
                                    this.setState({
                                        vendorAGB: !this.state.vendorAGB
                                    });
                                    this.checkStepCompletionState(this.state.step);
                                }}/>
                </Form.Group>
            </>
        );
    }


    changePlan = (planID) => {
        this.setState({plan: planID})
        this.checkStepCompletionState(this.state.step);
    }

    getPlanStep = () => {
        return (
            <Row>
                {
                    this.state.plans.map((item) => {
                        return (
                            <Col key={item.ID} md={6}
                                 className={'plan-card mb-4 ' + (this.state.plan === item.ID ? 'active' : '')}
                                 onClick={() => this.changePlan(item.ID)}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='text-center'>{item.Title}</Card.Title>
                                        <Card.Text
                                            dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(item.Description)}}>
                                        </Card.Text>
                                        {item.MonthlyFee ?
                                            <Card.Text>
                                                <div className='abo-info d-flex justify-content-between'>
                                                    <b className='mr-1'>Monatlich:</b>
                                                    <b>{item.MonthlyFee} €</b>
                                                </div>

                                            </Card.Text>
                                            :
                                            ''
                                        }
                                        {item.Commission ?
                                            <Card.Text style={{borderTop: '1px solid 000'}}>
                                                <div className='abo-info d-flex justify-content-between'>
                                                    <b className='mr-1'>Kommision:</b>
                                                    <b>{item.Commission * 100} %</b>
                                                </div>
                                            </Card.Text>
                                            :
                                            ''
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        )
    }

    getCompanySteps = () => {
        return (
            <>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="inputCompany"
                        placeholder="Firmenname*"
                        onChange={this.handleCompanyChange}
                        disabled={this.state.loading || this.state.companyDisabled}
                        value={this.state.company}
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="inputCompany">Firmenname*</Form.Label>
                    <p className='text-danger m-0'>{this.state.companyError}&nbsp;</p>
                </div>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="inputPhone"
                        placeholder="Telefonnummer"
                        onChange={this.handlePhoneChange}
                        disabled={this.state.loading}
                        value={this.state.phone}
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="inputPhone">Telefonnummer</Form.Label>
                    <p className='text-danger m-0'>{this.state.phoneError}&nbsp;</p>
                </div>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="inputCompanyEmail"
                        placeholder="E-Mail"
                        onChange={this.handleCompanyEmailChange}
                        disabled={this.state.loading}
                        value={this.state.companyEmail}
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="inputCompanyEmail">E-Mail</Form.Label>
                    <p className='text-danger m-0'>{this.state.companyEmailError}&nbsp;</p>
                </div>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="inputCompanyWebsite"
                        placeholder="Website"
                        onChange={this.handleCompanyWebsiteChange}
                        disabled={this.state.loading}
                        value={this.state.companyWebsite}
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="inputCompanyWebsite">Website</Form.Label>
                    <p className='text-danger m-0'>{this.state.companyWebsiteError}&nbsp;</p>
                </div>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="inputUID"
                        placeholder="UID"
                        onChange={this.handleUIDChange}
                        disabled={this.state.loading}
                        value={this.state.uid}
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="inputUID">UID</Form.Label>
                    <p className='text-danger m-0'
                       dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.uidError)}}/>
                    <p className='text-danger m-0'>&nbsp;</p>
                </div>
                <div className="form-group m-0">
                    <Form.Label htmlFor="inputShortDesc">Kurzbeschreibung</Form.Label>
                    <Form.Control as="textarea" rows="4"
                                  id="inputShortDesc"
                                  placeholder="Kurzbeschreibung"
                                  onChange={this.handleShortDescChange}
                                  disabled={this.state.loading}
                                  value={this.state.shortDesc}
                                  className='input-foreground'
                    />
                    <p className='text-danger m-0'>{this.state.shortDescError}&nbsp;</p>
                </div>
                <div>
                    <Form.Label>Beschreibung</Form.Label>
                    <CKEditor
                        editor={ClassicEditor}
                        data={this.state.desc}
                        config={{removePlugins: ['ImageUpload']}}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            this.setState({desc: data});
                        }}
                    />
                </div>
            </>
        );
    }

    handleCompanyChange(e) {
        let text = e.target.value;
        this.setState({company: text}, () => {
            this.checkStepCompletionState(this.state.step);
        });

    }

    handlePhoneChange(e) {
        let text = e.target.value;
        this.setState({phone: text}, () => {
            this.checkStepCompletionState(this.state.step)
        });

    }

    handleUIDChange(e) {
        let text = e.target.value.replace(' ', '');
        this.setState({uid: text});
        if (text.length) {
            ApiService.vatClient.get(text)
                .then((response) => {
                    let company = this.state.company;
                    this.setState({
                        uidError: '',
                        company: company,
                        companyDisabled: false
                    }, () => {
                        this.checkStepCompletionState(this.state.step)
                    })
                })
                .catch((error) => {
                    let errormsg = '';
                    if (error.response.data.validationResult.messages.length) {
                        error.response.data.validationResult.messages.forEach((msg) => {
                            errormsg += msg.message + '<br>'
                        })
                    }
                    this.setState({
                        uidError: errormsg,
                        companyDisabled: false
                    }, () => {
                        this.checkStepCompletionState(this.state.step)
                    })
                })
        } else {
            this.setState({uidError: '', companyDisabled: false}, () => {
                this.checkStepCompletionState(this.state.step)
            })
        }

    }

    handleCompanyEmailChange(e) {
        let text = e.target.value;
        this.setState({companyEmail: text});
        let emailValidation = text.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        if (emailValidation === null && text.length > 0) {
            this.setState({companyEmailError: 'Bitte gib eine gültige E-Mail Adresse ein!'}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        } else {
            this.setState({companyEmailError: ''}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        }

    }

    handleCompanyWebsiteChange(e) {
        let text = e.target.value;
        this.setState({companyWebsite: text}, () => {
            this.checkStepCompletionState(this.state.step)
        });

    }

    getLawContent() {
        return (
            <div>
                <Form.Label>AGB</Form.Label>
                <CKEditor
                    editor={ClassicEditor}
                    data={this.state.agb}
                    config={{removePlugins: ['ImageUpload']}}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({agb: data});
                    }}
                />
                <div className='mb-3'/>
                <Form.Label>Widerrufsrecht</Form.Label>
                <CKEditor
                    editor={ClassicEditor}
                    data={this.state.returnPolicy}
                    config={{removePlugins: ['ImageUpload']}}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({returnPolicy: data});
                    }}
                />
            </div>
        );
    }


    getAdderssContent = () => {
        return (
            <>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="inputStreet"
                        placeholder="Straße*"
                        onChange={this.handleStreetChange}
                        disabled={this.state.loading}
                        value={this.state.street}
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="inputStreet">Straße*</Form.Label>
                    <p className='text-danger m-0'>{this.state.streetError}&nbsp;</p>
                </div>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="inputNumber"
                        placeholder="Hausnummer*"
                        onChange={this.handleNumberChange}
                        disabled={this.state.loading}
                        value={this.state.number}
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="inputNumber">Hausnummer*</Form.Label>
                    <p className='text-danger m-0'>{this.state.numberError}&nbsp;</p>
                </div>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="inputZip"
                        placeholder="Postleitzahl"
                        onChange={this.handleZipChange}
                        disabled={this.state.loading}
                        value={this.state.zip}
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="inputZip">Postleitzahl*</Form.Label>
                    <p className='text-danger m-0'>{this.state.zipError}&nbsp;</p>
                </div>
                <div className="form-label-group m-0">
                    <Form.Control
                        type="text"
                        id="inputCity"
                        placeholder="Ort*"
                        onChange={this.handleCityChange}
                        disabled={this.state.loading}
                        value={this.state.city}
                        className='input-foreground'
                    />
                    <Form.Label htmlFor="inputCity">Ort*</Form.Label>
                    <p className='text-danger m-0'>{this.state.cityError}&nbsp;</p>
                </div>
                <div className="m-0">
                    <Form.Label htmlFor="inputCountry">Land*</Form.Label>
                    <Select
                        id='inputCountry'
                        placeholder="Bitte auswählen"
                        options={this.state.options}
                        value={this.state.options.filter(option => option.value === this.state.country)}
                        onChange={this.handleCountryChange}
                    />
                    <p className='text-danger m-0'>{this.state.countryError}&nbsp;</p>
                </div>
            </>
        );
    }

    handleStreetChange(e) {
        let text = e.target.value;
        this.setState({street: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({streetError: 'Bitte gib eine gültige Straße ein!'}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        } else {
            this.setState({streetError: ''}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        }
    }

    handleShortDescChange(e) {
        let text = e.target.value;
        this.setState({shortDesc: text});
        if (text.length > 200) {
            this.setState({shortDescError: 'Die Kurzbeschreibung darf nicht länger als 200 Zeichn lang sein'}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        } else {
            this.setState({shortDescError: ''}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        }
    }

    handleNumberChange(e) {
        let text = e.target.value;
        this.setState({number: text});
        if (text.match(/^[0-9]+[a-zA-Z]{0,3}$/) === null) {
            this.setState({numberError: 'Bitte gib eine gültige Hausnummer ein!'}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        } else {
            this.setState({numberError: ''}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        }

    }

    handleCityChange(e) {
        let text = e.target.value;
        this.setState({city: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({cityError: 'Bitte gib eine gültige Ort ein!'}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        } else {
            this.setState({cityError: ''}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        }
    }

    handleCountryChange(target) {
        let text = target.value;
        this.setState({country: text}, () => {
            this.checkStepCompletionState(this.state.step)
        });
    }

    handleZipChange(e) {
        let text = e.target.value;
        this.setState({zip: text});
        if (text.match(/^[^a-zA-Z\n]+$/) === null) {
            this.setState({zipError: 'Bitte gib eine gültige Postleitzahl ein!'}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        } else {
            this.setState({zipError: ''}, () => {
                this.checkStepCompletionState(this.state.step)
            });
        }
    }

    getImagesContent = () => {
        return (
            <>
                <FormGroup>
                    <Form.Label>Logo</Form.Label>
                    {typeof this.state.logo !== 'undefined' && this.state.logo.ID ?
                        <UploadField
                            onChange={this.handleLogoStatusChange}
                            inputContent='Ziehe dein Logo hierher oder klicke zum Durchsuchen'
                            maxFiles={1}
                            onDelete={this.handleLogoDelete}
                            files={[this.state.logo]}
                        />
                        :
                        <UploadField
                            onChange={this.handleLogoStatusChange}
                            onDelete={this.handleLogoDelete}
                            inputContent='Ziehe dein Logo hierher oder klicke zum Durchsuchen'
                            maxFiles={1}
                        />
                    }

                </FormGroup>
                <FormGroup>
                    <Form.Label>Bilder</Form.Label>
                    <UploadField
                        onChange={this.handleImageStatusChange}
                        onDelete={this.handleImagesDelete}
                        files={this.state.images}
                    />
                </FormGroup>
            </>


        );
    }

    handleLogoStatusChange = (currentFiles) => {
        this.setState({logo: currentFiles[0]});
    }

    handleLogoDelete(file, currentFiles) {
        this.setState({logo: {}});

    }

    handleImageStatusChange = (currentFiles) => {
        this.setState({images: currentFiles});
    }

    handleImagesDelete(file, currentFiles) {
        if (this.props.user.VendorID !== 0) {
            ApiService.authorizedClient(this.props.authKey).delete(
                `/Vendor/${this.props.user.VendorID}/many/Images/`,
                {data: [file]}
            ).then().catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten';
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                toastr.error('Fehler beim Löschen', msg)
            })
        }
        this.setState({images: currentFiles})

    }

    getDefaultStepContent = () => {
        return 'BITTE LEGEN SIE EINEN INHLAT FÜR DIESEN SCHRITT FEST';
    }


    handleSubmit() {
        const me = this;
        me.setState({loading: true}, () => {
            if (this.props.user.VendorID === 0) {
                this.submitAddress(me);
            } else {
                this.submitUpdate()
            }
        });


    }

    submitUpdate() {
        ApiService.authorizedClient(this.props.authKey).put(
            '/Address/' + this.state.addressID,
            {
                'Street': this.state.street,
                'Number': this.state.number,
                'City': this.state.city,
                'Country': this.state.country,
                'PostalCode': this.state.zip,
                'Company': this.state.company,
                'UID': this.state.uid,
            }
        ).then(
            (response) => {
                this.setState({address: response.data});
            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error('Fehler beim Anlegen der Adresse', errorMsg)
                this.setState({loading: false, error: ''});
            },
        );

        ApiService.authorizedClient(this.props.authKey).put(
            '/Vendor/' + this.props.user.VendorID,
            {
                Title: this.state.company,
                Description: this.state.desc,
                ShortDescription: this.state.shortDesc,
                Email: this.state.companyEmail,
                Website: this.state.companyWebsite,
                Tel: this.state.phone,
                PlanID: this.state.plan,
                Logo: this.state.logo.ID,
                AGB: this.state.agb,
                UID: this.state.uid,
                StripePaymentID: this.state.stripePaymentID,
                ReturnPolicy: this.state.returnPolicy,
            }
        ).then(
            (response) => {
                this.setState({address: response.data});
                toastr.success('Erfolgreich', 'Deine Händlerdaten wurden aktualisiert')
            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error('Fehler beim Anlegen der Adresse', errorMsg)
                this.setState({loading: false, error: ''});
            },
        );

        this.submitImages(this, true, this.props.user.VendorID);
    }

    submitAddress(me) {
        ApiService.authorizedClient(this.props.authKey).post(
            '/Address',
            {
                'Street': me.state.street,
                'Number': me.state.number,
                'City': me.state.city,
                'Country': me.state.country,
                'PostalCode': me.state.zip,
                'MemberID': me.state.memberID,
                'Company': me.state.company,
                'UID': me.state.uid,
            }
        ).then(
            (response) => {
                me.setState(
                    {
                        address: response.data
                    },
                    () => me.sumbmitVendor(me, response.data)
                );

            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error('Fehler beim Anlegen der Adresse', errorMsg)
                me.setState({loading: false, error: ''});
            },
        );
    }

    sumbmitVendor(me, address) {
        ApiService.authorizedClient(this.props.authKey).post(
            '/Vendor/',
            {
                Title: me.state.company,
                Description: me.state.desc,
                ShortDescription: me.state.shortDesc,
                Email: me.state.companyEmail,
                Website: me.state.companyWebsite,
                Tel: me.state.phone,
                AddressID: address.ID,
                PlanID: me.state.plan,
                Logo: me.state.logo.ID,
                AGB: me.state.agb,
                UID: me.state.uid,
                StripePaymentID: me.state.stripePaymentID,
                ReturnPolicy: me.state.returnPolicy,
                MemberID: me.props.memberID
            }
        ).then(
            (response) => {
                me.setState({loading: false, error: ''});
                //this.setState({succesMain: 'Die Daten wurden erfolgreich geändert'});
                me.setState({
                        vendor: response.data
                    }, me.submitMember(me, true, response.data)
                )
                ;

            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                me.setState({loading: false});
                toastr.error('Fehler beim Anlegen des Händlers', errorMsg)
            },
        );
    }

    submitMember(me, thankYou, vendor) {
        const payload = {
            VendorID: vendor.ID,
        };
        ApiService.authorizedClient(this.props.authKey).put(
            '/Member/' + me.props.memberID,
            payload
        ).then(
            () => {
                me.setState({loading: false, error: ''});
                toastr.success('Erfolgreich', 'Dein Profil wurd zum Händler hochgestuft')
                if (me.state.images.length) {
                    me.submitImages(me, true);
                } else {
                    if (thankYou) {
                        // me.setState({
                        //     showThankYou: true
                        // })
                        this.props.fetchUser();
                        this.props.history.push('/myaccount/paymentdata');
                    } else {
                        this.props.history.push('/myaccount/vendorregistration')
                    }

                }

            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                me.setState({loading: false});
                toastr.success('Fehler beim hinzufügen des Händlers', errorMsg)
            },
        );
    }

    submitImages(me, thankyou, vendorID) {
        if (typeof vendorID === 'undefined') {
            vendorID = me.state.vendor.ID;
        }
        ApiService.authorizedClient(this.props.authKey).patch(
            '/Vendor/' + vendorID + '/many/Images',
            this.state.images
        ).then(
            () => {
                me.setState({loading: false, error: ''});
                if (thankyou) {
                    this.props.fetchUser();
                    me.setState({
                        showThankYou: true
                    })
                    this.props.history.push('/myaccount/paymentdata');
                } else {
                    me.props.history.push('/myaccount/vendorregistration')
                }

            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                me.setState({loading: false});
                toastr.error('Fehler beim hochladen der Bilder', errorMsg)
            },
        );
    }

    changeStep(step) {
        if (this.props.user.VendorID !== 0) {
            this.setState({
                step: step
            })
            this.checkStepCompletionState(step)
        }
    }


    getPaymentContent() {
        return (
            <>
                <p><b>Das Bankkonto dient zur monatlichen Abrechnung Ihres ausgewählten Pakets.</b></p>
                {
                    this.state.showNewPayment ?
                        <Elements stripe={stripePromise}>
                            <StripeSEPAForm handleStripeData={this.handleStripeData}/>
                        </Elements>
                        :
                        <>
                            <p>Sie haben bereits ein Bankkonto hinterlegt!</p>
                            <div className='pt-3 pb-3'>
                                <Button variant='outline-primary' onClick={() => this.setState({showNewPayment: true})}
                                        className='w-100'>
                                    Zahlungsdaten ändern
                                </Button>
                            </div>
                        </>
                }
            </>


        );
    }

    handleStripeData(stripePaymentID) {
        this.setState({
            stripePaymentID: stripePaymentID
        }, () => this.checkStepCompletionState(this.state.step))
    }


    render() {
        return (
            <div className="p-4 bg-white shadow-sm">
                <Row>
                    {this.state.showThankYou ?
                        <Col xs={12} className='py-3'>
                            <h4 className='text-center mb-2'>Danke für deine Anmeldung als Händler</h4>
                            <p className='text-center mb-2'>Lege gleich los und gib dein <Link
                                to='/myaccount/products/new'>ersten Produkt ein</Link></p>
                        </Col>
                        :
                        <>
                            <Col lg={3}>
                                <Steps vertical current={this.state.step}>
                                    {this.props.user.VendorID !== 0 ?
                                        <Steps.Item title="AGB"/>
                                        :
                                        <Steps.Item
                                            className={this.props.user.VendorID !== 0 ? 'cursor-pointer' : ''}
                                            onClick={() => {
                                                this.changeStep(0)
                                            }} title="AGB"/>
                                    }
                                    <Steps.Item
                                        className={this.props.user.VendorID !== 0 ? 'cursor-pointer' : ''}
                                        onClick={() => {
                                            this.changeStep(1)
                                        }} title="Paket"/>
                                    <Steps.Item
                                        className={this.props.user.VendorID !== 0 ? 'cursor-pointer' : ''}
                                        onClick={() => {
                                            this.changeStep(2)
                                        }} title="Firmendaten"/>
                                    <Steps.Item
                                        className={this.props.user.VendorID !== 0 ? 'cursor-pointer' : ''}
                                        onClick={() => {
                                            this.changeStep(3)
                                        }} title="Firmenadresse"/>
                                    <Steps.Item
                                        className={this.props.user.VendorID !== 0 ? 'cursor-pointer' : ''}
                                        onClick={() => {
                                            this.changeStep(4)
                                        }} title="Bilder"/>
                                    <Steps.Item
                                        className={this.props.user.VendorID !== 0 ? 'cursor-pointer' : ''}
                                        onClick={() => {
                                            this.changeStep(5)
                                        }} title="Rechtliches"/>
                                    <Steps.Item
                                        className={this.props.user.VendorID !== 0 ? 'cursor-pointer' : ''}
                                        onClick={() => {
                                            this.changeStep(6)
                                        }} title="Abo-Zahlung"/>
                                </Steps>
                            </Col>
                            <Col lg={9}>
                                <div className='p-2'>
                                    {this.getStepContent()}
                                    {this.state.error ?
                                        <Alert variant="danger">{this.state.error}</Alert>
                                        :
                                        ''
                                    }
                                </div>
                                <div className='p-2 d-flex justify-content-between'>
                                    {this.props.user.VendorID !== 0 ?
                                        this.state.step === 1 ?
                                            <div/>
                                            :
                                            <Button onClick={this.onPrevious} disabled={this.state.step === 1}>
                                                Zurück
                                            </Button>
                                        :
                                        this.state.step === 0 ?
                                            <div/>
                                            :
                                            <Button onClick={this.onPrevious} disabled={this.state.step === 0}>
                                                Zurück
                                            </Button>
                                    }
                                    <span>
                                 {this.state.step === 6 ?

                                     <Button onClick={this.handleSubmit}
                                             disabled={this.state.buttonDisabled || this.state.loading}>
                                         Speichern & Weiter
                                     </Button>
                                     :
                                     <Button onClick={this.onNext} disabled={this.state.buttonDisabled}>
                                         Weiter
                                     </Button>
                                 }
                                        {this.props.user.VendorID !== 0 ?
                                            <Button className='ml-2' onClick={this.submitUpdate}
                                                    disabled={this.state.buttonDisabled}>
                                                Speichern
                                            </Button>
                                            :
                                            ''
                                        }
                            </span>

                                </div>

                            </Col>
                        </>
                    }
                </Row>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
    basketID: state.basket.basketID
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(VendorRegistration));
