import React from 'react';
import {connect} from "react-redux";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {Spinner} from "react-bootstrap";
import VendorOrderCard from "./VendorOrderCard";
import {DateRangePicker} from "rsuite";

const Locale = {
    sunday: 'So',
    monday: 'Mo',
    tuesday: 'DI',
    wednesday: 'Mi',
    thursday: 'Do',
    friday: 'Fr',
    saturday: 'Sa',
    ok: 'OK',
    today: 'Heute',
    yesterday: 'Gestern',
    last7Days: 'Letzten 7 Tage',
    hours: 'Stunden',
    minutes: 'Minuten',
    seconds: 'Sekunden'
};

class VendorOrders extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            toDate: '',
            fromDate: '',
            loading: true,
        }
        this.fetchOrder = this.fetchOrder.bind(this)
        this.setOrderFilterDates = this.setOrderFilterDates.bind(this)
    }

    componentDidMount() {
        let to = new Date()
        let from = new Date();
        from.setDate(to.getDate() - 7);
        this.setOrderFilterDates([from, to])
    }

    fetchOrder() {
        this.setState({loading: true});
        let filter = '';
        if (this.state.fromDate) {
            filter += '&filter[Created:GreaterThanOrEqual]=' + this.state.fromDate
        }
        if (this.state.toDate) {
            filter += '&filter[Created:LessThanOrEqual]=' + this.state.toDate
        }
        ApiService.authorizedClient(this.props.authKey).get("/VendorOrder/?filter[VendorID]=" + this.props.user.VendorID + '&filter[EmailSent]=1&sort=Created DESC' + filter)
            .then((response) => {
                this.setState({
                    orders: response.data,
                    error: '',
                    loading: false
                });
            })
            .catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten';
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message;
                }
                this.setState({loading: false});
                toastr.error('Fehler beim Laden der Bestellungen', msg);
            })
    }

    setOrderFilterDates(dates) {
        if (typeof dates[0] !== "undefined" && typeof dates[1] !== "undefined") {
            this.setState({
                fromDate: this.formatDate(dates[0]),
                toDate: this.formatDate(dates[1])
            }, () => this.fetchOrder())
        } else {
            this.setState({
                fromDate: '',
                toDate: ''
            }, () => this.fetchOrder())
        }

    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    render() {
        let to = new Date()
        let from = new Date();
        from.setDate(to.getDate() - 7);
        return (
            <>
                <div className='p-4 bg-white shadow-sm'>
                    <h4 className="font-weight-bold mt-0 mb-4">Kundenbestellungen</h4>
                    <div className="d-flex mb-3 align-items-center">
                        Datumsbereich:
                        <DateRangePicker
                            className="ml-2"
                            locale={Locale}
                            onChange={(dates) => this.setOrderFilterDates(dates)}
                            defaultValue={[from, to]}
                        />
                    </div>
                    {this.state.loading ?
                        <Spinner animation="border" role="status"
                                 className='mx-auto d-block align-self-center'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        :
                        <>
                            {
                                this.state.orders.length ?
                                    <>

                                        {
                                            this.state.orders.map((item) =>
                                                <VendorOrderCard
                                                    key={item.ID}
                                                    vendorOrder={item}
                                                />
                                            )
                                        }
                                    </>


                                    :
                                    <p className='text-muted text-center'>Keine Bestellungen vorhanden!</p>
                            }
                        </>

                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    user: state.user.user,
});


export default connect(
    mapStateToProps,
    null,
)(VendorOrders);
