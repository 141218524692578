import React from 'react';
import OrderCard from './OrderCard';
import {connect} from "react-redux";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {Spinner} from "react-bootstrap";

class Orders extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            loading: true,
        }
        this.fetchOrder = this.fetchOrder.bind(this)
    }

    componentDidMount() {
        this.fetchOrder()
    }

    fetchOrder() {
        this.setState({loading: true});
        ApiService.authorizedClient(this.props.authKey).get("/Order/?filter[MemberID]=" + this.props.memberID+'&filter[Status:not]=Basket')
            .then((response) => {
                this.setState({
                    orders: response.data,
                    error: '',
                    loading: false
                });
            })
            .catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten';
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message;
                }
                this.setState({loading: false});
                toastr.error('Fehler beim Laden der Bestellungen', msg);
            })
    }

    render() {
        return (
            <>
                <div className='p-4 bg-white shadow-sm'>
                    <h4 className="font-weight-bold mt-0 mb-4">Meine Bestellungen</h4>
                    {this.state.loading ?
                        <Spinner animation="border" role="status"
                                 className='mx-auto d-block align-self-center'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        :
                        this.state.orders.length ?
                            this.state.orders.map((item) =>
                                <OrderCard
                                    key={item.ID}
                                    orderNumber={item.Title}
                                    orderDate={item.Placed}
                                    orderPaidDate={item.Paid}
                                    order={item}
                                />
                            )

                            :
                            <p className='text-muted text-center'>Keine Bestellungen vorhanden!</p>
                    }
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
});


export default connect(
    mapStateToProps,
    null,
)(Orders);
