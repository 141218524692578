import React, {Component} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import LazyImage from "../LazyImage/LazyImage";
import {Link} from "react-router-dom/";
import Icofont from "../Icofont/Icofont";
import {connect} from "react-redux";

class ClusterMarker extends Component {

    static defaultProps = {};

    state = {
        show: false,
        elementHeight: 0,
    }

    recalcHeight() {
        let elementHeight = this.ref.clientHeight;
        this.setState({
            elementHeight: elementHeight
        })
    }

    componentDidMount() {
        this.recalcHeight();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.show !== this.state.show) {
            this.recalcHeight();
        }
    }

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const deg2rad = (deg) => {
            return deg * (Math.PI / 180)
        }
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d.toFixed(1);
    }

    render() {
        if (!this.state.show) {
            return (
                <span className='clustermarker' onClick={() => this.setState({show: !this.state.show})}>
                    <span className='clusterNumber'>{this.props.points.length}</span>
                     <Icofont
                         ref={ref => this.ref = ref}
                         icon="location-pin"
                         className="map-pin"
                     />
                </span>

            );
        }

        return (
            <div className={'customMarker clusterMarker p-3'}
                 ref={ref => this.ref = ref}
                 style={{top: -(this.state.elementHeight + 20)}}
                 onClick={() => this.setState({show: !this.state.show})}
            >
                <Icofont
                    icon='close-line'
                    className='closeMapPin'
                />
                <div className='inner'>
                    {this.state.show ?
                        this.props.points.map((item) =>
                            <Row key={item.ID} className='py-2 border-bottom'>
                                <Col xs={5} className='pr-1'>
                                    <div className='img-container'>
                                        {item.PreviewImage ?
                                            <LazyImage src={item.PreviewImage} fluid/>
                                            :
                                            null
                                        }
                                    </div>
                                </Col>
                                <Col xs={7} className='pl-1 d-flex align-items-center'>
                                    <div className='d-block'>
                                        <h6>{item.Title}</h6>
                                        {item.CategoryTitle ?
                                            <p className="text-gray mb-3">{item.CategoryTitle}</p>
                                            :
                                            null
                                        }
                                        <p className="text-gray time mb-0">
                                            {this.props.coords.latitude ?
                                                <span className="bg-light rounded-sm pb-1 pt-1 pr-2">
                                        <Icofont icon='map-pins'/>&nbsp;
                                                    {
                                                        this.getDistanceFromLatLonInKm(
                                                            item.Latitude,
                                                            item.Longitude,
                                                            this.props.coords.latitude,
                                                            this.props.coords.longitude,
                                                        )
                                                    } km
                                    </span>
                                                :
                                                null
                                            }
                                            {item.Price ?
                                                <span className="float-right text-dark">€{item.Price.toFixed(2)}</span>
                                                : null
                                            }
                                        </p>
                                    </div>

                                </Col>
                                <Col xs={12} className='pt-2'>
                                    <Button
                                        as={Link}
                                        className='btn btn-primary d-block'
                                        to={item.linkTo}>{item.linkTitle ?? 'Hier klicken'}
                                    </Button>
                                </Col>
                            </Row>
                        )
                        :
                        null
                    }
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
});


export default connect(
    mapStateToProps,
)(ClusterMarker);