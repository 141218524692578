import React, {Component} from "react";
import {ApiService} from "../../services/ApiService";
import TeaserElement from "../../elements/TeaserElement/TeaserElement";
import ContentElement from "../../elements/ContentElement/ContentElement";
import DefaultElement from "../../elements/DefaultElement/DefaultElement";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";

export default class Page extends Component {
    state = {
        title: '',
        metaTitle: '',
        metaDesc: '',
        elements: []
    }

    render() {

        return <>
            {(this.state.title.length || this.state.metaTitle.length) ?
                <CustomMetaTags
                    title={'Regionlinemarkt - ' + (this.state.metaTitle ?? this.state.title)}
                    desc={this.state.metaDesc}
                />
                :
                null
            }
            {this.state.elements.map((element, index) =>
                <section className="py-5" key={element.ID} style={{backgroundColor: element.BackgroundColor}}>
                    {this.renderElement(element, index)}
                </section>
            )}
        </>
            ;
    }

    renderElement = (element, index) => {
        let elmentName = element.ClassName.replace("CIC\\Region\\Data\\", '');
        switch (elmentName) {
            case 'TeaserElement':
                return <TeaserElement id={element.ID}/>;
            case 'ContentElement':
                return <ContentElement id={element.ID}/>;
            default:
                return <DefaultElement id={element.ID}/>;
        }

    }

    componentDidMount() {
        ApiService.client.get('/Page/?filter[URLSegment]=' + this.props.match.params.urlsegment + '&limit=1')
            .then((response) => {
                if (response.data[0]) {
                    this.setState({
                        title: response.data[0].Title,
                        metaTitle: response.data[0].MetaTitle ?? '',
                        metaDesc: response.data[0].MetaDescription ?? '',
                        elements: response.data[0].Elements ?? []
                    });
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }
}
