import React, {Component} from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import {Route, Switch} from "react-router-dom";
import Login from "../Login/Login";
import Register from "../Register/Register";
import Home from "../Home/Home";
import NotFound from "../NotFound/NotFound";
import Marketplace from "../Marketplace/Marketplace";
import VendorOverview from "../VendorOverview/VendorOverview";
import Product from "../Product/Product";
import Page from "../Page/Page";
import MyAccount from "../MyAccount/MyAccount";
import ReduxToastr from 'react-redux-toastr'
import VendorDetail from "../Vendor/VendorDetail";
import Checkout from "../Checkout/Checkout";
import VendorHowToPage from "../VendorHowToPage/VendorHowToPage";
import DSGVOOverlay from "../../components/DSGVO/DSGVOOverlay";
import ScrollToTop from "../../components/ScrollTop/ScrollTop";
import NewsPage from "../NewsPage/NewsPage";
import PasswordRest from "../Login/PasswordRest";
import RecipePage from "../RecipePage/RecipePage";


export default class Wrapper extends Component {
    render() {
        return (
            <>
                <ReduxToastr
                    timeOut={5000}
                    newestOnTop={true}
                    position="top-right"
                    getState={(state) => state.toastr} // This is the default
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                    closeOnToastrClick/>
                <Header/>
                <div
                    key={this.props.location.key}
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 1}}
                >
                    <DSGVOOverlay/>
                    <ScrollToTop>
                        <Switch>
                            <Route path="/login">
                                <Login/>
                            </Route>
                            <Route path="/resetpw/:hash">
                                <PasswordRest/>
                            </Route>
                            <Route path="/register">
                                <Register/>
                            </Route>
                            {/*<Route path="/kategorien" component={Categories}/>*/}
                            <Route path="/marktplatz/:id/:category" component={Marketplace}/>
                            <Route path="/marktplatz" component={Marketplace}/>
                            <Route path="/händlerübersicht" component={VendorOverview}/>
                            <Route path="/myaccount" component={MyAccount}/>
                            <Route path="/händler/:id/" component={VendorDetail}/>
                            <Route path="/news/" component={NewsPage}/>
                            <Route path="/rezepte/" component={RecipePage}/>
                            <Route path="/ueber-uns/" component={VendorHowToPage}/>
                            <Route path="/produkt/:id/:product" component={Product}/>
                            <Route exact path="/produkt/not-found">
                                <NotFound/>
                            </Route>
                            <Route exact path="/content/:urlsegment" component={Page}/>
                            <Route exact path="/checkout" component={Checkout}/>
                            <Route exact path="/">
                                <Home/>
                            </Route>
                            <Route default>
                                <NotFound/>
                            </Route>
                        </Switch>
                    </ScrollToTop>

                </div>
                <Footer/>
            </>
        );
    }
}
