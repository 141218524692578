import React from 'react';
import {Alert, Card, Col, Row, Spinner} from 'react-bootstrap';
import DeleteAddressModal from '../Modals/DeleteAddressModal';
import AddressCard from './AddressCard';
import Icofont from "../Icofont/Icofont";
import {Link} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";

class Addresses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showDeleteModal: false,
            showDeleteModalText: '',
            error: '',
            addresses: [],
            addressID: 0,
            addressTitle: '',
        };

        this.showDeleteModalForAddress = this.showDeleteModalForAddress.bind(this);
        this.fetchAddresses = this.fetchAddresses.bind(this);
        this.onAddressDelete = this.onAddressDelete.bind(this);
    };

    onAddressDelete() {
        this.fetchAddresses();
    }

    fetchAddresses() {
        this.setState({loading: true});
        ApiService.client.get("/Address/?filter[MemberID]=" + this.props.memberID)
            .then((response) => {
                this.setState({
                    addresses: response.data,
                    error: '',
                    loading: false
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    componentDidMount() {
        this.fetchAddresses();
    }


    hideDeleteModal = () => this.setState({showDeleteModal: false});

    showDeleteModalForAddress(addressID, addressTitle) {
        this.setState({showDeleteModal: true, addressID: addressID, addressTitle: addressTitle})
    }

    render() {
        return (
            <>
                <DeleteAddressModal
                    show={this.state.showDeleteModal}
                    addressID={this.state.addressID}
                    addressTitle={this.state.addressTitle}
                    onHide={this.hideDeleteModal}
                    authKey={this.props.authKey}
                    onAddressDelete={() => this.onAddressDelete()}
                />
                <div className='p-4 bg-white shadow-sm'>
                    <Row>
                        <Col md={12}>
                            <h4 className="font-weight-bold mt-0 mb-3">Adressen verwalten</h4>
                        </Col>
                        <Col md={6} className='mb-4'>
                            <Card className="bg-white addresses-item h-100 d-flex align-item-center shadow-sm">
                                <Link to="/myaccount/address/new"
                                      className="gold-members p-4 text-cente text-center d-block">
                                    <h6 className="mt-3 mb-3 text-secondary">Neue Adresse hinzufügen</h6>
                                    <Icofont icon='plus-circle' className='icofont-3x d-inline-block'/>
                                </Link>
                            </Card>
                        </Col>
                        {this.state.loading ?
                            <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            :
                            null
                        }
                        {this.state.error ?
                            <Alert variant="danger">{this.state.error}</Alert>
                            :
                            this.state.addresses.map((item, index) => {
                                return (<Col md={6} key={item.ID} className='mb-4'>
                                        <AddressCard
                                            id={item.ID}
                                            boxClass="shadow-sm"
                                            title={item.City}
                                            icoIcon='ui-home'
                                            iconclassName='icofont-3x'
                                            address={item.Title}
                                            onDeleteClick={() => this.showDeleteModalForAddress(item.ID, item.Title)}
                                        />
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Addresses);
