import React, {Component} from "react";
import {ApiService} from "../../services/ApiService";
import {Alert, Col, Container, Row, Spinner} from 'react-bootstrap';
import LazyImage from "../../components/LazyImage/LazyImage";
import {HTMLService} from "../../services/HTMLService";

export default class ContentElement extends Component {

    state = {
        content: '',
        prefix: this.props.elementClassPrefix ?? '',
        imageID: 0,
        img: '',
        imageAlt: '',
        alignItemsCenter: false,
        errorMsg: '',
        loading: false
    }

    setMainData = () => {
        this.setState({loading: true});
        ApiService.client.get('/' + this.state.prefix + 'ContentElement/' + this.props.id)
            .then((response) => {
                this.setState({
                    content: response.data.ParsedContent,
                    imageID: response.data.ImageID,
                    errorMsg: '',
                    loading: false
                });
                if (response.data.ImageID) {
                    this.setImage();
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({errorMsg: error.response.data.message, loading: false});
                } else {
                    this.setState({errorMsg: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    setImage = () => {
        ApiService.client.get('/Image/' + this.state.imageID + '/ScaleWidth/400/')
            .then((response) => {
                this.setState({img: response.data.AbsoluteURL, imageAlt: response.data.Title});
            })
            .catch((error) => {
                console.log(error);
            })
    }

    componentDidMount() {
        this.setMainData();
    }


    render() {
        return (
            <Container>
                {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    this.state.errorMsg ?
                        <Alert variant="danger">{this.state.errorMsg}</Alert>
                        :
                        <Row className={this.state.alignItemsCenter ? 'align-items-center' : ''}>
                            <Col
                                md={this.state.imageID ? 6 : 12}
                                className="py-5"
                                dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.content)}}
                            />
                            {this.state.imageID ?
                                <Col md={6} className="py-5">
                                    <LazyImage src={this.state.img} className='mx-auto d-block' fluid
                                               alt={this.state.imageAlt}/>
                                </Col>
                                : ''
                            }
                        </Row>

                }
            </Container>
        );
    }
}
