import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Alert, Col, Container, Form, Row} from 'react-bootstrap';
import LoginBackground from "../../assets/img/img_login.jpg";
import {ApiService} from "../../services/ApiService";
import {connect} from 'react-redux';
import UserActions from '../../stores/user/actions';
import BasketAction from "../../stores/basket/actions";
import {toastr} from "react-redux-toastr";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            isPasswordRestMode: false,
            password: '',
            loading: false,
            error: '',
            errors: [],
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePasswordRestSubmit = this.handlePasswordRestSubmit.bind(this);
        this.onKeyDownMain = this.onKeyDownMain.bind(this);
    }

    handlePasswordChange(e) {
        let text = e.target.value;
        this.setState({password: text});
    }

    onKeyDownMain(event) {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            this.handleSubmit();
        }
    }

    handleEmailChange(e) {
        let text = e.target.value;
        this.setState({username: text});
    }


    handleSubmit() {
        const me = this;
        me.setState({loading: true});
        ApiService.client.post(
            'Member/authorize',
            {
                'Email': me.state.username,
                'Password': me.state.password,
            }
        ).then(
            (response) => {
                if (response.data.valid === false) {
                    me.setState({loading: false, errors: response.data.messages});
                } else {
                    me.props.login(response.data.Key, response.data.ValidUntil, response.data.MemberID);
                    me.props.setBasketDoneLoading();
                    setTimeout(() => {
                        me.props.fetchBasket();
                    }, 200)
                    this.props.history.push('/');
                }
            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.messages[0].message) {
                    errorMsg = error.response.data.messages[0].message
                }
                me.setState({loading: false, error: errorMsg});
            },
        );
    }

    handlePasswordRestSubmit() {
        const me = this;
        if (!me.state.username) {
            me.setState({loading: false, error: 'Bitte gib deine E-Mail ein '});
            return;
        }
        me.setState({loading: true, error: ''});
        ApiService.client.get('/Member/sendResetEmail?email=' + this.state.username)
            .then((response) => {
                me.setState({loading: false, error: '', username: '', isPasswordRestMode: false});
                toastr.success('E-Mail verschickt!', response.data.message)
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten'
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            me.setState({loading: false, error: msg});
        })
    }

    render() {
        return (
            <Container fluid className='bg-transparent'>
                <CustomMetaTags
                    title={'Regionlinemarkt - Login'}
                />
                <Row>
                    <Col lg={6} className="d-none d-lg-flex bg-image"
                         style={{backgroundImage: `url(${LoginBackground})`}}/>
                    <Col md={12} lg={6}>
                        <div className="login d-flex align-items-center py-5">
                            <Container>
                                <Row>
                                    <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                                        <h3 className="login-heading mb-4">Servus und Hallo!</h3>
                                        <Form>
                                            <div className="form-label-group">
                                                <Form.Control
                                                    type="email"
                                                    id="inputEmail"
                                                    placeholder="E-Mail Adresse"
                                                    onChange={this.handleEmailChange}
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') {
                                                            if (this.state.isPasswordRestMode) {
                                                                this.handlePasswordRestSubmit()
                                                            }
                                                        }
                                                    }}
                                                    value={this.state.username}
                                                    disabled={this.state.loading}
                                                    className='input-foreground'
                                                />
                                                <Form.Label htmlFor="inputEmail">E-Mail Adresse</Form.Label>
                                            </div>
                                            {!this.state.isPasswordRestMode ?
                                                <div className="form-label-group">
                                                    <Form.Control
                                                        type="password"
                                                        id="inputPassword"
                                                        placeholder="Passwort"
                                                        onChange={this.handlePasswordChange}
                                                        disabled={this.state.loading}
                                                        value={this.state.password}
                                                        onKeyDown={this.onKeyDownMain}
                                                        className='input-foreground'
                                                    />
                                                    <Form.Label htmlFor="inputPassword">Passwort</Form.Label>
                                                </div>
                                                :
                                                null
                                            }
                                            {/* <Form.Check
                                                className='mb-3'
                                                custom
                                                type="checkbox"
                                                id="custom-checkbox"
                                                label="Passwort merken"
                                            />*/}
                                            {this.state.error ?
                                                <Alert variant="danger">{this.state.error}</Alert>
                                                : null
                                            }
                                            {this.state.isPasswordRestMode ?
                                                <div onClick={this.handlePasswordRestSubmit}
                                                     className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">
                                                    Passwort zurücksetzen
                                                </div>
                                                :
                                                <div onClick={this.handleSubmit}
                                                     className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">Login
                                                </div>
                                            }
                                            {this.state.isPasswordRestMode ?
                                                <div className="text-center pt-3">
                                                    <span
                                                        className='text-primary cursor-pointer'
                                                        onClick={() => this.setState({
                                                            isPasswordRestMode: !this.state.isPasswordRestMode
                                                        })}
                                                    ><b>Anmelden</b></span>
                                                </div>
                                                :
                                                <>
                                                    <div className="text-center pt-3">
                                                        Noch kein Account? <Link className="font-weight-bold"
                                                                                 to="/register">Zur Registrierung</Link>
                                                    </div>
                                                    <div className="text-center pt-3">
                                                        <span
                                                            className='text-primary cursor-pointer'
                                                            onClick={() => this.setState({
                                                                isPasswordRestMode: !this.state.isPasswordRestMode
                                                            })}
                                                        ><b>Passwort vergessen?</b></span>
                                                    </div>
                                                </>
                                            }

                                            {/*<hr className="my-4"/>
                                            <p className="text-center">LOGIN MIT</p>
                                            <div className="row">
                                                <div className="col pr-2">
                                                    <Button
                                                        className="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase"
                                                        type="submit"><Icofont icon="google-plus"
                                                                               className="mr-2"/> Google</Button>
                                                </div>
                                                <div className="col pl-2">
                                                    <Button
                                                        className="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase"
                                                        type="submit"><Icofont icon="facebook"
                                                                               className="mr-2"/> Facebook</Button>
                                                </div>
                                            </div>*/}
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    login: (authKey, validUntil, memberID) => dispatch(UserActions.login(authKey, validUntil, memberID)),
    setBasketDoneLoading: () => dispatch(BasketAction.setBasketDoneLoading()),
    fetchBasket: () => dispatch(BasketAction.fetchBasket())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Login));

