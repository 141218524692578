import React, {Component} from 'react';
import {Link, NavLink, Route, Switch, withRouter} from 'react-router-dom';
import {Badge, Col, Container, Row} from 'react-bootstrap';
import Orders from '../../components/MyAccount/Orders';
import Payments from '../../components/MyAccount/Payments';
import Addresses from '../../components/MyAccount/Addresses';
import ProfilData from '../../components/MyAccount/ProfilData';
import AddressForm from '../../components/MyAccount/AddressForm';
import {connect} from "react-redux";
import UserActions from "../../stores/user/actions";
import VendorRegistration from "../../components/MyAccount/VendorRegistration";
import StripePaymentOnBoarding from "../../components/MyAccount/StripePaymentOnBoarding";
import Icofont from "../../components/Icofont/Icofont";
import ShippingSettings from "../../components/MyAccount/ShippingSettings";
import ShippingRateForm from "../../components/MyAccount/ShippingRateForm";
import Products from "../../components/MyAccount/Products";
import ProductForm from "../../components/MyAccount/ProductForm";
import Order from "../Order/Order";
import BookmarkCollection from "../BookmarkCollection/BookmarkCollection";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";
import VendorOrders from "../../components/MyAccount/VendorOrders";

class MyAccount extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showEditProfile: false,
            firstName: '',
            surname: '',
            email: '',
            password: '',
            passwordRepeat: '',
        };
    }

    componentDidMount() {
        this.props.fetchUser();
    }

    render() {
        return (
            <>
                <CustomMetaTags
                    title={'Regionlinemarkt - Mein Benutzer'}
                />
                <section className="section pt-4 pb-4 osahan-account-page">
                    <Container>
                        <Row>
                            <Col lg={3}>
                                <div className="osahan-account-page-left shadow-sm bg-white">
                                    {this.props.user.VendorID ?
                                        <Badge
                                            variant='primary'
                                            className='p-2 category-tag ml-2 mt-2'>
                                            Händler
                                        </Badge>
                                        :
                                        null
                                    }
                                    <div
                                        className={(this.props.user.VendorID ? 'pb-4 px-4 pt-2' : ' p-4') + " border-bottom"}>
                                        <div className="osahan-user text-left">
                                            <div className="osahan-user-media">
                                                <div className="osahan-user-media-body text-center">
                                                    <h6 className="mb-2">{(this.props.user.FirstName ?? '') + ' ' + (this.props.user.Surname ?? '')}</h6>
                                                    <p>{(this.props.user.Email ?? '')}</p>
                                                    <p className="mb-0 text-black font-weight-bold text-center">
                                                        <Link to='/myaccount' className="text-primary mr-3">
                                                            <i className="icofont-ui-edit"></i>&nbsp;
                                                            BEARBEITEN
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <ul className="nav flex-lg-column border-0 pt-4 pl-4 pb-4">
                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName="active" exact
                                                     to="/myaccount/"><i
                                                className="icofont-user"></i> <span className='d-none d-md-inline'>Meine Daten</span></NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName="active" exact
                                                     to="/myaccount/orders"><i
                                                className="icofont-food-cart"></i> <span
                                                className='d-none d-md-inline'>Bestellungen</span></NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName="active" exact
                                                     to="/myaccount/bookmarks"><i
                                                className="icofont-heart"></i> <span
                                                className='d-none d-md-inline'>Merkliste</span></NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName="active" exact
                                                     to="/myaccount/addresses"><i
                                                className="icofont-location-pin"></i> <span
                                                className='d-none d-md-inline'>Adressen</span></NavLink>
                                        </li>
                                    </ul>
                                    <div className='border-bottom'></div>
                                    <ul className="nav flex-lg-column border-0 pt-4 pl-4 pb-4">
                                        <li className="nav-item">
                                            <NavLink className="nav-link" activeClassName="active" exact
                                                     to="/myaccount/vendorregistration"><i
                                                className="icofont-euro"></i><span
                                                className='d-none d-md-inline'>{this.props.user.VendorID !== 0 ? 'Händlerdaten' : 'Händlerregistrierung'}</span>
                                            </NavLink>
                                        </li>
                                        {this.props.user.VendorID !== 0 && this.props.user.StripeAccountID ?
                                            <li className="nav-item">
                                                <NavLink className="nav-link" activeClassName="active" exact
                                                         to="/myaccount/paymentdata"><i
                                                    className="icofont-euro"></i><span
                                                    className='d-none d-md-inline'>Zahlungsinformation</span>
                                                </NavLink>
                                            </li>
                                            : null
                                        }
                                        {this.props.user.VendorID !== 0 && this.props.user.VendorStripeAccountCompleted ?
                                            <>
                                                <NavLink className="nav-link" activeClassName="active" exact
                                                         to="/myaccount/shippingsettings">
                                                    <Icofont icon='fast-delivery'/>
                                                    <span className='d-none d-md-inline'>Versandoptionen</span>
                                                </NavLink>
                                                <NavLink className="nav-link" activeClassName="active" exact
                                                         to="/myaccount/products">
                                                    <Icofont icon='package'/>
                                                    <span className='d-none d-md-inline'>Produkte</span>
                                                </NavLink>
                                                <NavLink className="nav-link" activeClassName="active" exact
                                                         to="/myaccount/vendororders">
                                                    <Icofont icon='icofont-food-cart'/>
                                                    <span className='d-none d-md-inline'>Kundenbestellungen</span>
                                                </NavLink>
                                            </>
                                            :
                                            null
                                        }
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={9}>
                                {this.props.memberID ?
                                    <Switch>
                                        <Route path="/myaccount/orders" exact component={Orders}/>
                                        <Route path="/myaccount/order/:id" exact component={Order}/>
                                        <Route path="/myaccount/vendororders" exact component={VendorOrders}/>
                                        <Route path="/myaccount/bookmarks" exact component={BookmarkCollection}/>
                                        <Route path="/myaccount/payments" exact component={Payments}/>
                                        <Route path="/myaccount/addresses" exact component={Addresses}/>
                                        <Route path="/myaccount/address/:id" exact component={AddressForm}/>
                                        <Route path="/myaccount/vendorregistration" exact
                                               component={VendorRegistration}/>
                                        <Route path="/myaccount/paymentdata" exact
                                               component={StripePaymentOnBoarding}/>
                                        <Route path="/myaccount/shippingsettings" exact component={ShippingSettings}/>
                                        <Route path="/myaccount/shippingsettings/:id" exact
                                               component={ShippingRateForm}/>
                                        <Route path="/myaccount/products" exact component={Products}/>
                                        <Route path="/myaccount/products/:id" exact component={ProductForm}/>
                                        <Route path="/myaccount/" exact component={ProfilData}/>
                                    </Switch>
                                    :
                                    this.props.history.push('/')
                                }

                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
});


const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MyAccount));
