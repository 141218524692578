import React, {Component} from 'react';
import {Button, Form, Spinner} from "react-bootstrap";
import {ElementsConsumer, IbanElement} from "@stripe/react-stripe-js";
import './Stripe.style.scss';

class SEPAForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sepaHolderName: '',
            sepaHolderEmail: '',
            stripePaymentID: '',
            stripePaymentError: '',
            sepaAbg: false,
            sepaLoading: 0,
            disabled: true,
        }

        this.handleSepaHolderNameChange = this.handleSepaHolderNameChange.bind(this);
        this.handleSepaHolderEmailChange = this.handleSepaHolderEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSepaHolderNameChange(e) {
        let text = e.target.value;
        this.setState({sepaHolderName: text}, () => {
            this.checkFormStatus();
        });
    }

    handleSepaHolderEmailChange(e) {
        let text = e.target.value;
        this.setState({sepaHolderEmail: text}, () => {
            this.checkFormStatus();
        });
    }

    checkFormStatus() {
        if (
            this.state.sepaHolderName !== '' &&
            this.state.sepaHolderEmail !== '' &&
            this.state.sepaAbg
        ) {
            this.setState({
                disabled: false
            })
        } else {
            this.setState({
                disabled: true
            })
        }
    }

    handleSubmit() {
        const {stripe, elements} = this.props
        const iban = elements.getElement(IbanElement);
        const accountholderName = this.state.sepaHolderName;
        const email = this.state.sepaHolderEmail;
        const me = this;
        stripe.createPaymentMethod({
            type: 'sepa_debit',
            sepa_debit: iban,
            billing_details: {
                name: accountholderName,
                email: email
            },
        }).then(function (result) {
            if (result.error) {
                me.setState({
                    stripePaymentError: result.error.message
                })
            } else {
                if (typeof me.props.handleStripeData === 'function') {
                    me.props.handleStripeData(result.paymentMethod.id)
                }
            }
        });
    }

    render() {
        return (
            this.state.sepaLoading ?
                <Spinner animation="border" role="status"
                         className='mx-auto d-block align-self-center'>
                    <span className="sr-only">Loading...</span>
                </Spinner>
                :
                <>
                    <div className="form-label-group m-0">
                        <Form.Control
                            type="text"
                            id="inputSepaHolderName"
                            placeholder="Name des Kontobesitzers*"
                            onChange={this.handleSepaHolderNameChange}
                            disabled={this.state.loading}
                            value={this.state.sepaHolderName}
                            className='input-foreground'
                        />
                        <Form.Label htmlFor="inputCompany">Name des Kontobesitzers*</Form.Label>
                    </div>
                    <div className="form-label-group m-0">
                        <Form.Control
                            type="text"
                            id="inputSepaHolderEmail"
                            placeholder="Email des Kontobesitzers*"
                            onChange={this.handleSepaHolderEmailChange}
                            disabled={this.state.loading}
                            value={this.state.sepaHolderEmail}
                            className='input-foreground'
                        />
                        <Form.Label htmlFor="inputCompany">Email des Kontobesitzers*</Form.Label>
                    </div>
                    <div className="form-label-group m-0">
                        <IbanElement options={{
                            supportedCountries: ['SEPA'],
                            // Elements can use a placeholder as an example IBAN that reflects
                            // the IBAN format of your customer's country. If you know your
                            // customer's country, we recommend that you pass it to the Element as the
                            // placeholderCountry.
                            placeholderCountry: 'AT',
                            style: {
                                base: {
                                    color: '#000',
                                    fontSize: '16px',
                                    '::placeholder': {
                                        color: '#aab7c4'
                                    },
                                    ':-webkit-autofill': {
                                        color: '#32325d',
                                    },
                                },
                                invalid: {
                                    color: '#fa755a',
                                    iconColor: '#fa755a',
                                    ':-webkit-autofill': {
                                        color: '#fa755a',
                                    },
                                }
                            }
                        }}/>
                        <p className='text-danger m-0'>{this.state.stripePaymentError}&nbsp;</p>
                    </div>
                    <Form.Group controlId='sepaAGB' className='pt-3'>
                        <Form.Check
                            type="checkbox"
                            label={'Durch die Bereitstellung Ihrer IBAN und die Bestätigung dieser Zahlung ermächtigen Sie (A) Regiononlinemark GmbH und Stripe, unseren Zahlungsdienstleister, Anweisungen an Ihre Bank zu senden, um Ihr Konto zu belasten, und (B) Ihre Bank, Ihr Konto gemäß diesen Anweisungen zu belasten . Sie haben Anspruch auf eine Rückerstattung von Ihrer Bank gemäß den Bedingungen Ihrer Vereinbarung mit Ihrer Bank. Eine Rückerstattung muss innerhalb von acht Wochen ab dem Datum der Belastung Ihres Kontos beantragt werden.'}
                            checked={this.state.sepaAbg}
                            onChange={() => this.setState({sepaAbg: !this.state.sepaAbg}, () => this.checkFormStatus())}
                        />
                    </Form.Group>
                    <div className='pt-3 pb-3'>
                        <Button variant='outline-primary' onClick={this.handleSubmit} disabled={this.state.disabled}
                                className='w-100'>
                            Bankdaten verifizieren
                        </Button>
                    </div>
                </>

        )

    }
}

export default class StripeSEPAForm extends Component {
    render() {
        return <ElementsConsumer>
            {({stripe, elements}) => (
                <SEPAForm stripe={stripe} elements={elements} handleStripeData={this.props.handleStripeData}/>
            )}
        </ElementsConsumer>
    }

}
