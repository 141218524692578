import React, {Component} from "react";
import {connect} from "react-redux";
import {Alert, Button, Form} from "react-bootstrap";
import {ApiService} from "../../services/ApiService";

class ProductRequestForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            emailError: '',
            address: '',
            message: '',
            error: '',
            success: ''
        }

        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleEmailChange = this.handleEmailChange.bind(this)
        this.handleAddressChange = this.handleAddressChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    updateGeoPosition() {
        if (this.props.coords.geocodeAddress) {
            this.setState({address: this.props.coords.geocodeAddress.formatted_address ?? ''});
        }
    }

    componentDidMount() {
        if (this.props.user && typeof this.props.user.FirstName !== 'undefined') {
            let firstname = this.props.user.FirstName ?? '';
            let surname = this.props.user.Surname ?? ''
            this.setState({
                name: firstname + ' ' + surname,
                email: this.props.user.Email
            })
        }

        if (typeof this.props.product !== 'undefined' && typeof this.props.product.Title !== 'undefined') {
            this.setState({
                message: 'Ich interessiere mich für Ihr Produkt (' + this.props.product.Title + '). Bitte lassen Sie mir dazu Informationen zukommen'
            })
        }

        if (typeof this.props.coords) {
            this.updateGeoPosition();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof this.props.product !== 'undefined' && typeof this.props.product.Title !== 'undefined' && JSON.stringify(prevProps.product) !== JSON.stringify(this.props.product)) {
            this.setState({
                message: 'Ich interessiere mich für Ihr Produkt (' + this.props.product.Title + ').\nBitte lassen Sie mir dazu Informationen zukommen'
            })
        }
        if (typeof prevProps.coords.geocodeAddress !== 'undefined' && JSON.stringify(prevProps.coords.geocodeAddress) !== JSON.stringify(this.props.coords.geocodeAddress)) {
            this.updateGeoPosition();
        }
    }

    handleSubmit() {
        if (this.state.emailError) {
            this.setState({error: 'Überprüfe deine Eingabe'});
            return;
        }
        if (
            this.state.name.length <= 0 ||
            this.state.email.length <= 0 ||
            this.state.address.length <= 0 ||
            this.state.message.length <= 0
        ) {
            this.setState({error: 'Bitte fülle alle Felder aus!', success: ''});
            return;
        }
        ApiService.baseClient.post(
            '/sendRequest/' + this.props.product.ID,
            {
                Name: this.state.name,
                Email: this.state.email,
                Address: this.state.address,
                Message: this.state.message,
            }
        )
            .then((response) => {
                this.setState({error: '', success: 'Vielen Dank für deine Nachricht'});
            })
            .catch((error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                this.setState({loading: false, error: errorMsg});
            })

    }

    handleNameChange(e) {
        let text = e.target.value;
        this.setState({name: text});
    }

    handleEmailChange(e) {
        let text = e.target.value;
        this.setState({email: text});
        let emailValidation = text.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        if (emailValidation === null && text.length > 0) {
            this.setState({emailError: 'Bitte gib eine gültige E-Mail Adresse ein!'});
        } else {
            this.setState({emailError: ''});
        }
    }

    handleAddressChange(e) {
        let text = e.target.value;
        this.setState({address: text});
    }

    handleMessageChange(e) {
        let text = e.target.value;
        this.setState({message: text});
    }

    render() {
        return (
            this.state.success ?
                <Alert variant="success">{this.state.success}</Alert>
                :
                <Form>
                    <div className="form-label-group m-0">
                        <Form.Control
                            type="text"
                            id='inputName'
                            placeholder="Name"
                            onChange={this.handleNameChange}
                            disabled={!!(this.state.loading || this.state.disabled)}
                            value={this.state.name}
                            className='input-foreground'
                        />
                        <Form.Label htmlFor='inputName'>Name</Form.Label>
                    </div>
                    <div className="form-label-group m-0">
                        <Form.Control
                            type="text"
                            id='inputEmail'
                            placeholder="E-Mail"
                            onChange={this.handleEmailChange}
                            disabled={!!(this.state.loading || this.state.disabled)}
                            value={this.state.email}
                            className='input-foreground'
                        />
                        <Form.Label htmlFor='inputEmail'>E-Mail</Form.Label>
                        <p className='text-danger m-0'>{this.state.emailError}&nbsp;</p>
                    </div>
                    <div className="form-label-group m-0">
                        <Form.Control
                            type="text"
                            id='inputAddress'
                            placeholder="Addresse"
                            onChange={this.handleAddressChange}
                            disabled={!!(this.state.loading || this.state.disabled)}
                            value={this.state.address}
                            className='input-foreground'
                        />
                        <Form.Label htmlFor='inputAddress'>Addresse</Form.Label>
                    </div>
                    <div className="form-group m-0 mt-1">
                        <Form.Label htmlFor="inputMessage" className='custom-label'>Nachricht</Form.Label>
                        <Form.Control as="textarea" rows="4"
                                      id="inputMessage"
                                      placeholder="Nachricht"
                                      onChange={this.handleMessageChange}
                                      disabled={this.state.loading}
                                      value={this.state.message}
                                      className='input-foreground'
                        />
                    </div>
                    {this.state.error ?
                        <Alert variant="danger" className='mt-2'>{this.state.error}</Alert>
                        : null
                    }
                    <Button onClick={this.handleSubmit} className='btn-block mt-3'>
                        Anfragen
                    </Button>

                </Form>

        )
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
    user: state.user.user,
    memberID: state.user.memberID,
});


export default connect(
    mapStateToProps,
    null
)(ProductRequestForm);
