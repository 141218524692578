import React from 'react';
import {withRouter} from 'react-router-dom';
import {Alert, Col, Container, Form, Row} from 'react-bootstrap';
import LoginBackground from "../../assets/img/img_login.jpg";
import {ApiService} from "../../services/ApiService";
import {connect} from 'react-redux';
import UserActions from '../../stores/user/actions';
import BasketAction from "../../stores/basket/actions";
import {toastr} from "react-redux-toastr";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";

class PasswordRest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pwr: {},
            password: '',
            passwordRepeat: '',
            passwordError: '',
            passwordRepeatError: '',
            loading: false,
            error: '',
            errors: [],
        };

        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePasswordRepeatChange = this.handlePasswordRepeatChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let hash = this.props.match.params.hash;
        if (hash) {
            ApiService.client.get("/PasswordResetRequest/?filter[Hash]=" + hash)
                .then(result => {
                    console.log(result)
                    if (typeof result.data[0] !== 'undefined' && result.data[0].Done !== 1) {
                        this.setState({
                            pwr: result.data[0]
                        })
                    } else {
                        console.log('error')
                        this.setState({loading: false, error: 'Keine gültiger Passwort zurücksetzen Link'});
                    }
                }).catch((error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error('Hoppla', errorMsg)
                this.setState({loading: false, error: ''});
            })
        } else {
            this.setState({loading: false, error: 'Keine gültiger Passwort zurücksetzen Link'});
        }
    }

    handlePasswordChange(e) {
        let text = e.target.value;
        this.setState({password: text});
        if (text.length < 8) {
            this.setState({passwordError: 'Das Passwort muss mindestens 8 Zeichen beinhalten!'});
        } else {
            this.setState({passwordError: ''});
        }
    }

    handlePasswordRepeatChange(e) {
        let text = e.target.value;
        this.setState({passwordRepeat: text});
        if (text !== this.state.password) {
            this.setState({passwordRepeatError: 'Die Passwörter stimmen nicht überein!'});
        } else {
            this.setState({passwordRepeatError: ''});
        }

    }

    handleSubmit() {
        const me = this;
        if (this.state.password.length < 8 || this.state.passwordError.length > 0 || this.state.passwordRepeatError.length > 0) {
            return false;
        }
        if (typeof this.state.pwr.Hash === "undefined") {
            return false;
        }
        ApiService.client.post(
            'PasswordResetRequest/setpassword/' + this.state.pwr.Hash,
            {
                Hash: this.state.pwr.Hash,
                Password: this.state.password,
            }
        )
            .then(response => {
                if (response.data.valid === false) {
                    me.setState({loading: false, errors: response.data.messages});
                } else {
                    toastr.success('Passwort zurückgesetzt', 'Dein Passwort wurd erfolgreich geändert')
                    me.props.login(response.data.Key, response.data.ValidUntil, response.data.MemberID);
                    me.props.setBasketDoneLoading();
                    setTimeout(() => {
                        me.props.fetchBasket();
                    }, 200)
                    this.props.history.push('/');
                }
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten'
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            if (error.response && error.response.data && error.response.data.messages && error.response.data.messages.length) {
                msg = error.response.data.messages[0].message
            }
            me.setState({loading: false, error: msg});
        })
    }


    render() {
        return (
            <Container fluid className='bg-transparent'>
                <CustomMetaTags
                    title={"Region im Netz - Anmelden"}
                />
                <Row>
                    <Col md={4} lg={6} className="d-none d-lg-flex bg-image"
                         style={{backgroundImage: `url(${LoginBackground})`}}/>
                    <Col md={12} lg={6}>
                        <div className="login d-flex align-items-center py-5">
                            <Container>
                                <Row>
                                    <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                                        <h3 className="login-heading mb-4">Passwort ändern!</h3>
                                        <Form>
                                            <div className="form-label-group m-0">
                                                <Form.Control
                                                    type="password"
                                                    autoComplete="none"
                                                    id="inputPassword"
                                                    placeholder="Passwort"
                                                    onChange={this.handlePasswordChange}
                                                    disabled={this.state.loading}
                                                    value={this.state.password}
                                                    className='input-foreground'
                                                />
                                                <Form.Label htmlFor="inputPassword">Passwort</Form.Label>
                                                <p className='text-danger m-0'>{this.state.passwordError}&nbsp;</p>
                                            </div>
                                            <div className="form-label-group m-0">
                                                <Form.Control
                                                    type="password"
                                                    autoComplete="none"
                                                    id="inputPasswordRepeat"
                                                    placeholder="Passwort wiederholen"
                                                    onChange={this.handlePasswordRepeatChange}
                                                    disabled={this.state.loading}
                                                    value={this.state.passwordRepeat}
                                                    className='input-foreground'
                                                />
                                                <Form.Label htmlFor="inputPasswordRepeat">Passwort
                                                    wiederholen</Form.Label>
                                                <p className='text-danger m-0'>{this.state.passwordRepeatError}&nbsp;</p>
                                            </div>
                                            {this.state.error ?
                                                <Alert variant="danger">{this.state.error}</Alert>
                                                : null
                                            }
                                            <div onClick={this.handleSubmit} disabled={this.state.loading}
                                                 className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2 mt-5">
                                                Passwort ändern
                                            </div>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    login: (authKey, validUntil, memberID) => dispatch(UserActions.login(authKey, validUntil, memberID)),
    setBasketDoneLoading: () => dispatch(BasketAction.setBasketDoneLoading()),
    fetchBasket: () => dispatch(BasketAction.fetchBasket())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(PasswordRest));

