import {call, put, select} from 'redux-saga/effects';
import BasketActions from '../stores/basket/actions';
import {ApiService} from "../services/ApiService";

const getAuth = (state) => state.user.authKey;
const getBasketID = (state) => state.basket.basketID;

/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* fetchBasket() {
    const auth = yield select(getAuth);
    const basketID = yield select(getBasketID);
    yield put(BasketActions.setBasketLoading())
    let response;
    if (!auth) {
        response = yield call(ApiService.client.get, '/Basket/fetch/' + (basketID != 0 ? basketID : '') + '?m=' + Date.now());
    } else {
        response = yield call(ApiService.authorizedClient(auth).get, '/Basket/fetch/' + (basketID != 0 ? basketID : '') + '?m=' + Date.now());
    }
    if (typeof response.data !== 'undefined') {
        yield put(BasketActions.setBasketId(response.data.ID));
        yield put(BasketActions.setItemCount(response.data.TotalItems));
        yield put(BasketActions.setList(response.data.List));
        yield put(BasketActions.setTotal(response.data.Total));
    }
    yield put(BasketActions.setBasketDoneLoading());
}

/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* setAndFetchBasket({basketID}) {
    yield put(BasketActions.setBasketId(basketID));
    yield put(BasketActions.fetchBasket());
}
