import React, {Fragment} from 'react';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import './LocationField.scss';
import {Form} from "react-bootstrap";
import {connect} from "react-redux";
import CoordsActions from "../../stores/coords/actions";
import Icofont from "../Icofont/Icofont";

let timeout;

class LocationField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            geolocationAvailable: false,
            locating: false,
            latitude: 0,
            longitude: 0,
            sessionToken: null
        };
        this.updateGeoPosition = this.updateGeoPosition.bind(this);
        this.handleLocateClick = this.handleLocateClick.bind(this);
    }


    componentDidMount() {
        this.setSessionToken();
        if (this.props.coords.latitude) {
            this.updateGeoPosition(this.props.coords);
            if (typeof this.props.coords.geocodeAddress !== 'undefined') {
                this.setState({address: this.props.coords.geocodeAddress.formatted_address},
                    () => this.setState({
                        locating: false,
                    })
                );
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.coords.latitude !== this.state.latitude || this.props.coords.longitude !== this.state.longitude) {
            this.updateGeoPosition(this.props.coords);
        }

        if (
            typeof this.props.coords.geocodeAddress !== 'undefined' &&
            (typeof prevProps.coords.geocodeAddress === 'undefined' || JSON.stringify(prevProps.coords.geocodeAddress) !== JSON.stringify(this.props.coords.geocodeAddress))
        ) {
            this.setState({address: this.props.coords.geocodeAddress.formatted_address},
                () => this.setState({
                    locating: false,
                })
            );
        }
    }

    handleLocateClick() {
        this.setState({locating: true});
        this.props.reset();
        timeout = setTimeout(() => this.setState({geolocationAvailable: false, locating: false}), 15000);
    }

    updateGeoPosition(position) {
        this.setState({
            locating: false,
            latitude: position.latitude,
            longitude: position.longitude,
            geolocationAvailable: position.custom
        });

        if (!this.props.coords.custom) {
            clearTimeout(timeout);
        }
    }

    handleChange = address => {
        this.setState({address});
    };

    handleSelect = address => {
        //TODO: FIX DE OR OTHER PLACES NOT FROM AT
        if (!address.includes('Österreich') && !address.includes('Austria')) {
            address += ', Österreich'
        }
        this.handleChange(address);
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setSessionToken();
                this.props.updateLocation(latLng.lat, latLng.lng)
            })
            .catch(error => console.error('Error', error));
    };

    setSessionToken() {
        let token = new window.google.maps.places.AutocompleteSessionToken();
        this.setState({
            sessionToken: token
        })
    }


    render() {
        const searchOptions = {
            componentRestrictions: {country: 'at'}
        };
        if (this.state.latitude) {
            searchOptions.location = {lat: () => this.state.latitude, lng: () => this.state.longitude}
            searchOptions.radius = 10000
        }
        searchOptions.sessionToken = this.state.sessionToken;
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={searchOptions}
            >
                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                    <Fragment>
                        <Form.Group className={this.props.className + ' location-field'}>
                            <Form.Control {...getInputProps({
                                placeholder: this.props.placeholder ?? 'zB. "4020 Linz" oder "Schörgenhubstraße 41, 4030 Linz"',
                                className: 'location-search-input',
                                size: 'lg'
                            })} value={this.state.address} disabled={this.state.locating}/>
                            {this.state.geolocationAvailable &&
                            <button className="locate-me"
                                    onClick={this.handleLocateClick}
                                    disabled={this.state.locating}
                            >
                                <Icofont icon='ui-pointer'/>
                                <span>Standort bestimmen</span> {this.state.locating && '...'}
                            </button>
                            }
                            <div className="autocomplete-dropdown-container">
                                {loading && <div className={"suggestion-item"}>Lade...</div>}
                                {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                        ? 'suggestion-item active'
                                        : 'suggestion-item';
                                    return (
                                        <div key={index} {...getSuggestionItemProps(suggestion, {
                                            className,
                                        })}>
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </Form.Group>
                    </Fragment>
                )}
            </PlacesAutocomplete>
        );
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
});

const mapDispatchToProps = (dispatch) => ({
    updateLocation: (latitude, longitude) => dispatch(CoordsActions.updateLocation(latitude, longitude, true)),
    reset: () => dispatch(CoordsActions.reset()),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocationField);
