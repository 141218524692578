import React, {Component} from 'react'
import Dropzone from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import Icofont from "../Icofont/Icofont";
import {Button} from "react-bootstrap";

export default class UploadField extends Component {
    static defaultProps = {
        files: [],
    };

    state = {
        currentFiles: [],
        message: ''
    }

    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeStatus = this.handleChangeStatus.bind(this);
    }

    // specify upload params and url for your files
    getUploadParams = ({meta}) => {
        return {url: process.env.REACT_APP_FILE_UPLOAD_URL}
    }

    componentDidMount() {
        this.setState({currentFiles: this.props.files});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.files) !== JSON.stringify(this.props.files)) {
            this.setState({currentFiles: this.props.files});
        }
    }

    // called every time a file's `status` changes
    handleChangeStatus = (fileWithMeta, status, files) => {
        if (status === 'done') {
            let newFiles = [...this.state.currentFiles, (JSON.parse(fileWithMeta.xhr.response))];

            if (this.props.maxFiles <= this.state.currentFiles.length) {
                newFiles.shift();
            }

            this.setState({currentFiles: newFiles}, () => {
                if (typeof this.props.onChange === 'function') {
                    this.props.onChange(this.state.currentFiles);
                }
            })
            fileWithMeta.remove();
        }

        if (status === 'error_upload') {
            if (fileWithMeta.xhr.readyState !== 4) {
                fileWithMeta.xhr.onloadend = () => {
                    this.setState(JSON.parse(fileWithMeta.xhr.response));
                }
            } else {
                this.setState(JSON.parse(fileWithMeta.xhr.response));
            }
            fileWithMeta.remove();
        }

        if (status === 'preparing') {
            this.setState({message: ''});
        }

        if (status === 'rejected_file_type') {
            this.setState({message: 'Dieser Dateityp ist nicht erlaubt!'});
        }

        if (status === 'error_file_size') {
            this.setState({message: 'Eine deienr Dateien übersteigt die maximale Dateigröße!'});
        }

        if (status === 'error_upload_params' || status === 'exception_upload') {
            this.setState({message: 'Ein unbekannter Fehler ist aufgetreten!'});
        }
    }

    handleDelete(file) {
        this.setState({
                currentFiles: this.state.currentFiles.filter((item, index) => {
                    return file.ID !== item.ID;
                }),
            },
            () => {
                if (typeof this.props.onDelete === 'function') {
                    this.props.onDelete(file, this.state.currentFiles);
                }
            }
        )

    }

    render() {
        return (
            <div className="my-uploadfield">
                {this.state.message.length ? <p className="text-center text-danger">{this.state.message}</p> : null}
                {this.props.maxFiles === 1 && typeof this.props.files !== 'undefined' && this.props.files.length ?
                    null
                    :
                    <Dropzone
                        getUploadParams={this.getUploadParams}
                        canRestart={false}
                        canCancel={false}
                        canRemove={false}
                        submitButtonContent={null}
                        inputContent='Ziehe deine Dateien hierher oder klicke zum Durchsuchen'
                        accept="image/*,audio/*,video/*"
                        classNames={{
                            inputLabelWithFiles: 'd-none',
                            inputLabel: 'dzu-inputLabel text-center text-muted',
                            dropzone: 'dzu-dropzone overflow-auto'
                        }}
                        {...this.props}
                        onChangeStatus={this.handleChangeStatus}
                    />
                }

                <div className="dzu-currentFiles">
                    {this.state.currentFiles.map((file, index) => {
                        return (<div key={file.ID} className="dzu-previewContainer">
                                <img className="dzu-previewImage custom-dzu-previewImage"
                                     src={file.AbsoluteURL}
                                     alt={file.Title}
                                     title={file.Title}/>
                                <div className="dzu-previewStatusContainer">
                                    <span className="dzu-title">{file.Title}</span>
                                    <Button variant="link" className="dzu-previewButton"
                                            onClick={() => this.handleDelete(file)}>
                                        <Icofont icon='trash'/>
                                    </Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
