import React, {Component} from "react";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import countryList from "../../custom-country-list/CustomCountryList";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {Alert, Col, Form, Row, Spinner} from "react-bootstrap";
import Select from "react-select";
import Icofont from "../../components/Icofont/Icofont";
import {HTMLService} from "../../services/HTMLService";

class CheckoutAddressFrom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addressID: this.props.addressID ?? 0,
            addresses: [],
            addressOptions: [{value: 0, label: 'Neue Adresse'}],
            street: '',
            streetError: '',
            street2: '',
            street2Error: '',
            number: '',
            numberError: '',
            city: '',
            cityError: '',
            country: '',
            countryError: '',
            zip: '',
            zipError: '',
            company: '',
            companyError: '',
            companyDisabled: false,
            uid: '',
            uidError: '',
            loading: false,
            disabled: !!this.props.addressID,
            error: '',
            success: '',
            page: 1,
            options: countryList().getData()
        };

        this.handleStreetChange = this.handleStreetChange.bind(this);
        this.handleStreet2Change = this.handleStreet2Change.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.handleDataSubmit = this.handleDataSubmit.bind(this);
        this.getAddresses = this.getAddresses.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.updateGeoPosition = this.updateGeoPosition.bind(this);
        this.handleDisableChanged = this.handleDisableChanged.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleUIDChange = this.handleUIDChange.bind(this);
    }

    handleCompanyChange(e) {
        let text = e.target.value;
        this.setState({company: text});

    }

    handleUIDChange(e) {
        let text = e.target.value;
        this.setState({uid: text});
        if (text.length) {
            ApiService.vatClient.get(text)
                .then((response) => {
                    let company = this.state.company;
                    let companyDisabled = false;
                    if (response.data.name.length && response.data.name !== '---') {
                        company = response.data.name;
                        companyDisabled = true;
                    }
                    this.setState({
                        uidError: '',
                        company: company,
                        companyDisabled: companyDisabled
                    })
                })
                .catch((error) => {
                    let errormsg = '';
                    if (error.response.data.validationResult.messages.length) {
                        error.response.data.validationResult.messages.forEach((msg) => {
                            errormsg += msg.message + '<br>'
                        })
                    }
                    this.setState({
                        uidError: errormsg,
                        companyDisabled: false
                    })
                })
        } else {
            this.setState({uidError: '', companyDisabled: false})
        }

    }

    handleStreetChange(e) {
        let text = e.target.value;
        this.setState({street: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({streetError: 'Bitte gib eine gültige Straße ein!'});
        } else {
            this.setState({streetError: ''});
        }

    }

    handleStreet2Change(e) {
        let text = e.target.value;
        this.setState({street2: text});
    }

    handleNumberChange(e) {
        let text = e.target.value;
        this.setState({number: text});
        if (text.match(/^[0-9]+[a-zA-Z]{0,3}$/) === null) {
            this.setState({numberError: 'Bitte gib eine gültige Hausnummer ein!'});
        } else {
            this.setState({numberError: ''});
        }
    }

    handleCityChange(e) {
        let text = e.target.value;
        this.setState({city: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({cityError: 'Bitte gib eine gültige Ort ein!'});
        } else {
            this.setState({cityError: ''});
        }
    }

    handleCountryChange(target) {
        let text = target.value;
        this.setState({country: text});
    }

    handleZipChange(e) {
        let text = e.target.value;
        this.setState({zip: text});
    }

    handleDataSubmit() {
        if (
            this.state.streetError ||
            this.state.numberError ||
            this.state.cityError ||
            this.state.countryError ||
            this.state.zipError
        ) {
            this.setState({error: 'Überprüfe deine Eingabe'});
            return;
        }
        if (
            this.state.street.length <= 0 ||
            this.state.number.length <= 0 ||
            this.state.city.length <= 0 ||
            this.state.country.length <= 0 ||
            this.state.zip.length <= 0
        ) {
            this.setState({error: 'Bitte fülle alle Felder aus!'});
            return;
        }
        const me = this;
        me.setState({loading: true});
        let newAddress = this.state.addressID ? false : true;
        if (this.state.addressID && this.state.addressID === this.props.checkWithAddressID) {
            let checkAddress = this.state.addresses.find((item) => item.ID === this.props.checkWithAddressID)
            if (checkAddress) {
                let currentEntry = {
                    Street: me.state.street,
                    Street2: me.state.street2,
                    Number: me.state.number,
                    City: me.state.city,
                    Country: me.state.country,
                    PostalCode: me.state.zip,
                    MemberID: me.props.memberID,
                    Company: me.state.company,
                    UID: me.state.uid
                }
                let checkAddressData = {
                    Street: checkAddress.Street,
                    Street2: me.Street2 ?? '',
                    Number: checkAddress.Number,
                    City: checkAddress.City,
                    Country: checkAddress.Country,
                    PostalCode: checkAddress.PostalCode,
                    MemberID: checkAddress.MemberID,
                    Company: checkAddress.Company,
                    UID: checkAddress.UID
                }
                if (JSON.stringify(checkAddressData) !== JSON.stringify(currentEntry)) {
                    newAddress = true;
                } else {
                    newAddress = false;
                }
            }
        }
        if (newAddress) {
            ApiService.authorizedClient(this.props.authKey).post(
                '/Address',
                {
                    'Street': me.state.street,
                    'Street2': me.state.street2,
                    'Number': me.state.number,
                    'City': me.state.city,
                    'Country': me.state.country,
                    'PostalCode': me.state.zip,
                    'MemberID': me.props.memberID,
                    'Company': me.state.company,
                    'UID': me.state.uid,
                }
            ).then(
                (response) => {
                    me.setState({loading: false, error: '', addressID: response.data.ID, disabled: true});
                    if (typeof me.props.setAddress === 'function') {
                        me.props.setAddress(response.data.ID);
                    }
                    if (typeof this.props.changeEditMode === 'function') {
                        this.props.changeEditMode(false)
                    }
                    me.getAddresses()
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                    me.setState({loading: false, error: ''});
                },
            );
        } else {
            ApiService.authorizedClient(this.props.authKey).put(
                '/Address/' + this.state.addressID,
                {
                    'Street': me.state.street,
                    'Street2': me.state.street2,
                    'Number': me.state.number,
                    'City': me.state.city,
                    'Country': me.state.country,
                    'Zip': me.state.zip,
                    'MemberID': me.props.memberID
                }
            ).then(
                (response) => {
                    me.setState({loading: false, error: '', addressID: response.data.ID, disabled: true});
                    if (typeof me.props.setAddress === 'function') {
                        me.props.setAddress(response.data.ID);
                    }
                    if (typeof this.props.changeEditMode === 'function') {
                        this.props.changeEditMode(false)
                    }
                    me.getAddresses()
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                },
            );
        }
    }


    getAddresses(setAddressID = 0, setFirst = false) {
        if (this.props.memberID) {
            this.setState({loading: true});
            ApiService.client.get("/Address/?filter[MemberID]=" + this.props.memberID)
                .then((response) => {
                    if (setAddressID) {
                        let displayAddress = response.data.find((item) => item.ID === setAddressID)
                        if (displayAddress) {
                            this.setState(
                                {
                                    company: displayAddress.Company,
                                    uid: displayAddress.UID,
                                    street: displayAddress.Street,
                                    street2: displayAddress.Street2 ?? '',
                                    number: displayAddress.Number,
                                    city: displayAddress.City,
                                    country: displayAddress.Country,
                                    zip: displayAddress.PostalCode,
                                }
                            )
                        }
                    }
                    this.setState({
                        addresses: response.data,
                        error: '',
                        loading: false
                    }, () => {
                        this.setAddressOptions()
                        if (!setAddressID && setFirst && typeof response.data[0] !== 'undefined') {
                            this.setState({
                                addressID: response.data[0].ID,
                                disabled: true
                            });
                            if (typeof this.props.changeEditMode === 'function') {
                                this.props.changeEditMode(false)
                            }
                        }
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message, loading: false});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                    }
                })
        }
    }

    setAddressOptions() {
        let addressOptions = [{value: 0, label: 'Neue Adresse'}]
        this.state.addresses.map((item) => addressOptions.push({value: item.ID, label: item.Title}))
        this.setState({
            addressOptions: addressOptions
        })
    }

    handleAddressChange(target) {
        let text = target.value;
        this.setState({
            addressID: text,
            disabled: text !== 0 ? true : false
        });
    }

    handleDisableChanged() {
        this.setState({
            disabled: !this.state.disabled
        });
        if (typeof this.props.changeEditMode === 'function') {
            this.props.changeEditMode(true)
        }
    }

    updateGeoPosition() {
        if (this.props.coords.geocodeAddress) {
            const address = ApiService.getAddressObject(this.props.coords.geocodeAddress.address_components ?? []);
            let city = '';
            if (
                typeof address.route === 'undefined' ||
                typeof address.street_number === 'undefined' ||
                typeof address.country === 'undefined' ||
                typeof address.postal_code === 'undefined'
            ) {
                return null;
            }
            if (typeof address.locality !== 'undefined') {
                city = address.locality.long_name
            }
            if (!city && typeof address.administrative_area_level_2 !== 'undefined') {
                city = address.administrative_area_level_2.long_name;
            }
            if (!city && typeof address.administrative_area_level_1 !== 'undefined') {
                city = address.administrative_area_level_1.long_name;
            }
            this.setState({
                street: address.route.long_name ?? '',
                number: address.street_number.long_name ?? '',
                city: city,
                country: address.country.short_name ?? '',
                zip: address.postal_code.short_name ?? '',
            })
        }
    }

    componentDidMount() {
        if (typeof this.props.user.FirstName !== 'undefined' && typeof this.props.user.Surname !== 'undefined') {
            this.setState({
                company: this.props.user.FirstName + ' ' + this.props.user.Surname
            })
        }
        this.getAddresses(this.state.addressID, true);
        if (typeof this.props.coords !== 'undefined') {
            this.updateGeoPosition();
        }
        // this.setState({
        //     addressID: this.props.addressID
        // })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.company) {
            if (typeof this.props.user.FirstName !== 'undefined' && typeof this.props.user.Surname !== 'undefined') {
                this.setState({
                    company: this.props.user.FirstName + ' ' + this.props.user.Surname
                })
            }
        }
        if (prevProps.addressID !== this.props.addressID) {
            this.setState({
                addressID: this.props.addressID
            }, () => this.getAddresses(this.props.addressID))

        }
        if (prevState.addressID !== this.state.addressID) {
            if (typeof this.props.setAddress === 'function') {
                this.props.setAddress(this.state.addressID);
            }
            if (!this.state.addressID) {
                this.setState(
                    {
                        company: '',
                        uid: '',
                        street: '',
                        street2: '',
                        number: '',
                        city: '',
                        country: '',
                        zip: ''
                    }
                )
            } else {
                let displayAddress = this.state.addresses.find((item) => item.ID === this.state.addressID)
                if (displayAddress) {
                    this.setState(
                        {
                            company: displayAddress.Company,
                            uid: displayAddress.UID,
                            street: displayAddress.Street,
                            street2: displayAddress.Street2 ?? '',
                            number: displayAddress.Number,
                            city: displayAddress.City,
                            country: displayAddress.Country,
                            zip: displayAddress.PostalCode,
                        }
                    )
                }
            }

            if (prevState.disabled !== this.state.disabled) {
                if (typeof this.props.changeEditMode === 'function') {
                    this.props.changeEditMode(!this.state.disabled)
                }
            }

        }

        if (typeof prevProps.coords.geocodeAddress !== 'undefined' && JSON.stringify(prevProps.coords.geocodeAddress) !== JSON.stringify(this.props.coords.geocodeAddress)) {
            this.updateGeoPosition();
        }
    }

    render() {
        return (
            <>
                {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-auto d-block align-self-center'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    <>
                        <h4 className="mb-1">{this.props.addressType === 'Billing' ? 'Rechnungsadresse' : 'Lieferadresse'}</h4>
                        <Row className='mt-2'>
                            <Col xs={9}>
                                <Select
                                    className='address-select'
                                    id={'inputAddressChange' + this.props.addressType}
                                    options={this.state.addressOptions}
                                    value={this.state.addressOptions.find(option => option.value === this.state.addressID)}
                                    onChange={this.handleAddressChange}
                                    disabled={this.state.loading}
                                />
                            </Col>
                            <Col xs={3}>
                                {this.state.disabled ?
                                    <div className='edit-button' onClick={this.handleDisableChanged}>
                                        <Icofont icon='ui-edit'/>
                                    </div>
                                    :
                                    null
                                }
                            </Col>
                        </Row>

                        <Form>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputCompany"
                                    placeholder="Firmenname"
                                    onChange={this.handleCompanyChange}
                                    disabled={this.state.loading || this.state.companyDisabled}
                                    value={this.state.company}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputCompany">Name / Firmenname</Form.Label>
                                <p className='text-danger m-0'>{this.state.companyError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputUID"
                                    placeholder="UID"
                                    onChange={this.handleUIDChange}
                                    disabled={this.state.loading}
                                    value={this.state.uid ?? ''}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputUID">UID</Form.Label>
                                <p className='text-danger m-0'
                                   dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.uidError)}}/>
                                <p className='text-danger m-0'>&nbsp;</p>
                            </div>
                            <Row>
                                <Col sm={9}>
                                    <div className="form-label-group m-0">
                                        <Form.Control
                                            type="text"
                                            id={'inputStreet' + this.props.addressType}
                                            placeholder="Straße"
                                            onChange={this.handleStreetChange}
                                            disabled={!!(this.state.loading || this.state.disabled)}
                                            value={this.state.street}
                                            className='input-foreground'
                                        />
                                        <Form.Label htmlFor={'inputStreet' + this.props.addressType}>Straße</Form.Label>
                                        <p className='text-danger m-0'>{this.state.streetError}&nbsp;</p>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <div className="form-label-group m-0">
                                        <Form.Control
                                            type="text"
                                            id={'inputNumber' + this.props.addressType}
                                            placeholder="Hausnummer"
                                            onChange={this.handleNumberChange}
                                            disabled={!!(this.state.loading || this.state.disabled)}
                                            value={this.state.number}
                                            className='input-foreground'
                                        />
                                        <Form.Label
                                            htmlFor={'inputNumber' + this.props.addressType}>Hausnummer</Form.Label>
                                        <p className='text-danger m-0'>{this.state.numberError}&nbsp;</p>
                                    </div>
                                </Col>
                            </Row>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id={'inputStreet2' + this.props.addressType}
                                    placeholder="Adresszeile 2"
                                    onChange={this.handleStreet2Change}
                                    disabled={!!(this.state.loading || this.state.disabled)}
                                    value={this.state.street2}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor={'inputStreet2' + this.props.addressType}>Adresszeile 2</Form.Label>
                                <p className='text-danger m-0'>{this.state.street2Error}&nbsp;</p>
                            </div>
                            <Row>
                                <Col sm={6}>
                                    <div className="form-label-group m-0">
                                        <Form.Control
                                            type="text"
                                            id={'inputZip' + this.props.addressType}
                                            placeholder="Postleitzahl"
                                            onChange={this.handleZipChange}
                                            disabled={!!(this.state.loading || this.state.disabled)}
                                            value={this.state.zip}
                                            className='input-foreground'
                                        />
                                        <Form.Label
                                            htmlFor={'inputZip' + this.props.addressType}>Postleitzahl</Form.Label>
                                        <p className='text-danger m-0'>{this.state.zipError}&nbsp;</p>
                                    </div>
                                </Col>
                                <Col sm={6}>
                                    <div className="form-label-group m-0">
                                        <Form.Control
                                            type="text"
                                            id={'inputCity' + this.props.addressType}
                                            placeholder="Ort"
                                            onChange={this.handleCityChange}
                                            disabled={!!(this.state.loading || this.state.disabled)}
                                            value={this.state.city}
                                            className='input-foreground'
                                        />
                                        <Form.Label htmlFor={'inputCity' + this.props.addressType}>Ort</Form.Label>
                                        <p className='text-danger m-0'>{this.state.cityError}&nbsp;</p>
                                    </div>
                                </Col>
                            </Row>

                            <div className="m-0">
                                <Form.Label htmlFor="inputCountry">Land</Form.Label>
                                <Select
                                    id={'inputCountry' + this.props.addressType}
                                    placeholder="Bitte auswählen"
                                    options={this.state.options}
                                    value={this.state.options.filter(option => option.value === this.state.country)}
                                    onChange={this.handleCountryChange}
                                    disabled={!!(this.state.loading || this.state.disabled)}
                                />
                                <p className='text-danger m-0'>{this.state.countryError}&nbsp;</p>
                            </div>
                            {this.state.error ?
                                <Alert variant="danger">{this.state.error}</Alert>
                                : null
                            }
                            {this.state.success ?
                                <Alert variant="success">{this.state.success}</Alert>
                                : null
                            }
                            <div className='mb-4'></div>
                            {this.state.disabled ?
                                null
                                :
                                <div onClick={this.handleDataSubmit}
                                     className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">
                                    {this.state.addressID === 0 ?
                                        'Speichern'
                                        :
                                        'Ändern'
                                    }
                                </div>
                            }
                        </Form>
                    </>
                }
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    coords: state.coords,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
});


const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CheckoutAddressFrom));
