import React, {Component} from "react";
import CartDropdownHeader from "../CartDropdown/CartDropdownHeader";
import CartDropdownItem from "../CartDropdown/CartDropdownItem";
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import BasketAction from "../../stores/basket/actions";
import SlidingPanel from 'react-sliding-side-panel';
import Icofont from "../Icofont/Icofont";
import {BasketService} from "../../services/BasketService";
import {toastr} from "react-redux-toastr";

class BasketDropdown extends Component {


    constructor(props) {
        super(props);

        this.toggleCard = this.toggleCard.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.state = {
            loading: false,
            vendorsIds: [],
            vendors: [],
            basketItems: [],
            width: 0,
            windowWidth: 30,
            height: 0,
            total: 0
        }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        let newWidth = this.state.windowWidth;
        if (typeof this.state.width !== 'undefined') {
            if (this.state.width > 1399) {
                newWidth = 30;
            }
            if (this.state.width > 991 && this.state.width < 1399) {
                newWidth = 60;
            }
            if (this.state.width < 769) {
                newWidth = 90;
            }
            if (this.state.windowWidth !== newWidth) {
                this.setState({
                    windowWidth: newWidth
                })
            }
        }

        if (this.props.openCard !== prevProps.openCard) {
            if (!prevProps.openCard) {
                this.props.fetchBasket();
            }
        }

    }


    toggleCard() {
        if (typeof this.props.toggleCard === 'function') {
            this.props.toggleCard();
        }
    }

    changeQuantity = (product, quantity) => {
        if (!this.props.basketID) {
            let errorMsg = 'Keine Warenkorb übergeben';
            toastr.error('Hoppla', errorMsg);
            return null;
        }
        if (quantity) {
            BasketService.modifyItems(
                [
                    {
                        ProductID: product.ID,
                        BasketID: this.props.basketID,
                        Quantity: quantity
                    }
                ],
                'modify',
                this.props.basketID,
                this.props.authKey
            ).then((result) => {
                toastr.success('Geändert', product.Title+ ' wurde erfogreich geändert');
                this.props.fetchBasket()
            }).catch((error) => {
                console.log(error)
            })
        } else {
            BasketService.modifyItems(
                [
                    {
                        ProductID: product.ID,
                        BasketID: this.props.basketID,
                        Quantity: quantity
                    }
                ],
                'delete',
                this.props.basketID,
                this.props.authKey
            ).then((result) => {
                toastr.success('Gelöscht', product.Title+ ' wurde erfogreich gelöscht');
                this.props.fetchBasket()
            }).catch((error) => {
                console.log(error)
            })
        }

    }

    render() {
        return (
            <SlidingPanel
                type={'right'}
                isOpen={this.props.openCard}
                size={this.state.windowWidth}
                backdropClicked={this.toggleCard}
            >
                <div className='card-overlay'>
                    <div className='close-card p-3'>
                        <Icofont icon='close' onClick={this.toggleCard}/>
                    </div>
                    <div className='basket-content'>
                        {typeof this.props.list === 'undefined' || this.props.list.length <= 0 ?
                            <p className='p-5 text-center'>Du hast keine Produkte im Warenkorb</p>
                            :
                            this.state.loading || this.props.basketLoading ?
                                <Spinner animation="border" role="status"
                                         className='mx-auto d-block align-self-center'>
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                                :
                                <>
                                    {this.props.list.map((vendor, index) => {
                                        return (
                                            <div key={vendor.ID}>
                                                <CartDropdownHeader
                                                    className="dropdown-cart-top-header py-2 px-4 border-top"
                                                    title={vendor.Title}
                                                    hidden={vendor.Hidden ?? 0}
                                                    image={vendor.PreviewImage}
                                                    imageClass="img-fluid mr-3"
                                                    imageAlt={vendor.Title}
                                                    linkUrl={'/Händler/' + vendor.ID}
                                                    linkText="Zum Händler"
                                                />
                                                {vendor.Items.map((item, index) => {
                                                    return (
                                                        <div key={index}
                                                             className="dropdown-cart-top-body border-top p-4">
                                                            <CartDropdownItem
                                                                product={item.Product}
                                                                quantity={item.Quantity}
                                                                iconClass='text-success'
                                                                title={item.Product.Title}
                                                                onChangeQuantity={(product, quantity) => this.changeQuantity(product, quantity)}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                                {vendor.Shipping.length ?
                                                    <>
                                                        <div
                                                            key={index}
                                                            className="dropdown-cart-top-body border-top py-1 pt-3 px-4">
                                                            <Row>
                                                                <Col xs={8}>
                                                                    <b>{vendor.Title} - Versandkosten:</b>
                                                                </Col>
                                                            </Row>

                                                        </div>
                                                        {vendor.Shipping.map((item, index) => {
                                                            return (
                                                                <div key={index}
                                                                     className="dropdown-cart-top-body border-top p-4">
                                                                    <div key={index}
                                                                         className="dropdown-cart-top-body px-4 py-1">
                                                                        <Row>
                                                                            <Col xs={8}>
                                                                                {item.Title}
                                                                            </Col>
                                                                            <Col xs={4} className='text-right'>
                                                                                {typeof item.Rate !== 'undefined' ?
                                                                                    <>
                                                                                        € {item.Rate.toFixed(2)}
                                                                                        <div
                                                                                            className='ml-2 mr-2 d-inline-block'
                                                                                            style={{width: 13}}/>
                                                                                    </>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </Col>
                                                                        </Row>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                        );
                                    })}
                                </>
                        }
                    </div>
                    {typeof this.props.list !== 'undefined' && this.props.list.length > 0 ?
                        <div className="dropdown-cart-top-footer border-top p-2">
                            <div className="mb-3">
                                <h4 className="mb-0">Gesamt: € {typeof this.props.total !== 'undefined' ? this.props.total.toFixed(2) : '--'}</h4>
                                <small className="text-black">inkl. MwSt</small>
                            </div>
                            <Button
                                as={Link}
                                to="/checkout"
                                onClick={this.toggleCard}
                                className='btn btn-primary btn-block py-3 text-white text-center'
                            >
                                Jetzt
                                bestellen
                            </Button>
                        </div>
                        :
                        null
                    }
                </div>
            </SlidingPanel>
        )

    }

}

const mapStateToProps = (state) => ({
    list: state.basket.list,
    authKey: state.user.authKey,
    total: state.basket.total,
    basketLoading: state.basket.basketLoading,
    basketID: state.basket.basketID,
});

const mapDispatchToProps = (dispatch) => ({
    fetchBasket: () => dispatch(BasketAction.fetchBasket())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(BasketDropdown));
