import React from 'react';
import {
    Alert,
    Badge,
    Button,
    Col,
    Container,
    Form,
    Image,
    Nav, Overlay,
    OverlayTrigger,
    Popover,
    Row,
    Spinner,
    Tab, Tooltip
} from 'react-bootstrap';
import Icofont from "../../components/Icofont/Icofont";
import ProductImage from "../../assets/img/headerbild_default.jpg";
import './Product.style.scss';
import OwlCarousel from 'react-owl-carousel3';
import Select2 from "react-select2-wrapper";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import LazyImage from "../../components/LazyImage/LazyImage";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Map from "../../components/Map/Map";
import GoogleMarker from "../../components/GoogleMarker/GoogleMarker";
import BasketAction from "../../stores/basket/actions";
import ProductRequestForm from "./ProductRequestForm";
import AddToBookmarkCollectionModal from "../../components/Modals/AddToBookmarkCollectionModal";
import ProductCardItem from "../../components/CardItem/ProductCardItem";
import DefaultImage from "../../assets/img/default_img.png";
import {HTMLService} from "../../services/HTMLService";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";
import LocationField from "../../components/LocationField/LocationField";
import InfoBadge from "../../components/InfoBadge/InfoBadge";
import {BasketService} from "../../services/BasketService";


class Product extends React.Component {

    constructor(props) {
        super(props);

        this.handleQuantityChange = this.handleQuantityChange.bind(this)
        this.getNearProducts = this.getNearProducts.bind(this)
        this.handleShippingRateChange = this.handleShippingRateChange.bind(this)
        this.handleBulkShippingRateChange = this.handleBulkShippingRateChange.bind(this)
        this.addToBasket = this.addToBasket.bind(this)
        this.target = React.createRef();

        this.state = {
            product: {},
            vendor: {},
            address: {},
            localAddress: '',
            geolocationAvailable: false,
            shippingRates: [],
            shippingloading: true,
            shippingRate: 0,
            shippingRateID: 0,
            showBulkShippingRate: false,
            bulkShippingRates: [],
            packageBulkShippingRates: [],
            allowedBulkShippingRates: [],
            bulkShippingRateID: 0,
            useBulkShipping: false,
            images: [],
            logo: '',
            total: 0.00,
            center: {
                lat: 48.247590,
                lng: 14.303290
            },
            quantity: 1,
            showBookmarkModal: false,
            headerImg: ProductImage,
            buttonDisabled: true,
            products: [],
            loadingProducts: true,
            editLocation: false,
            show: false
        }
    }

    hideBookmarkModal = () => this.setState({showBookmarkModal: false});

    handleQuantityChange(e) {
        let val = e.target.value;
        this.setState({
            quantity: val
        }, () => {
            this.setTotal()
        })
    }

    updateGeoPosition(coords) {
        if (typeof this.props.coords.geocodeAddress.formatted_address !== 'undefined') {
            this.setState({
                    localAddress: this.props.coords.geocodeAddress.formatted_address ?? '',
                    editLocation: false
                },
                () => this.setState({
                    geolocationAvailable: false
                })
            );
        }

    }

    componentDidMount() {
        if (typeof this.props.coords === 'undefined' || this.props.coords.latitude === 0) {
            this.setState({
                editLocation: true
            })
        }
        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            ApiService.client.get('/Product/' + id)
                .then((result) => {
                    this.setState({
                        product: result.data,
                        packageBulkShippingRates: result.data.PackageShipping ?? [],
                        total: result.data.Price
                    })
                    this.getVendor(result.data.VendorID)
                    this.getHeaderImage(result.data.CategoryID, result.data.VendorID)
                    this.getShippingRates(result.data.ID)
                    this.getImages(result.data.ID)
                }).catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({
                        loading: false
                    }
                );

                toastr.error('Fehler beim laden des Produktes', msg)
                this.props.history.push('/produkt/not-found');
            })
        }
        if (typeof this.props.coords) {
            this.updateGeoPosition();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof prevProps.coords.geocodeAddress !== 'undefined' && JSON.stringify(prevProps.coords.geocodeAddress) !== JSON.stringify(this.props.coords.geocodeAddress)) {
            this.checkBulkShipping()
            this.updateGeoPosition();
        }
    }

    getHeaderImage(catID, vendorID) {
        if (vendorID) {
            ApiService.client.get('/Vendor/' + vendorID + '/many/Images/')
                .then((response) => {
                    if (typeof response.data[0] !== "undefined") {
                        this.setState({
                            headerImg: response.data[0].AbsoluteURL
                        });
                    } else {
                        this.getDefaultHeader(catID)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.getDefaultHeader(catID)
                })
        } else if (catID) {
            this.getDefaultHeader(catID)
        }

    }

    getDefaultHeader(catID) {
        ApiService.client.get('/Category/' + catID + '/headerImage/')
            .then((response) => {
                this.setState({
                    headerImg: response.data.AbsoluteURL
                });
            })
            .catch((error) => {
            })
    }

    getShippingRates(productID) {
        ApiService.client.get('/Product/' + productID + '/many/ShippingRates/')
            .then((response) => {
                this.setState({
                    shippingRates: response.data,
                    shippingloading: false
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    getImages(productID) {
        ApiService.client.get('/Product/' + productID + '/allImages/')
            .then((response) => {
                this.setState({
                    images: response.data
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })

    }

    checkBulkShipping() {
        let allowedBulkShippingRates = [];
        this.setState({
            shippingloading: true
        })
        if (typeof this.props.coords.geocodeAddress !== "undefined" && this.state.bulkShippingRates.length) {
            if (typeof this.props.coords.geocodeAddress.address_component != 'undefined' && this.props.coords.geocodeAddress.address_component.length) {
                let postalcode = this.props.coords.geocodeAddress.address_component.find((item) => item.type === 'postal_code');
                this.state.bulkShippingRates.map((bulkRate, index) => {
                    if (
                        postalcode &&
                        bulkRate.DeliveryZips &&
                        postalcode.short_name &&
                        bulkRate.DeliveryZips.includes(postalcode.short_name)
                    ) {
                        allowedBulkShippingRates.push(bulkRate);
                    }
                })

            }
        }
        if (this.props.basketID) {
            allowedBulkShippingRates.forEach((bulkRate, index) => {
                BasketService.checkBulkShipping(this.props.basketID, bulkRate.ID)
                    .then((respnose) => {
                        bulkRate.isInBasket = respnose.data.InBasket
                    })
                    .catch((error) => console.log(error))
            })

        }
        this.setState({
            allowedBulkShippingRates: allowedBulkShippingRates ?? [],
            shippingloading: false
        })

    }

    getVendor(vendorID) {
        if (vendorID) {
            ApiService.client.get('/Vendor/' + vendorID)
                .then((result) => {
                    this.setState({
                        vendor: result.data,
                        bulkShippingRates: result.data.BulkShippingProduct ?? []
                    }, () => this.checkBulkShipping())
                    this.getAddress(result.data.AddressID)
                    this.loadLogo(result.data.LogoID)
                }).catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({
                        loading: false
                    }
                );
                toastr.error('Fehler beim laden des Händlers', msg)
            })
        }
    }

    loadLogo(logoID) {
        if (logoID) {
            ApiService.client.get('/Image/' + logoID + '/FitMax/200/200/')
                .then((result) => {
                    this.setState({
                        logo: result.data.AbsoluteURL
                    })
                }).catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({loading: false});
                toastr.error('Fehler laden des Bildes', msg)
            })
        } else {
            this.setState({
                logo: DefaultImage
            })
        }

    }

    getAddress(addressID) {
        if (addressID) {
            ApiService.client.get('/Address/' + addressID)
                .then((result) => {
                    this.setState({
                        address: result.data,
                        center: {
                            lat: result.data.Latitude,
                            lng: result.data.Longitude
                        },
                    })
                    this.getNearProducts(result.data)
                }).catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({
                        loading: false
                    }
                );
                toastr.error('Fehler beim laden der Adresse', msg)
            })
        }
    }

    getNearProducts(address) {
        let initFilter = '?filter[VendorID:not]=0&filter=[Hidden]=0&limit=2&filter[ID:not]=' + this.props.match.params.id;
        initFilter += '&sort=RAND()';
        // if (address.Latitude && address.Longitude) {
        //     initFilter += '&Distance ASC&lat=' + address.Latitude + '&lng=' + address.Longitude
        // }

        ApiService.client.get('/Product/' + initFilter)
            .then((result) => {
                this.setState({
                    products: result.data,
                    loadingProducts: false
                })
                if (typeof this.props.setCountFunction === 'function') {
                    this.props.setCountFunction(result.data.length);
                }

            })
            .catch((error) => {
                this.setState({loadingProducts: false});
            })
    }

    handleShippingRateChange(shippingRate, ID) {
        this.setState(
            {
                shippingRate: shippingRate,
                shippingRateID: ID,
                bulkShippingRateID: 0,
                buttonDisabled: false,
                useBulkShipping: false,
            }
            , () => {
                this.setTotal();
            })
    }

    handleBulkShippingRateChange(id, price) {
        this.setState(
            {
                shippingRate: price,
                shippingRateID: 0,
                buttonDisabled: false,
                bulkShippingRateID: id,
                useBulkShipping: true,
            }
            , () => {
                this.setTotal();
            })
    }

    setTotal() {
        this.setState(
            {total: (this.state.quantity * this.state.product.Price) + this.state.shippingRate}
        )
    }

    handleAddToBasket = (basketID) => {
        let payload = [];
        let bulkRate = null;
        if (this.state.useBulkShipping) {
            bulkRate = this.state.bulkShippingRates.find((bulkRate) => bulkRate.ID === this.state.bulkShippingRateID)
        }
        if (this.state.useBulkShipping && this.state.packageBulkShippingRates.length && !bulkRate) {
            bulkRate = this.state.packageBulkShippingRates.find((bulkRate) => bulkRate.ID === this.state.bulkShippingRateID)
        }
        if (typeof bulkRate === 'object' && bulkRate) {
            payload.push({
                ProductID: this.state.product.ID,
                BasketID: basketID,
                Quantity: this.state.quantity,
                ShippingRate: bulkRate.BulkShippingRateID ?? 0
            })
            payload.push({
                ProductID: bulkRate.ID,
                BasketID: basketID,
                Quantity: 1,
                ShippingRate: bulkRate.BulkShippingRateID ?? 0
            })
        } else {
            payload.push({
                ProductID: this.state.product.ID,
                BasketID: basketID,
                Quantity: this.state.quantity,
                ShippingRate: this.state.shippingRateID
            })
        }

        BasketService.modifyItems(
            payload,
            'add',
            basketID,
            this.props.authKey
        ).then((result) => {
            toastr.success('Hinzugefügt', this.state.product.Title + ' wurde zum Warenkorb hinzugefügt')
            this.props.fetchBasket()
        }).catch((error) => {
            console.log(error)
        })
    }

    addToBasket() {
        if (this.props.basketID) {
            this.handleAddToBasket(this.props.basketID)
        } else {
            BasketService.fetchBasket(this.props.authKey).then((result) => {
                if (result?.data?.ID) {
                    this.props.setAndFetchBasket(result.data.ID)
                    this.handleAddToBasket(result.data.ID)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const deg2rad = (deg) => {
            return deg * (Math.PI / 180)
        }
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d.toFixed(1);
    }

    getShippingRateInfo(id) {
        if (this.state.shippingRates.length) {
            this.state.shippingRates.map((item, key) => {
                if (item.id === id) {
                    return item.Title;
                }
            })
        }

        return "";
    }


    render() {
        return (
            <>
                {typeof this.state.product.Title !== 'undefined' ?
                    <CustomMetaTags
                        title={'Regionlinemarkt - ' + (this.state.product.Title ?? 'Produktdetails')}
                        desc={this.state.product.Description}
                        img={this.state.logo}
                    />
                    :
                    <CustomMetaTags
                        title={'Regionlinemarkt - ' + (this.state.product.Title ?? 'Produktdetails')}
                    />
                }
                <AddToBookmarkCollectionModal
                    show={this.state.showBookmarkModal}
                    productID={this.state.product.ID}
                    productTitle={this.state.product.Title}
                    onHide={this.hideBookmarkModal}
                />
                <section className="restaurant-detailed-banner">
                    <div className="text-center">
                        <Image fluid className="cover" src={this.state.headerImg}/>
                    </div>
                    <div className="restaurant-detailed-header">
                        <Container>
                            <Row className="d-flex align-items-end">
                                <Col md={8}>
                                    <div className="restaurant-detailed-header-left">
                                        <LazyImage fluid className="mr-3 float-left" alt="osahan"
                                                   src={this.state.logo}/>
                                        <h2 className="text-white">{this.state.product.Title ?? ''}</h2>
                                        <p className="text-white mb-0">
                                            <Icofont icon="food-cart"/> {this.state.vendor.Title ?? ''}
                                        </p><p className="text-white mb-1">
                                        <Icofont icon="location-pin"/> {this.state.address.Title ?? ''}
                                    </p>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="restaurant-detailed-header-right text-right">
                                        {this.props.coords.latitude && this.state.address.Latitude ?
                                            <Button
                                                variant='primary'
                                                type="button"
                                                className='mr-2'
                                            >
                                                {this.props.coords.latitude ?
                                                    <>
                                                        <Icofont icon='map-pins'/> &nbsp;
                                                        {
                                                            this.getDistanceFromLatLonInKm(
                                                                this.state.address.Latitude,
                                                                this.state.address.Longitude,
                                                                this.props.coords.latitude,
                                                                this.props.coords.longitude,
                                                            )

                                                        } km
                                                    </>
                                                    :
                                                    null
                                                }
                                            </Button>
                                            :
                                            null
                                        }
                                        <Button variant='primary'
                                                type="button">€ {typeof this.state.product.Price !== 'undefined' ? this.state.product.Price.toFixed(2) : '--'}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
                <Tab.Container defaultActiveKey="description">
                    <section className="offer-dedicated-nav bg-white border-top-0 shadow-sm">
                        <Container>
                            <Row>
                                <Col md={12}>
                                <span className="restaurant-detailed-action-btn float-right">
                                    <Button variant='light' size='sm' className="border-light-btn mr-1" type="button"
                                            onClick={() => this.setState({showBookmarkModal: true})}>
                                        <Icofont icon="heart" className='text-danger'/> Zur Merkliste hinzufügen
                                    </Button>
                                </span>
                                    <Nav id="pills-tab">
                                        <Nav.Item>
                                            <Nav.Link eventKey="description">Beschreibung</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="vendor">Händlerinfos</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="mt-2">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <Button variant="link" size="sm" onClick={() => {
                                        window.history.back();
                                        window.scrollTo({top: 0, behavior: 'smooth'});
                                    }}>
                                        <Icofont icon="rounded-double-left"/> Zurück zur Übersicht
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="offer-dedicated-body pt-2 pb-2 mb-4">
                        <Container>
                            <Row>
                                <Col lg={8} md={7}>
                                    <div className="offer-dedicated-body-left">
                                        <Tab.Content className='h-100'>
                                            <Tab.Pane eventKey="description">

                                                {this.state.images.length ?
                                                    <div id="images" className="bg-white rounded shadow-sm p-4 mb-3">
                                                        <div className="outer">
                                                            <OwlCarousel
                                                                ref={ref => this.big = ref}
                                                                id="big"
                                                                items={1}
                                                                nav={true}
                                                                dots={false}
                                                                loop={false}
                                                                responsiveRefreshRate={200}
                                                                navText={[
                                                                    '<i class="fa fa-arrow-left" aria-hidden="true"/>',
                                                                    '<i class="fa fa-arrow-right" aria-hidden="true"/>'
                                                                ]}
                                                            >
                                                                {this.state.images.map((item, index) => {
                                                                    return (
                                                                        <div className="item" key={index}>
                                                                            <LazyImage src={item.AbsoluteURL}/>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </OwlCarousel>
                                                            <OwlCarousel
                                                                ref={ref => this.thumbs = ref}
                                                                id="thumbs"
                                                                items={5}
                                                                navText={[
                                                                    '<i class="fa fa-arrow-left" aria-hidden="true"/>',
                                                                    '<i class="fa fa-arrow-right" aria-hidden="true"/>'
                                                                ]}
                                                                smartSpeed={200}
                                                                autoplaySpeed={500}
                                                                responsiveRefreshRate={100}
                                                            >
                                                                {this.state.images.map((item, index) => {
                                                                    return (
                                                                        <div className="item" key={index}
                                                                             onClick={() => this.big.to(index, 300)}>
                                                                            <Image src={item.AbsoluteURL}/>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </OwlCarousel>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                {this.state.product.Description ?
                                                    <div id="description"
                                                         className="bg-white rounded shadow-sm p-4 mb-5">
                                                        <h5 className="mb-4">Produktinfos</h5>
                                                        <div
                                                            dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.product.Description)}}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                                }

                                            </Tab.Pane>
                                            <Tab.Pane eventKey="vendor">
                                                <div id="restaurant-info"
                                                     className="bg-white rounded shadow-sm p-4 mb-4">
                                                    <Row>
                                                        <Col md={7}>
                                                            <h5 className="mb-4">{this.state.vendor.Title}</h5>
                                                            {this.state.vendor.Tel ?
                                                                <p className="mb-2 text-black">
                                                                    <Icofont
                                                                        icon="phone-circle text-primary mr-2"/> {this.state.vendor.Tel}
                                                                </p>
                                                                :
                                                                null
                                                            }
                                                            {this.state.vendor.Email ?
                                                                <p className="mb-2 text-black"><Icofont
                                                                    icon="email text-primary mr-2"/> {this.state.vendor.Email}
                                                                </p>
                                                                :
                                                                null
                                                            }

                                                            <hr className="clearfix"/>
                                                            <p className="text-black mb-0">
                                                                {this.state.vendor.ShortDescription ?? ''}
                                                            </p>
                                                        </Col>
                                                        <Col md={5}>
                                                            <div className="gmap_canvas"
                                                                 style={{width: '100%', height: '350px'}}>
                                                                {this.state.vendor.Latitude ?
                                                                    <Map
                                                                        center={{
                                                                            lat: this.state.vendor.Latitude,
                                                                            lng: this.state.vendor.Longitude
                                                                        }}
                                                                        zoom={10}
                                                                        heightStyle='100%'
                                                                    >
                                                                        <GoogleMarker
                                                                            key={this.state.vendor.ID}
                                                                            lat={this.state.vendor.Latitude}
                                                                            lng={this.state.vendor.Longitude}
                                                                            text={this.state.vendor.Title}
                                                                            title={this.state.vendor.Title}
                                                                            image={this.state.vendor.PreviewImage}
                                                                            desc={this.state.vendor.ShortDescription}
                                                                            linkTo={'/händler/' + this.state.vendor.ID}
                                                                            linkTitle={'Händlerprofil anzeigen'}
                                                                        />
                                                                    </Map>
                                                                    :
                                                                    null
                                                                }

                                                            </div>
                                                        </Col>
                                                        <Col xs={12}>
                                                            <hr className="clearfix"/>
                                                            <Button as={Link}
                                                                    to={'/händler/' + this.state.vendor.ID}
                                                                    block
                                                                    variant="outline-primary">Zur
                                                                Händlerseite</Button>
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>

                                </Col>
                                <Col lg={4} md={5}>
                                    <div
                                        className="bg-white rounded shadow-sm text-white mb-4 p-4 clearfix restaurant-detailed-earn-pts card-icon-overlap">
                                        {this.state.product.RequestOnly ?
                                            <ProductRequestForm product={this.state.product}/>
                                            :
                                            <>
                                                {this.state.product.Promoted ? (
                                                        <div className={'text-right'}>
                                                            <Badge variant='primary' className='bigger-badge'>
                                                                Top Angebot
                                                            </Badge>
                                                        </div>
                                                    )
                                                    : ""
                                                }
                                                <div className="mb-3">
                                                    <h4 className="mb-0">€ {typeof this.state.product.Price !== 'undefined' ? this.state.product.Price.toFixed(2) : '--'}</h4>
                                                    {this.state.product.RefPrice && this.state.product.Promoted ?
                                                        <del className="text-muted"><h5>statt
                                                            €{this.state.product.RefPrice.toFixed(2)}</h5></del>
                                                        : null
                                                    }
                                                    <small className="text-black">inkl.
                                                        MwSt {this.state.product.TaxRateFormatted ? '(' + this.state.product.TaxRateFormatted + ')' : ''}</small>
                                                </div>
                                                <span className="text-black">Menge:</span>
                                                <Select2
                                                    data={Array.from(Array(20), (_, i) => i + 1)}
                                                    value={this.state.quantity}
                                                    options={{
                                                        minimumResultsForSearch: Infinity
                                                    }}
                                                    onChange={this.handleQuantityChange}
                                                />
                                                <hr/>


                                                <div className="product-address mb-3">
                                                    <div className="d-flex align-items-center w-100">
                                                        {this.state.editLocation ?
                                                            <div style={{
                                                                display: "inline-block",
                                                                width: '100%'
                                                            }
                                                            }>
                                                                <div
                                                                    className="form-row justify-content-start custom-location-field">
                                                                    <LocationField
                                                                        placeholder='Bitte gib deine Adresse ein'
                                                                        className='col-12 mb-0'
                                                                    />
                                                                </div>
                                                            </div>
                                                            :
                                                            <div
                                                                className='inlineLocationField d-flex justify-content-between w-100'
                                                                style={{border: '1px solid #eee'}}>
                                                                <div style={{color: '#000'}}>
                                                                    {this.state.localAddress.length ? <span
                                                                            className='text-location-small'>{this.state.localAddress}</span> :
                                                                        <span
                                                                            className="text-muted text-location-small">Bitte gib deine Adresse ein</span>}
                                                                </div>
                                                                <Icofont
                                                                    icon='ui-edit'
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editLocation: !this.state.editLocation
                                                                        })
                                                                    }
                                                                    }
                                                                    className='text-muted ml-2 cursor-pointer'
                                                                />
                                                            </div>
                                                        }
                                                        <InfoBadge type={'AddressInfoText'}/>
                                                    </div>
                                                </div>
                                                <p className="text-black">
                                                    Lieferung<br/>
                                                </p>
                                                {(this.state.shippingRates.length == 0 && this.state.packageBulkShippingRates.length == 0 && this.state.allowedBulkShippingRates.length == 0) && !this.state.shippingloading && this.props.coords.latitude ?
                                                    <Alert variant="danger">
                                                        Leider bietet der Händler keine Versandoption für deine Adresse
                                                        an.
                                                        {this.state.vendor.Email || this.state.vendor.Tel ?
                                                            <>
                                                                <br/><br/>
                                                            </>
                                                            :
                                                            null
                                                        }

                                                        {this.state.vendor.Email ?
                                                            <>E-Mail: {this.state.vendor.Email}<br/></>
                                                            : null
                                                        }
                                                        {this.state.vendor.Tel ?
                                                            <>Tel.: {this.state.vendor.Tel}<br/></>
                                                            : null
                                                        }
                                                    </Alert>
                                                    : null
                                                }
                                                {!this.props.coords.latitude ?
                                                    <p className="text-danger">Bitte gib deine Adresse ein, um
                                                        Lieferoptionen anzuzeigen.</p>
                                                    :
                                                    this.state.shippingRates.map((item, index) => {
                                                        return (
                                                            <>
                                                                <Form.Check
                                                                    key={item.ID}
                                                                    className="text-black"
                                                                    type="radio"
                                                                    label={item.Title + ' (€ ' + item.Rate.toFixed(2) + ')'}
                                                                    id={item.ID}
                                                                    onChange={() => this.handleShippingRateChange(item.Rate, item.ID)}
                                                                    name="deliverOpt"
                                                                />

                                                            </>
                                                        )
                                                    })
                                                }
                                                {this.state.allowedBulkShippingRates.length ?
                                                    this.state.allowedBulkShippingRates.map((bulkRate, index) => {
                                                        let title = bulkRate.Title + ' (€ ' + bulkRate.Price.toFixed(2) + ')';
                                                        let rate = bulkRate.Price;
                                                        let shippingInformation = bulkRate.ShippingInformation;
                                                        if (bulkRate.isInBasket) {
                                                            title = bulkRate.Title + ' Bereits im Warenkorb – 0,00€';
                                                            rate = 0;
                                                        }

                                                        return (
                                                            <div className={"d-block text-black"}>
                                                                {shippingInformation !== undefined ?
                                                                    <OverlayTrigger
                                                                    key={bulkRate.ID}
                                                                    placement={'top'}
                                                                    overlay={
                                                                        <Tooltip id={'tooltip-' + bulkRate.ID}>
                                                                            {shippingInformation ?? 'Für diese Versandart wurde kein Informationstext hinterlegt.'}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Icofont icon={"info-circle"}/>
                                                                </OverlayTrigger> : null}
                                                                <Form.Check
                                                                    key={bulkRate.ID}
                                                                    className={"d-inline"}
                                                                    type="radio"
                                                                    label={title}
                                                                    id={bulkRate.ID}
                                                                    onChange={() => this.handleBulkShippingRateChange(bulkRate.ID, rate)}
                                                                    name="deliverOpt"
                                                                />


                                                            </div>

                                                        )
                                                    })
                                                    :
                                                    <>
                                                        {this.state.packageBulkShippingRates.length ?
                                                            this.state.packageBulkShippingRates.map((bulkRate, index) => {
                                                                let title = bulkRate.Title + ' (€ ' + bulkRate.Price.toFixed(2) + ')';
                                                                let rate = bulkRate.Price;
                                                                if (bulkRate.isInBasket) {
                                                                    title = bulkRate.Title + ' Bereits im Warenkorb – 0,00€';
                                                                    rate = 0;
                                                                }

                                                                return (
                                                                    <Form.Check
                                                                        key={bulkRate.ID}
                                                                        className="text-black"
                                                                        type="radio"
                                                                        label={title}
                                                                        id={bulkRate.ID}
                                                                        onChange={() => this.handleBulkShippingRateChange(bulkRate.ID, rate)}
                                                                        name="deliverOpt"
                                                                    />
                                                                )
                                                            })
                                                            :
                                                            null
                                                        }
                                                    </>
                                                }
                                                {/*{this.state.bulkShippingRate && this.state.bulkShippingRate.Price && this.state.showBulkShippingRate ?*/}
                                                {/*    <Form.Check*/}
                                                {/*        className="text-black"*/}
                                                {/*        type="radio"*/}
                                                {/*        label={this.state.bulkShippingRate.Title + ' (€ '+this.state.bulkShippingRate.Price.toFixed(2)+')' }*/}
                                                {/*        id={this.state.bulkShippingRate.ID}*/}
                                                {/*        onChange={() => this.handleBulkShippingRateChange(this.state.bulkShippingRate.ID)}*/}
                                                {/*        name="deliverOpt"*/}
                                                {/*    />*/}
                                                {/*    :*/}
                                                {/*    null}*/}
                                                <hr/>
                                                <div className="mb-3">
                                                    <h4 className="mb-0">Gesamt:
                                                        € {this.state.total.toFixed(2) ?? '--'}</h4>
                                                    <small className="text-black">inkl.
                                                        MwSt {this.state.product.TaxRateFormatted ? '(' + this.state.product.TaxRateFormatted + ')' : ''}</small>
                                                </div>
                                                <hr/>
                                                {this.state.buttonDisabled && this.props.coords.latitude ?
                                                    <p className='mb-2 text-danger'>
                                                        Bitte wähle eine Lieferung aus, um das Produkt in den Warenkorb
                                                        zu legen
                                                    </p>
                                                    :
                                                    null
                                                }
                                                <Button variant="primary" block onClick={this.addToBasket}
                                                        disabled={this.state.buttonDisabled || !this.props.coords.latitude}>
                                                    In den Warenkorb
                                                </Button>
                                            </>
                                        }
                                    </div>
                                    {this.state.productLoading ?
                                        <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                        :
                                        <>
                                            <h4 className='mb-2'>Auch interessant…</h4>
                                            {
                                                this.state.products.map((product, index) => {
                                                    return (
                                                        <ProductCardItem
                                                            key={product.ID}
                                                            noHeight100={true}
                                                            productID={product.ID}
                                                            title={product.Title}
                                                            subTitle=''
                                                            imageAlt={product.Title}
                                                            image={product.PreviewImage}
                                                            imageClass='img-fluid item-img'
                                                            linkUrl={'/produkt/' + product.ID + '/' + product.URLTitle}
                                                            price={'€' + product.Price.toFixed(2)}
                                                            vendorID={product.VendorID}
                                                            categoryID={product.CategoryID}
                                                            distance="10km NOCH BERECHENEN!"
                                                            latitude={product.Latitude}
                                                            longitude={product.Longitude}
                                                            showPromoted={product.Promoted}
                                                            showSale={product.Sale}
                                                            refPrice={product.RefPrice}
                                                            favIcoIconColor='text-primary'
                                                        />
                                                    )
                                                })
                                            }
                                        </>

                                    }
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Tab.Container>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
    authKey: state.user.authKey,
    basketItems: state.basket.basketItems,
    basketID: state.basket.basketID,
    memberID: state.user.memberID,
});

const mapDispatchToProps = (dispatch) => ({
    fetchBasket: () => dispatch(BasketAction.fetchBasket()),
    setAndFetchBasket: (basketID) => dispatch(BasketAction.setAndFetchBasket(basketID))
});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Product));

