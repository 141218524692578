import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import ProductCardItem from "../../components/CardItem/ProductCardItem";
import axios from 'axios';

let CancelToken = axios.CancelToken;
let cancel;
let cancel2;
export default class Grid extends Component {

    state = {
        products: [],
        loading: true,
        categoryIDs: []
    }
    static defaultProps = {
        categoryIDs: [],
        minPrice: 0,
        maxPrice: 0,
        title: '',
    }

    constructor(props) {
        super(props);

        this.loadProducts = this.loadProducts.bind(this)
    }

    componentDidMount() {
        this.loadProducts()
        console.log(this.props)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((JSON.stringify(prevProps.categoryIDs) !== JSON.stringify(this.props.categoryIDs)) ||
            (prevProps.minPrice !== this.props.minPrice) ||
            (prevProps.maxPrice !== this.props.maxPrice) ||
            (prevProps.title !== this.props.title) ||
            (prevProps.lat !== this.props.lat) ||
            (prevProps.lng !== this.props.lng) ||
            (prevProps.offset !== this.props.offset) ||
            (prevProps.sort !== this.props.sort)
        ) {
            if (cancel != undefined) {
                cancel('NEWREQUEST');
            }
            if (cancel2 != undefined) {
                cancel2('NEWREQUEST');
            }
            this.loadProducts()
        }

    }

    loadProducts() {
        let initFilter = '?filter[VendorID:not]=0&filter[Hidden]=0';
        if (this.props.sort === 'Distance ASC' && this.props.lat && this.props.lng) {
            initFilter += '&sort=Promoted DESC,Distance ASC,Title ASC&lat=' + this.props.lat + '&lng=' + this.props.lng
        } else if (this.props.sort === 'Price ASC') {
            initFilter += '&sort=Promoted DESC,Price ASC'
        } else {
            initFilter += '&sort=Promoted DESC'
        }
        let filter = '';
        this.props.categoryIDs.map((item) => {
            filter += '&filter[CategoryID][]=' + item
            return '';
        })

        filter += '&filter[Price:GreaterThanOrEqual]=' + this.props.minPrice;
        if (this.props.maxPrice) {
            filter += '&filter[Price:LessThanOrEqual]=' + this.props.maxPrice;
        }

        if (this.props.title) {
            filter += '&filter[Title:PartialMatch]=' + this.props.title;
        }

        if(this.props.shippingFilter) {
            if(this.props.shippingFilter == '0') {
                filter += "&filter[AllowPackageShipping]=0";
            } else {
                filter += "&filter[AllowPackageShipping]=1";
            }
        }

        // filter += '&filterAny[Description:PartialMatch]=' + this.props.title;

        if (this.props.saleOnly) {
            filter += '&filter[Sale]=' + this.props.saleOnly
        }

        let countFilter = filter + '&onlyCount=1';

        filter += "&customLimit=16"
        if (this.props.offset) {
            filter += "&offset=" + this.props.offset
        }
        ApiService.client.get(
            '/Product/' + initFilter + countFilter,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel2 = c;
                })
            }
        )
            .then((result) => {
                if (typeof this.props.setCountFunction === 'function') {
                    this.props.setCountFunction(result.data.Count);
                }

            })
            .catch((error) => {
                if (typeof error.message !== "undefined" && error.message === "NEWREQUEST") {
                } else {
                    console.log(error)
                }

            })
        ApiService.client.get(
            '/Product/' + initFilter + filter,
            {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c;
                })
            }
        )
            .then((result) => {
                this.setState({
                    products: result.data,
                    loading: false
                })

            })
            .catch((error) => {
                if (typeof error.message !== "undefined" && error.message === "NEWREQUEST") {
                } else {
                    let msg = 'Ein Fehler ist aufgetreten'
                    if (error.response && error.response.data && error.response.data.message) {
                        msg = error.response.data.message
                    }
                    this.setState({loading: false});
                    toastr.error('Fehler laden der Produkte', msg)
                }

            })
    }

    render() {
        return (
            <Row>
                {this.state.products.length === 0 && !this.state.loading ?
                    <Col xs={12} className='text-center'>
                        <h3>Keine Produkte gefunden</h3>
                    </Col>
                    :
                    null
                }
                {this.state.products.map((product, index) => {
                    return (
                        <Col xl={3} lg={6} sm={6} className="mb-4 pb-2" key={product.ID}>
                            <ProductCardItem
                                productID={product.ID}
                                title={product.Title}
                                subTitle=''
                                imageAlt={product.Title}
                                image={product.PreviewImage}
                                imageClass='img-fluid item-img'
                                linkUrl={'/produkt/' + product.ID + '/' + product.URLTitle}
                                price={'€' + product.Price.toFixed(2)}
                                vendorID={product.VendorID}
                                categoryID={product.CategoryID}
                                distance="10km NOCH BERECHENEN!"
                                latitude={product.Latitude}
                                longitude={product.Longitude}
                                showPromoted={product.Promoted}
                                showSale={product.Sale}
                                refPrice={product.RefPrice}
                                favIcoIconColor='text-primary'
                            />
                        </Col>
                    )
                })}
            </Row>
        );
    }
}

