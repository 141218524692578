import React, {Component} from "react";
import BookmarkCollectionAction from "../../stores/bookmarkCollections/actions";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {toastr} from "react-redux-toastr";
import Icofont from "../../components/Icofont/Icofont";
import {ApiService} from "../../services/ApiService";
import LazyImage from "../../components/LazyImage/LazyImage";

class BookmarkCollection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCollectionID: 0,
            selectedCollectionTitle: '',
            currentCollection: {},
            loadingCollection: false,
            editTitle: false,
            deleteConfirm: false,
            newListTitle: 'Neue Merkliste',
            addNewList: false,
            chooseLastList: false
        }

        this.handleCollectionChange = this.handleCollectionChange.bind(this)
        this.handleTitleChange = this.handleTitleChange.bind(this)
        this.handleNewListTitleChange = this.handleNewListTitleChange.bind(this)
        this.handleSaveList = this.handleSaveList.bind(this)
        this.handleTitleSave = this.handleTitleSave.bind(this)
        this.fetchCollection = this.fetchCollection.bind(this)
        this.removeProduct = this.removeProduct.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    fetchCollection() {
        if (this.state.selectedCollectionID) {
            ApiService.authorizedClient(this.props.authKey).get("/BookmarkCollection/" + this.state.selectedCollectionID)
                .then((response) => {
                    this.setState({
                        currentCollection: response.data,
                        selectedCollectionTitle: response.data.Title,
                        loadingCollection: false
                    });
                })
                .catch((error) => {
                    let msg = 'Ein Fehler ist aufgetreten';
                    if (error.response && error.response.data && error.response.data.message) {
                        msg = error.response.data.message;
                    }
                    this.setState({loadingCollection: false});
                    toastr.error('Fehler beim Laden der Merkliste', msg);
                })
        }

    }

    componentDidMount() {
        this.props.fetchCollections();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.collectionsErrorMessage !== this.props.collectionsErrorMessage) {
            if (this.props.collectionsErrorMessage) {
                toastr.error('Fehler', this.props.collectionsErrorMessage)
            }
        }
        if (!this.state.selectedCollectionID && typeof this.props.bookmarkCollections[0] !== 'undefined') {
            this.setState({
                selectedCollectionID: this.props.bookmarkCollections[0].ID,
                selectedCollectionTitle: this.props.bookmarkCollections[0].Title,
                loadingCollection: true
            }, () => this.fetchCollection())
        }
        if (prevState.selectedCollectionID !== this.state.selectedCollectionID) {
            this.setState({
                currentCollection: {},
                loadingCollection: true
            }, () => this.fetchCollection())
        }
        if (typeof this.props.bookmarkCollections[0] !== 'undefined') {
            if (JSON.stringify(prevProps.bookmarkCollections) !== JSON.stringify(this.props.bookmarkCollections)) {
                this.setState({
                    selectedCollectionID: this.state.selectedCollectionID ?? this.props.bookmarkCollections[0].ID,
                    selectedCollectionTitle: this.props.bookmarkCollections[0].Title,
                    loadingCollection: true
                }, () => this.fetchCollection())

            }
        }
        if (JSON.stringify(prevProps.bookmarkCollections) !== JSON.stringify(this.props.bookmarkCollections)) {
            if (this.state.chooseLastList) {
                this.setState({
                    selectedCollectionID: this.props.bookmarkCollections[this.props.bookmarkCollections.length - 1].ID,
                    selectedCollectionTitle: this.props.bookmarkCollections[this.props.bookmarkCollections.length - 1].Title,
                    chooseLastList: false,
                    loadingCollection: true
                }, () => this.fetchCollection())
            }
        }


    }

    handleCollectionChange(e) {
        var index = e.target.selectedIndex;
        this.setState({
            selectedCollectionID: e.target.value,
            selectedCollectionTitle: e.target[index].getAttribute('title') ?? '',
            editTitle: false,
            deleteConfirm: false,
            addNewList: false,
        });

    }

    handleTitleChange(e) {
        this.setState({
            selectedCollectionTitle: e.target.value
        })
    }

    handleNewListTitleChange(e) {
        this.setState({
            newListTitle: e.target.value
        })
    }

    handleSaveList() {
        this.props.updateOrCreateCollection(0, this.state.newListTitle)
        this.setState({
            addNewList: false,
            chooseLastList: true
            // selectedCollectionID: 0 ?? this.state.selectedCollectionID
        })
        // setTimeout(() => {
        //     this.setState({
        //         chooseLastList: true
        //     })
        // },400)
    }

    handleTitleSave() {
        this.props.updateOrCreateCollection(this.state.selectedCollectionID, this.state.selectedCollectionTitle)
        this.setState({
            editTitle: false
        })
    }

    removeProduct(productID, title) {
        //this.props.addItem(this.state.selectedCollectionID,402)
        this.props.removeItem(this.state.selectedCollectionID, productID)
        toastr.success('"' + title + '" wurde entfernt', this.state.selectedCollectionTitle)
    }

    handleDelete() {
        let id = this.state.selectedCollectionID;
        this.props.deleteCollection(id);
        toastr.success('Merkliste wurde gelöscht', this.state.selectedCollectionTitle)
        this.setState({
            selectedCollectionID: 0,
            currentCollection: {},
            selectedCollectionTitle: '',
            deleteConfirm: false
        })
    }


    render() {
        return (
            <div className='p-4 bg-white shadow-sm h-100'>
                {this.props.collectionsAreLoading ?
                    <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    this.props.bookmarkCollections.length ?
                        <>
                            <Row className='align-items-center'>
                                <Col md={12}>
                                    <h4 className="font-weight-bold mt-0 mb-3">Merklisten</h4>
                                </Col>
                                {this.state.addNewList ?
                                    <>
                                        <Col lg={8}>
                                            <div className="form-label-group m-0">
                                                <Form.Control
                                                    type="text"
                                                    id="inputTitle"
                                                    placeholder="Titel"
                                                    onChange={this.handleNewListTitleChange}
                                                    disabled={this.state.loading}
                                                    value={this.state.newListTitle}
                                                    className='input-foreground'
                                                />
                                                <Form.Label htmlFor="inputTitle">Titel</Form.Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div className='d-flex align-items-center mt-3'>
                                                <Button className='btn btn-primary'
                                                        onClick={this.handleSaveList}>Anlegen</Button>
                                                <Icofont
                                                    icon='close'
                                                    className='ml-2 cursor-pointer search-close'
                                                    onClick={() => {
                                                        this.setState({addNewList: !this.state.addNewList})
                                                    }}/>
                                            </div>
                                        </Col>
                                    </>
                                    :
                                    <>
                                        <Col lg={6}>
                                            <Form.Group controlId="title" className='m-0 d-flex'>
                                                <Form.Control as="select"
                                                              value={this.state.selectedCollectionID}
                                                              onChange={this.handleCollectionChange}
                                                >
                                                    {this.props.bookmarkCollections.map((item) =>
                                                        <option
                                                            key={item.ID}
                                                            value={item.ID}
                                                            title={item.Title}
                                                        >
                                                            {item.Title + ' (' + item.Count + ')'}
                                                        </option>
                                                    )}
                                                </Form.Control>
                                                <Icofont icon='plus' className='add-collection'
                                                         onClick={() => {
                                                             this.setState({addNewList: !this.state.addNewList})
                                                         }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            {this.state.deleteConfirm ?
                                                <Row>
                                                    <Col lg={8}><p>Möchtes du die Liste wirklich löschen</p></Col>
                                                    <Col lg={4} className='align-items-center'>
                                                        <Button className='btn btn-primary'
                                                                onClick={this.handleDelete}>Ja</Button>
                                                        <Button variant={"danger"} className='ml-1 btn btn-danger'
                                                                onClick={() => {
                                                                    this.setState({deleteConfirm: !this.state.deleteConfirm})
                                                                }}>Nein</Button>

                                                    </Col>
                                                    <Col lg={3}>

                                                    </Col>
                                                </Row>
                                                :
                                                <>
                                                    {this.state.editTitle ?
                                                        <Row>
                                                            <Col lg={7}>
                                                                <div className="form-label-group m-0">
                                                                    <Form.Control
                                                                        type="text"
                                                                        id="inputTitle"
                                                                        placeholder="Titel"
                                                                        onChange={this.handleTitleChange}
                                                                        disabled={this.state.loading}
                                                                        value={this.state.selectedCollectionTitle}
                                                                        className='input-foreground'
                                                                    />
                                                                    <Form.Label htmlFor="inputTitle">Titel</Form.Label>
                                                                </div>
                                                            </Col>
                                                            <Col lg={5}>
                                                                <div className='d-flex align-items-center mt-3'>
                                                                    <Button className='btn btn-primary'
                                                                            onClick={this.handleTitleSave}>Speichern</Button>
                                                                    <Icofont
                                                                        icon='close'
                                                                        className='ml-2 cursor-pointer search-close'
                                                                        onClick={() => {
                                                                            this.setState({editTitle: !this.state.editTitle})
                                                                        }}/>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <div className='d-inline-block inlineTitleEditField'>
                                                            {this.state.selectedCollectionTitle ?? ''}
                                                            <Icofont
                                                                icon='ui-edit'
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editTitle: !this.state.editTitle
                                                                    })
                                                                }
                                                                }
                                                                className='text-muted ml-2 cursor-pointer'
                                                            />
                                                            {this.props.bookmarkCollections.length > 1 ?
                                                                <Icofont
                                                                    icon='trash'
                                                                    className='ml-2 cursor-pointer search-close'
                                                                    onClick={() => {
                                                                        this.setState({deleteConfirm: !this.state.deleteConfirm})
                                                                    }}/>
                                                                :
                                                                null
                                                            }

                                                        </div>

                                                    }
                                                </>
                                            }

                                        </Col>

                                    </>
                                }

                            </Row>
                            {this.state.addNewList ?
                                null
                                :
                                <Row className='pt-4 pb-3'>
                                    <Col lg={12}>
                                        {this.state.loadingCollection ?
                                            <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            :
                                            typeof this.state.currentCollection.Products === 'undefined' ?
                                                <h5>Du hast keine Produkte in dieser Merkliste</h5>
                                                :
                                                this.state.currentCollection.Products.map((item, index) =>
                                                    <div key={index} className='py-2 px-3 border-top'>
                                                        <Row>
                                                            <Col xs={2}>
                                                                <Link
                                                                    to={'/produkt/' + item.ID + '/' + item.URLTitle + ''}>
                                                                    <LazyImage
                                                                        src={item.PreviewImage}
                                                                        fluid
                                                                        className='checkout-product-img'/>
                                                                </Link>
                                                            </Col>
                                                            <Col xs={4} className='d-flex align-items-center'>
                                                                <span>
                                                                    <b className='d-block w-100 mb-1'>
                                                                    <Link
                                                                        to={'/produkt/' + item.ID + '/' + item.URLTitle + ''}>{
                                                                        item.Title}
                                                                    </Link>
                                                                </b>
                                                                <span className='d-block w-100'>
                                                                <Link
                                                                    to={'/händler/' + item.VendorID + '/' + item.VendorTitle}>
                                                                    {item.VendorTitle}
                                                                </Link>
                                                                </span>
                                                                </span>

                                                            </Col>
                                                            <Col xs={4} className='d-flex align-items-center'>
                                                                € {item.Price.toFixed(2)}
                                                            </Col>
                                                            <Col xs={2}
                                                                 className='d-flex align-items-center justify-content-end text-right'>
                                                                <Icofont icon='trash'
                                                                         className='ml-2 mr-2 cursor-pointer'
                                                                         onClick={() => this.removeProduct(item.ID, item.Title)}/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                        }
                                    </Col>
                                </Row>
                            }

                        </>
                        :
                        <p>{this.props.collectionsErrorMessage ?? ''}</p>
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    bookmarkCollections: state.bookmarkCollection.bookmarkCollections,
    collectionsAreLoading: state.bookmarkCollection.collectionsAreLoading,
    collectionsErrorMessage: state.bookmarkCollection.collectionsErrorMessage
});


const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
    fetchCollections: () => dispatch(BookmarkCollectionAction.fetchCollections()),
    updateOrCreateCollection: (listID, title) => dispatch(BookmarkCollectionAction.updateOrCreateCollection(listID, title)),
    removeItem: (listID, productID) => dispatch(BookmarkCollectionAction.removeItem(listID, productID)),
    addItem: (listID, productID) => dispatch(BookmarkCollectionAction.addItem(listID, productID)),
    deleteCollection: (listID) => dispatch(BookmarkCollectionAction.deleteCollection(listID)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BookmarkCollection));
