// @flow
import React, {Component} from 'react';
import {Badge, Image} from 'react-bootstrap';
import Icofont from "../Icofont/Icofont";

type
Props = {
    title: string,
    icon: string,
    className: string,
    image: string,
    imageAlt: string,
    imageClass: string,
    badgeVariant: string,
    badgeClass: string,
    badgeValue: number,
}

export default class DropDownTitle extends Component<Props> {
    static defaultProps = {
        icon: 'cart-alt',
        className: '',
        imageAlt: '',
        image: '',
        imageClass: '',
        badgeVariant: '',
        badgeClass: '',
        badgeValue: 0,
    }

    render() {
        return (
            <div className={this.props.className}>
                {
                    this.props.image ?
                        <Image alt={this.props.imageAlt} src={this.props.image} className={this.props.imageClass}/>
                        : ''
                }

                {(this.props.icon && !this.props.image) ?
                    <Icofont icon={this.props.icon}/> : ''
                }

                {' ' + this.props.title}

                {this.props.badgeValue ?
                    <Badge variant={this.props.badgeVariant}
                           className={this.props.badgeClass}>{this.props.badgeValue}</Badge>
                    : ''
                }
            </div>
        );
    }
}
