import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import VendorCard from "../../components/Vendor/VendorCard";

export default class Grid extends Component {
    state = {
        vendors: [],
        loading: true
    }

    static defaultProps = {
        title: ''
    }

    componentDidMount() {
        this.loadVendors();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            (prevProps.title !== this.props.title) ||
            (prevProps.lat !== this.props.lat) ||
            (prevProps.lng !== this.props.lng) ||
            (prevProps.sort !== this.props.sort)
        ) {
            this.loadVendors()
        }

    }

    loadVendors() {
        let filter = 'overview?filter[Hidden]=0';
        if (this.props.sort === 'Distance ASC' && this.props.lat && this.props.lng) {
            filter += '&sort=Distance ASC&lat=' + this.props.lat + '&lng=' + this.props.lng
            filter += '&filter[Title:PartialMatch]=' + this.props.title;
        } else {
            filter += '&filter[Title:PartialMatch]=' + this.props.title;
        }


        ApiService.client.get('/Vendor/' + filter)
            .then((result) => {
                this.setState({
                    vendors: result.data.Results ?? []
                })
                if (typeof this.props.setCountFunction === 'function') {
                    this.props.setCountFunction(result.data.Count);
                }
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten'
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            this.setState({loading: false});
            toastr.error('Fehler beim Händler laden', msg)
        })
    }

    render() {
        return (
            <Row>
                {this.state.vendors.map((vendor, index) => {
                    return (
                        <Col key={vendor.ID} xl={3} lg={6} className="mb-4 pb-2">
                            <VendorCard vendor={vendor}/>
                        </Col>
                    )
                })}
            </Row>
        );
    }
}
