import React from 'react';
import {Link, NavLink, Route, Switch, withRouter} from 'react-router-dom';
import {
    Accordion,
    Button,
    ButtonGroup,
    Col,
    Container,
    Dropdown,
    Form,
    FormControl,
    InputGroup, OverlayTrigger,
    Row,
    Spinner, Tooltip
} from 'react-bootstrap';
import Icofont from "../../components/Icofont/Icofont";
import PageTitle from '../../components/PageTitle/PageTitle';
import MarketplaceBackground from '../../assets/img/header_marktplatz-desktop-abdunkler.jpg';
import Grid from "./Grid";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {connect} from "react-redux";
import LocationField from "../../components/LocationField/LocationField";
import Map from "../Marketplace/Map";
import queryString from 'query-string';
import {CheckTree, RangeSlider} from 'rsuite';
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";
import InfoBadge from "../../components/InfoBadge/InfoBadge";

class Marketplace extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            selectedCategories: [],
            salesFilterUrl: '',
            categories: [],
            categoryLoading: true,
            filterCategoryIDs: [],
            productCount: 0,
            productCountLoading: true,
            minPrice: 0,
            minValPrice: 0,
            maxPrice: 0,
            maxOldValuePrice: 0,
            maxValPrice: 1000,
            searchTitle: '',
            searchTerm: '',
            saleOnly: false,
            address: '',
            editLocation: false,
            geolocationAvailable: true,
            ready: false,
            sort: 'Distance ASC',
            sortTitle: 'in der Nähe',
            metaTitle: '',
            metaDesc: '',
            RegShippingInfo: '',
            BulkShippingInfo: ''
        }


        this.handleMinPriceChange = this.handleMinPriceChange.bind(this);
        this.handleMaxPriceChange = this.handleMaxPriceChange.bind(this);
        this.handleSearchTitleChange = this.handleSearchTitleChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleSearchTimeout = this.handleSearchTimeout.bind(this);
        this.setProductCountCallback = this.setProductCountCallback.bind(this);
        this.setFilterCategoryIDs = this.setFilterCategoryIDs.bind(this);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.setOffsetUrl = this.setOffsetUrl.bind(this);
    }

    setOffsetUrl() {
        let newUrl = this.props.history.location.pathname + '?offset=' + this.state.offset;
        let params = queryString.parse(this.props.location.search)
        if (this.state.searchTerm) {
            newUrl += "&search=" + this.state.searchTerm;
        }
        if (typeof params.sale !== 'undefined') {
            newUrl += "&sale=1";
        }
        if (this.state.selectedCategories.length) {
            newUrl += "&categories=" + JSON.stringify(this.state.selectedCategories);
        }
        if (this.state.minPrice) {
            newUrl += "&minPrice=" + this.state.minPrice;
        }
        if (this.state.maxPrice) {
            newUrl += "&maxPrice=" + this.state.maxPrice;
        }
        window.history.pushState({}, null, newUrl)
    }


    next() {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            offset: parseInt(this.state.offset) + 16
        }, () => this.setOffsetUrl())
    }

    prev() {
        window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({
            offset: parseInt(this.state.offset) - 16
        }, () => this.setOffsetUrl())
    }

    setProductCountCallback(count) {
        this.setState({
            productCountLoading: true,
        }, () => {
            this.setState({
                productCountLoading: false,
                productCount: count
            })
        })

    }


    updateGeoPosition() {
        if (this.props.coords.geocodeAddress) {
            this.setState({address: this.props.coords.geocodeAddress.formatted_address ?? ''},
                () => this.setState({
                    geolocationAvailable: false
                })
            )
        }
    }

    componentDidMount() {
        if (typeof this.props.coords === 'undefined' || this.props.coords.latitude === 0) {
            this.setState({
                editLocation: true
            })
        }
        ApiService.client.get('/SiteConfig/1')
            .then((response) => {
                this.setState({
                    metaTitle: response.data.MarktMetaTitle ?? 'Regionlinemarkt - Marktplatz',
                    metaDesc: response.data.MarktMetaTitle ?? 'Angebote aus der Nähe',
                });
            })
            .catch((error) => {
                console.log(error);
            })
        let params = queryString.parse(this.props.location.search)
        let offset = 0;
        let min = 0;
        let max = 0;
        let searchTitle = '';
        let searchTerm = '';
        let saleOnly = '';
        let salesFilterUrl = '';
        let selectedCategories = [];
        if (typeof params.offset !== 'undefined') {
            offset = params.offset;
        }
        if (typeof params.search !== 'undefined') {
            searchTitle = params.search;
            searchTerm = params.search
        }
        if (typeof params.sale !== 'undefined') {
            saleOnly = params.sale;
            salesFilterUrl = '?sale=1';
        }
        if (typeof params.minPrice !== 'undefined') {
            min = parseInt(params.minPrice);
        }
        if (typeof params.maxPrice !== 'undefined') {
            max = parseInt(params.maxPrice);
        }
        if (typeof params.categories !== 'undefined') {
            try {
                let selectedCategoriesTemp = JSON.parse(params.categories)
                if (typeof selectedCategoriesTemp === 'object') {
                    selectedCategories = selectedCategoriesTemp;
                }
            } catch (e) {
                console.log(e);
            }
        }
        this.setState({
            offset: offset,
            minPrice: min,
            maxPrice: max,
            searchTitle: searchTitle,
            searchTerm: searchTerm,
            saleOnly: saleOnly,
            salesFilterUrl: salesFilterUrl,
            selectedCategories: selectedCategories
        })
        ApiService.client.get('/Category/tree/ocwp')
            .then((result) => {
                this.setState({
                    categories: result.data,
                    categoryLoading: false
                })
            })
            .catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({
                        categoryLoading: false
                    }
                );
                toastr.error('Fehler beim laden der Kategorien', msg)
            })

        if (this.props.coords) {
            this.updateGeoPosition();
        }

        if ("geolocation" in navigator) {
            this.setState({geolocationAvailable: true})
        } else {
            this.setState({geolocationAvailable: false})
        }

        this.getPrice();
        this.getShippingFilterInformation();

        let id = this.props.match.params.id;
        if (!isNaN(id) && id) {
            this.setState({
                    selectedCategories: [parseInt(id)],
                }
            )
            window.history.pushState({}, 'Marktplatz', '/marktplatz')
        } else {
            this.setState({
                    ready: true
                }
            );
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.searchTerm !== prevState.searchTerm) {
            this.handleSearchTimeout();
        }
        if (typeof prevProps.coords.geocodeAddress !== 'undefined' && JSON.stringify(prevProps.coords.geocodeAddress) !== JSON.stringify(this.props.coords.geocodeAddress)) {
            this.updateGeoPosition();
        }

        if (JSON.stringify(this.state.selectedCategories) !== JSON.stringify(prevState.selectedCategories)) {
            this.setFilterCategoryIDs();
            this.getPrice();
        }
    }

    handleMinPriceChange(e) {
        let text = e.target.value.replace(',', '.');
        let prevVal = this.state.minPrice
        this.setState({minPrice: text});
        if (isNaN(text)) {
            this.setState(
                {
                    minPrice: prevVal,
                }
            )
        }
    }

    handleMaxPriceChange(e) {
        let text = e.target.value.replace(',', '.');
        this.setState({maxPrice: text});
        if (isNaN(text)) {
            this.setState(
                {
                    prevVal: 0,
                }
            )
        }
    }

    handleSearchTimeout = () => {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.setState({searchTitle: this.state.searchTerm, offset: 0})
            let newUrl = this.props.history.location.pathname + '?search=' + this.state.searchTerm;
            let params = queryString.parse(this.props.location.search)
            if (typeof params.sale !== 'undefined') {
                newUrl += "&sale=1";
            }
            window.history.pushState({}, null, newUrl)
        }, 500);
    }

    handleSearchTitleChange(e) {
        let text = e.target.value;
        this.setState({searchTerm: text});
    }

    handleCategoryChange(keys, e) {
        if (!keys) {
            this.setState({selectedCategories: [], offset: 0}, () => this.setOffsetUrl());
        } else {
            this.setState({
                    selectedCategories: [...keys], offset: 0,
                }, () => this.setOffsetUrl()
            )

        }


    }

    setFilterCategoryIDs() {
        this.setState({
            filterCategoryIDs: this.state.selectedCategories,
            ready: true
        })
    }

    setRangerValue(val) {
        this.setState({
            minPrice: val[0],
            maxPrice: val[1],
            offset: 0
        }, () => this.setOffsetUrl());
    }

    setPrice(min, max) {
        let maxPriceNew = this.state.maxPrice
        let minPriceNew = this.state.minPrice
        if (max <= maxPriceNew || maxPriceNew === 0) {
            maxPriceNew = max
        }

        if (this.state.maxOldValuePrice === maxPriceNew) {
            maxPriceNew = max
        }

        this.setState({
            maxOldValuePrice: max,
            minPrice: minPriceNew,
            minValPrice: min,
            maxPrice: maxPriceNew,
            maxValPrice: max,
        })
    }
    getPrice() {
        let min = 0;
        let max = 10000;

        let initFilter = '?sort=Price DESC&filter[VendorID:not]=0&limit=1';
        let filter = '';
        this.state.selectedCategories.map((item) => {
            filter += '&filter[CategoryID][]=' + item
            return '';
        })

        if (this.state.searchTitle) {
            filter += '&filter[Title:PartialMatch]=' + this.state.searchTitle;
        }

        if (this.props.saleOnly) {
            filter += '&filter[Sale]=' + this.props.saleOnly
        }
        ApiService.client.get('/Product/' + initFilter + filter)
            .then((result) => {
                // let cheapest = result.data[0];
                let mostExpensive = result.data[0];
                // min = parseInt(cheapest.Price);
                max = parseInt(mostExpensive.Price) + 1;
                this.setPrice(0, max)
            })
            .catch((error) => {
                this.setPrice(min, max)
            })
    }

    getShippingFilterInformation() {
        ApiService.client.get('/SiteConfig/1').then((res) => {
            this.setState({
                RegShippingInfo: res.data.RegShippingInfo,
                BulkShippingInfo: res.data.BulkShippingInfo
            });
        })
    }


    render() {
        return (
            <>
                {this.state.metaTitle.length ?
                    <CustomMetaTags
                        title={this.state.metaTitle}
                        desc={this.state.metaDesc}
                    />
                    :
                    null
                }
                <PageTitle
                    title="Angebote in deiner Nähe finden!"
                    subTitle="Suche nach regionalen Händlern, Landwirten, Produzenten, Dienstleistern, ..."
                    bannerText={<>Du willst deine Produkte & Dienstleistungen vermarkten? <Link
                        to="/content/haendler-werden"><b style={{color: "#fff"}}>Arbeite mit uns!</b></Link></>}
                    image={MarketplaceBackground}
                />
                <section className="section pt-5 pb-5 products-listing">
                    <Container fluid>
                        <Row className="mb-3">
                            <Col xl={7} lg={6}>
                                <h4 className="font-weight-bold mt-0 mb-3">
                                    Marktplatz
                                    <small className="h6 mb-0 ml-2">
                                        <span>{this.state.productCount} Angebote in der Nähe von &nbsp;</span>
                                        <span className="d-inline-block">
                                                <span
                                                    className={'d-flex align-items-center ' + (this.state.editLocation ? 'inline-location-container-info' : '')}>
                                                {this.state.editLocation ?
                                                    <div style={{
                                                        display: "inline-block",
                                                        width: '85%'
                                                    }
                                                    }>
                                                        <div
                                                            className="form-row justify-content-start custom-location-field">
                                                            <LocationField className='col-12 mb-0'/>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className='d-inline-block inlineLocationField'>
                                                        {this.state.address ?? ''} <Icofont icon='ui-edit'
                                                                                            onClick={() => {
                                                                                                this.setState({
                                                                                                    editLocation: !this.state.editLocation
                                                                                                })
                                                                                            }
                                                                                            }
                                                                                            className='text-muted ml-2 cursor-pointer'/>
                                                    </div>

                                                }
                                                    <InfoBadge type={'AddressInfoText'}/>
                                            </span>
                                        </span>
                                    </small>
                                </h4>
                            </Col>
                            <Col xl={5} lg={6} className="d-md-flex align-items-center justify-content-lg-end">
                                <ButtonGroup className="mr-2 mb-3 mb-xl-0 mb-lg-0 mb-md-0">
                                    <NavLink
                                        to={`${this.props.match.url}/bulkshipping${this.state.salesFilterUrl}`}
                                        exact
                                        activeClassName="active"
                                        className="btn btn-outline-primary"
                                    >
                                        <Icofont icon="box"/> Sammelversand

                                        <OverlayTrigger
                                            key={'bulk'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={'tooltip-bulk'}>
                                                    {this.state.BulkShippingInfo ?? 'Für diesen Filter wurde kein Informationstext hinterlegt.'}
                                                </Tooltip>
                                            }
                                        >
                                            <Icofont icon={"info-circle"} className={"ml-2"}/>
                                        </OverlayTrigger>
                                    </NavLink>
                                    <NavLink
                                        to={`${this.props.match.url}/regshipping${this.state.salesFilterUrl}`}
                                        activeClassName="active"
                                        className="btn btn-outline-primary mr-2"
                                    >
                                        <Icofont icon="picture"/> Regionalversand
                                        <OverlayTrigger
                                            key={'reg'}
                                            placement={'top'}
                                            overlay={
                                                <Tooltip id={'tooltip-reg'}>
                                                    {this.state.RegShippingInfo ?? 'Für diesen Filter wurde kein Informationstext hinterlegt.'}
                                                </Tooltip>
                                            }
                                        >
                                            <Icofont icon={"info-circle"} className={"ml-2"}/>
                                        </OverlayTrigger>
                                    </NavLink>

                                    <NavLink
                                        to={`${this.props.match.url}${this.state.salesFilterUrl}`}
                                        exact
                                        activeClassName="active"
                                        className="btn btn-outline-primary"
                                    >
                                        <Icofont icon="listing-box"/> Liste
                                    </NavLink>
                                    <NavLink
                                        to={`${this.props.match.url}/map${this.state.salesFilterUrl}`}
                                        activeClassName="active"
                                        className="btn btn-outline-primary"
                                    >
                                        <Icofont icon="map"/> Karte
                                    </NavLink>
                                </ButtonGroup>
                                <Dropdown>
                                    <Dropdown.Toggle variant="outline-primary">
                                        Sortierung: <span
                                        className="text-theme">{this.state.sortTitle}</span> &nbsp;&nbsp;
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-menu-right shadow-sm border-0'>
                                        <Dropdown.Item onClick={() => this.setState({
                                            sort: 'Distance ASC',
                                            sortTitle: 'in der Nähe'
                                        })}>in der Nähe</Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.setState({
                                            sort: 'Price ASC',
                                            sortTitle: 'Preis'
                                        })}
                                        >Preis</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3} lg={4} className='mb-3 mb-xl-0 mb-lg-0'>
                                <div className="filters shadow-sm rounded bg-white">
                                    <div className="filters-header border-bottom pl-4 pr-4 pt-3 pb-3">
                                        <h5 className="m-0">Suche</h5>
                                    </div>
                                    <div className="filters-body">
                                        <div className="filters-card border-bottom p-4">
                                            <FormControl
                                                placeholder="Suchbegriff eingeben..."
                                                value={this.state.searchTerm}
                                                onChange={this.handleSearchTitleChange}
                                            />
                                        </div>
                                        <Accordion defaultActiveKey="0">
                                            <div className="filters-card border-bottom p-4">
                                                <div className="filters-card-header" id="headingOne">
                                                    <h6 className="mb-0">
                                                        <Accordion.Toggle as={Button} size='block' variant="link"
                                                                          className='text-left d-flex align-items-center p-0'
                                                                          eventKey="0">
                                                            Kategorien <Icofont icon='arrow-down' className='ml-auto'/>
                                                        </Accordion.Toggle>
                                                    </h6>
                                                </div>
                                                <Accordion.Collapse eventKey="0">
                                                    <div className="filters-card-body card-shop-filters">
                                                        {this.state.categoryLoading ?
                                                            <Spinner animation="border" role="status"
                                                                     className='mx-auto d-block align-self-center'>
                                                                <span className="sr-only">Loading...</span>
                                                            </Spinner>
                                                            :
                                                            <>
                                                                <CheckTree
                                                                    data={this.state.categories}
                                                                    childrenKey='Children'
                                                                    labelKey='Title'
                                                                    valueKey='ID'
                                                                    value={this.state.selectedCategories}
                                                                    onChange={this.handleCategoryChange}
                                                                    locale={{
                                                                        noResultsText: 'Keine Kategorien',
                                                                    }}
                                                                    renderTreeNode={nodeData => {
                                                                        return (
                                                                            <span>
                                                                              {nodeData.Title} ({nodeData.ProductCount ?? 0})
                                                                            </span>
                                                                        );
                                                                    }}
                                                                />
                                                                <div className="mt-2">
                                                                    <Link to="/marktplatz" className="link">Alle
                                                                        anzeigen</Link>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Accordion.Collapse>
                                            </div>
                                            <div className="filters-card border-bottom p-4">
                                                <div className="filters-card-header" id="headingTwo">
                                                    <h6 className="mb-0">
                                                        <Accordion.Toggle as={Button} size='block' variant="link"
                                                                          className='text-left d-flex align-items-center p-0'
                                                                          eventKey="1">
                                                            Preis <span style={{fontSize: '10px'}}
                                                                        className="ml-2 text-primary">({this.state.minPrice} - {this.state.maxPrice})</span>
                                                            <Icofont icon='arrow-down' className='ml-auto'/>
                                                        </Accordion.Toggle>
                                                    </h6>
                                                </div>

                                                <Accordion.Collapse eventKey="1">
                                                    <div className="filters-card-body card-shop-filters">
                                                        <form className="filters-search mb-3">
                                                            <RangeSlider
                                                                progress
                                                                value={[this.state.minPrice, this.state.maxPrice]}
                                                                min={this.state.minValPrice}
                                                                max={this.state.maxValPrice}
                                                                onChange={value => {
                                                                    this.setRangerValue(value);
                                                                }}
                                                            />
                                                            <InputGroup className='mt-3'>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text>
                                                                        €
                                                                    </InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="text" placeholder="Von"
                                                                              value={this.state.minPrice}
                                                                              onChange={this.handleMinPriceChange}/>
                                                                <Form.Control type="text" placeholder="Bis"
                                                                              value={this.state.maxPrice}
                                                                              onChange={this.handleMaxPriceChange}/>

                                                            </InputGroup>
                                                        </form>
                                                    </div>
                                                </Accordion.Collapse>
                                            </div>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={9} lg={8}>
                                {/*<CategoriesCarousel className="mb-4 pl-2 pr-2"/>*/}
                                {this.state.ready ?

                                    <Switch>
                                        {/*<Route path={`${this.props.match.path}/map`} component={Map}/>*/}
                                        <Route path={`${this.props.match.path}/map`}
                                               render={
                                                   (props) => (
                                                       <Map {...props}
                                                            categoryIDs={this.state.filterCategoryIDs}
                                                            minPrice={this.state.minPrice}
                                                            maxPrice={this.state.maxPrice}
                                                            title={this.state.searchTitle}
                                                            saleOnly={this.state.saleOnly}
                                                            setCountFunction={this.setProductCountCallback}
                                                       />
                                                   )
                                               }
                                        />

                                        <Route path={`${this.props.match.path}/bulkshipping`}
                                               render={
                                                   (props) => (
                                                       <Grid
                                                           {...props}
                                                           offset={this.state.offset}
                                                           categoryIDs={this.state.filterCategoryIDs}
                                                           minPrice={this.state.minPrice}
                                                           maxPrice={this.state.maxPrice}
                                                           title={this.state.searchTitle}
                                                           lat={this.props.coords.latitude ?? 48.247590}
                                                           lng={this.props.coords.longitude ?? 14.303290}
                                                           saleOnly={this.state.saleOnly}
                                                           sort={this.state.sort}
                                                           shippingFilter={'1'}
                                                           setCountFunction={this.setProductCountCallback}
                                                       />
                                                   )
                                               }
                                        />
                                        <Route path={`${this.props.match.path}/regshipping`}
                                               render={
                                                   (props) => (
                                                       <Grid
                                                           {...props}
                                                           offset={this.state.offset}
                                                           categoryIDs={this.state.filterCategoryIDs}
                                                           minPrice={this.state.minPrice}
                                                           maxPrice={this.state.maxPrice}
                                                           title={this.state.searchTitle}
                                                           lat={this.props.coords.latitude ?? 48.247590}
                                                           lng={this.props.coords.longitude ?? 14.303290}
                                                           saleOnly={this.state.saleOnly}
                                                           sort={this.state.sort}
                                                           shippingFilter={'0'}
                                                           setCountFunction={this.setProductCountCallback}
                                                       />
                                                   )
                                               }
                                        />
                                        {/*<Route path={`${this.props.match.path}`} component={Grid}/>*/}
                                        <Route path={`${this.props.match.path}`}
                                               render={
                                                   (props) => (
                                                       <Grid
                                                           {...props}
                                                           offset={this.state.offset}
                                                           categoryIDs={this.state.filterCategoryIDs}
                                                           minPrice={this.state.minPrice}
                                                           maxPrice={this.state.maxPrice}
                                                           title={this.state.searchTitle}
                                                           lat={this.props.coords.latitude ?? 48.247590}
                                                           lng={this.props.coords.longitude ?? 14.303290}
                                                           saleOnly={this.state.saleOnly}
                                                           sort={this.state.sort}
                                                           setCountFunction={this.setProductCountCallback}
                                                       />
                                                   )
                                               }
                                        />
                                    </Switch>
                                    :
                                    <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                }
                                <div className="d-flex justify-content-between">
                                    {this.state.offset ?
                                        <div className="product-grid-btn" onClick={this.prev}>
                                            <Icofont icon="arrow-left" className="mr-2"/>
                                            Zurück
                                        </div>
                                        :
                                        <div></div>
                                    }
                                    {!this.state.productCountLoading ?
                                        <>
                                            {(parseInt(this.state.offset) + 16) < this.state.productCount ?
                                                <div className="product-grid-btn" onClick={this.next}>
                                                    Weiter
                                                    <Icofont icon="arrow-right" className="ml-2"/>
                                                </div>
                                                :
                                                <div></div>
                                            }
                                        </>
                                        :
                                        null
                                    }

                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
});


export default connect(
    mapStateToProps,
)(withRouter(Marketplace));
