import React from 'react';
import OwlCarousel from 'react-owl-carousel3';
import MayLikeItem from '../MayLikeItem/MayLikeItem';
import {withRouter} from "react-router-dom";

class ItemsCarousel extends React.Component {

    constructor(props) {
        super(props);

        this.handleRedirect = this.handleRedirect.bind(this)
    }

    handleRedirect(id, title) {
        this.props.history.push('/produkt/' + id + '/' + title);
    }


    render() {
        if (!this.props.items.length) {
            return null;
        }
        return (
            <OwlCarousel nav loop {...options} className="owl-theme owl-carousel-five offers-interested-carousel">
                {this.props.items.map((product, index) => {
                    return (
                        <div className="item cursor-pointer" key={product.ID}
                             onClick={() => this.handleRedirect(product.ID, product.URLTitle)}>
                            <MayLikeItem
                                title={product.Title}
                                urlTitle={product.URLTitle}
                                produktID={product.ID}
                                price={'€' + product.Price.toFixed(2)}
                                image={product.PreviewImage}
                                imageClass='img-fluid'
                                imageAlt={(typeof product.Images !== 'undefined' && typeof product.Images[0] !== 'undefined') ? product.Images[0].Title : product.Title}
                            />
                        </div>
                    )
                })}
            </OwlCarousel>
        );
    }
}

const options = {
    responsive: {
        0: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
        1200: {
            items: 5,
        },
    },
    lazyLoad: true,
    pagination: "false",
    loop: false,
    dots: false,
    mouseDrag: false,
    autoplay: true,
    autoplayTimeout: 2000,
    nav: true,
    navText: ["<i class='icofont-thin-left'></i>", "<i class='icofont-thin-right'></i>"]
}

export default (withRouter(ItemsCarousel));
