import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Alert, Spinner} from "react-bootstrap";
import {ApiService} from "../../services/ApiService";
import LazyImage from "../../components/LazyImage/LazyImage";


export default class TeaserItem extends Component {
    state = {
        title: '',
        prefix: this.props.elementClassPrefix ?? '',
        subtitle: '',
        content: '',
        link: '',
        img: '',
        imageAlt: '',
        loading: false,
        errorMsg: '',
        imageLoading: false,
    }
    setMainDate = () => {
        this.setState({loading: true,})
        ApiService.client.get('/' + this.state.prefix + 'Teaser/' + this.props.id)
            .then((response) => {
                this.setState(
                    {
                        title: response.data.Title,
                        subTitle: response.data.Subtitle,
                        content: response.data.Content,
                        link: response.data.Link,
                        imageID: response.data.ImageID,
                        loading: false,
                        errorMsg: ''
                    }
                );
                if (this.state.imageID) {
                    this.setImage();
                }
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({errorMsg: error.response.data.message, loading: false});
                } else {
                    this.setState({errorMsg: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    setImage = () => {
        this.setState({imageLoading: true});
        ApiService.client.get('/Image/' + this.state.imageID + '/Pad/400/300/FFFFFF/100')
            .then((response) => {
                this.setState({img: response.data.AbsoluteURL, imageAlt: response.data.Title});
            })
            .catch((error) => {
                console.log(error);
            })
    }

    componentDidMount() {
        this.setMainDate();
    }


    render() {
        return (
            <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                {this.state.errorMsg ?
                    <Alert variant="danger">{this.state.errorMsg}</Alert>
                    :
                    this.state.loading ?
                        <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        :
                        <>
                            <div className="list-card-image p-4">
                                {this.state.img ?
                                    (this.state.link ?
                                            (
                                                <a href={this.state.link} target='_blank' rel="noopener noreferrer">
                                                    <LazyImage src={this.state.img} fluid
                                                               alt={this.state.imageAlt}
                                                    />
                                                </a>
                                            )
                                            :
                                            <LazyImage src={this.state.img} fluid
                                                       alt={this.state.imageAlt}
                                            />
                                    )
                                    : null
                                }
                            </div>
                            <div className="p-3 position-relative">
                                <div className="list-card-body">
                                    <h6 className="mb-1">
                                        {this.state.link ?
                                            <a href={this.state.link} target='_blank' className="text-black" rel="noopener noreferrer">
                                                {this.state.title}
                                            </a>
                                            :
                                            <Link to="#" className="text-black">{this.state.title}</Link>
                                        }
                                    </h6>
                                    <p className="text-gray mb-3">{this.state.subTitle}</p>

                                    {this.state.content ? (
                                            <p className="text-gray mb-3">{this.state.content}</p>
                                        )
                                        : ''
                                    }
                                </div>
                            </div>
                        </>

                }
            </div>
        )
    }
}
