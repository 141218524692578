import React, {Component} from "react";
import {Alert, Col, Container, Row, Spinner} from 'react-bootstrap';
import TeaserItem from "./TeaserItem";
import {ApiService} from "../../services/ApiService";

export default class TeaserElement extends Component {

    state = {
        teaserItems: [],
        prefix: this.props.elementClassPrefix ?? '',
        errorMsg: '',
        loading: false

    }

    componentDidMount() {
        this.setState({loading: true});
        ApiService.client.get('/' + this.state.prefix + 'TeaserElement/' + this.props.id)
            .then((response) => {
                this.setState({
                    teaserItems: response.data.Teasers,
                    errorMsg: '',
                    loading: false
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({errorMsg: error.response.data.message, loading: false});
                } else {
                    this.setState({errorMsg: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    render() {
        return (
            <Container>
                {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    this.state.errorMsg ?
                        <Alert variant="danger">{this.state.errorMsg}</Alert>
                        :
                        <Row>
                            {this.state.teaserItems.map((item, index) => {
                                return <Col key={item.ID} md={4} className="mb-5"> <TeaserItem id={item.ID}
                                                                                               elementClassPrefix={this.state.prefix}/></Col>
                            })}
                        </Row>

                }
            </Container>
        )
    }
}
