import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Icofont from "../Icofont/Icofont";
import config from "../../config";
import FooterMenu from "../FooterMenu/FooterMenu";
import {ApiService} from '../../services/ApiService';
import {Link} from "react-router-dom";

export default class Footer extends React.Component {
    static defaultProps = {
        sectionclassName: 'footer-bottom-search pt-5 pb-5 bg-white',
    }

    state = {
        menus: []
    }

    componentDidMount() {
        ApiService.client.get('/Menu')
            .then((response) => {
                this.setState({menus: response.data});
            })
            .catch((error) => {
                console.log(error);
            })
    }

    render() {
        return (
            <>
                <section className="section pt-5 pb-5 text-center bg-white">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <h5 className="m-0">
                                    Du willst deine Produkte & Dienstleistungen vermarkten?
                                    <Link to="/content/haendler-werden"> Arbeite mit uns!</Link>
                                </h5>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="footer pt-5 pb-5">
                    <Container>
                        <Row>
                            <Col md={4} sm={12}>
                                <a href="https://www.facebook.com/RegionlinemarktAT"
                                   rel="noopener noreferrer"
                                   target="_blank"
                                >
                                    <Icofont icon="facebook" size={2}/>
                                </a>
                                <a href="https://www.instagram.com/regionlinemarkt"
                                   rel="noopener noreferrer"
                                   target="_blank"
                                   className="ml-1"
                                >
                                    <Icofont icon="instagram" size={2}/>
                                </a>
                            </Col>
                            <Col md={1} sm={6} className="mobile-none">
                            </Col>
                            {this.state.menus.map((item, index) => {
                                return <FooterMenu key={item.ID} id={item.ID} title={item.Title}/>;
                            })}
                        </Row>
                    </Container>
                </section>
                <footer className="pt-4 pb-4 text-center">
                    <Container>
                        <p className="mt-0 mb-0">© Copyright 2020 {config.projectName}</p>
                        <small className="mt-0 mb-0"> Made with <Icofont icon="heart" className="text-danger"/> by
                            <a className="text-danger ml-1"
                               rel="noreferrer noopener"
                               target="_blank"
                               href="https://www.cic.at">CIC</a>
                        </small>
                    </Container>
                </footer>
            </>
        );
    }
}
