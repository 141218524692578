import React from 'react';
import {Alert, Badge, Button, Card, Col, Form, FormControl, Row, Spinner} from 'react-bootstrap';
import DeleteProductModal from '../Modals/DeleteProductModal';
import Icofont from "../Icofont/Icofont";
import {Link} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import ProductCard from "./ProductCard";
import {SelectPicker} from "rsuite";
import LazyImage from "../LazyImage/LazyImage";

class Addresses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showDeleteModal: false,
            showDeleteModalText: '',
            searchTitle: '',
            onlyPromoted: 0,
            onlySale: 0,
            error: '',
            products: [],
            productID: 0,
            productTitle: '',
            showCards: true,
            sort: 'Created DESC',
        };

        this.showDeleteModalForAddress = this.showDeleteModalForAddress.bind(this);
        this.fetchProducts = this.fetchProducts.bind(this);
        this.onProductDelete = this.onProductDelete.bind(this);
        this.handleSearchTitleChange = this.handleSearchTitleChange.bind(this);
    };

    onProductDelete() {
        this.fetchProducts();
    }

    fetchProducts() {
        this.setState({loading: true});
        let filter = '&filter[Title:PartialMatch]=' + this.state.searchTitle;
        if (this.state.onlyPromoted) {
            filter += '&filter[Promoted]=1';
        }
        if (this.state.onlySale) {
            filter += '&filter[Sale]=1';
        }

        ApiService.client.get("/Product/?filter[VendorID]=" + this.props.user.VendorID + filter + '&sort=' + this.state.sort)
            .then((response) => {
                this.setState({
                    products: response.data,
                    error: '',
                    loading: false
                });
            })
            .catch((error) => {

                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({error: msg, loading: false});
                toastr.error('Hoppla', msg);
            })
    }

    componentDidMount() {
        this.fetchProducts();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            (prevState.searchTitle !== this.state.searchTitle) ||
            (prevState.onlyPromoted !== this.state.onlyPromoted) ||
            (prevState.onlySale !== this.state.onlySale) ||
            (prevState.sort !== this.state.sort)
        ) {
            this.fetchProducts();
        }
    }


    hideDeleteModal = () => this.setState({showDeleteModal: false});

    showDeleteModalForAddress(productID, productTitle) {
        this.setState({showDeleteModal: true, productID: productID, productTitle: productTitle})
    }

    handleSearchTitleChange(e) {
        let text = e.target.value;
        this.setState({searchTitle: text});
    }

    render() {
        return (
            <>
                <DeleteProductModal
                    show={this.state.showDeleteModal}
                    productID={this.state.productID}
                    productTitle={this.state.productTitle}
                    onHide={this.hideDeleteModal}
                    authKey={this.props.authKey}
                    onProductDelete={() => this.onProductDelete()}
                />
                <div className='p-4 bg-white shadow-sm'>
                    <Row>
                        <Col md={12}>
                            <h4 className="font-weight-bold mt-0 mb-3">Produkte verwalten</h4>
                            <Row className='mb-3'>
                                <Col lg={6} className='mb-lg-0 mb-xl-0 mb-3'>
                                    <FormControl
                                        placeholder="Suchbegriff eingeben..."
                                        value={this.state.searchTitle}
                                        onChange={this.handleSearchTitleChange}
                                    />
                                </Col>
                                <Col lg={6} className='d-flex align-items-center'>
                                    <Form.Group controlId="formPromotedCheckbox" className="mr-3 mb-0">
                                        <Form.Check type="checkbox"
                                                    label="Top-Produkte"
                                                    checked={this.state.onlyPromoted}
                                                    onChange={() => {
                                                        this.setState({
                                                            onlyPromoted: !this.state.onlyPromoted
                                                        });
                                                    }}/>
                                    </Form.Group>
                                    <Form.Group controlId="formSaleCheckbox" className="mr-3 mb-0">
                                        <Form.Check type="checkbox"
                                                    label="SALE-Produkte"
                                                    checked={this.state.onlySale}
                                                    onChange={() => {
                                                        this.setState({
                                                            onlySale: !this.state.onlySale
                                                        });
                                                    }}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={6} className='mb-4'>
                            <label>Sortierung:</label>
                            <SelectPicker
                                className="ml-2"
                                data={[
                                    {label: 'Zuletzt angelegt', value: 'Created DESC'},
                                    {label: 'Name aufsteigend', value: 'Title ASC'},
                                    {label: 'Name absteigend', value: 'Title DESC'},
                                    {label: 'Preis aufsteigend', value: 'Price ASC'},
                                    {label: 'Preis absteigend', value: 'Price DESC'},
                                ]}
                                value={this.state.sort}
                                searchable={false}
                                cleanable={false}
                                placeholder={'Sortierung'}
                                onChange={(val) => this.setState({sort: val})}
                            />
                        </Col>
                        <Col xs={6} className='text-right mb-4'>
                            <Button variant="primary" onClick={() => this.setState({showCards: true})}><Icofont
                                icon="layout"/></Button>
                            <Button variant="primary" className="ml-2"
                                    onClick={() => this.setState({showCards: false})}><Icofont
                                icon="listing-box"/></Button>
                        </Col>
                        {this.state.showCards ?
                            <Col md={6} className='mb-4'>
                                <Card className="bg-white addresses-item h-100 d-flex align-item-center shadow-sm">
                                    <Link to="/myaccount/products/new"
                                          className="gold-members p-4 text-cente text-center d-block">
                                        <h6 className="mt-3 mb-3 text-secondary">Neues Produkt hinzufügen</h6>
                                        <Icofont icon='plus-circle' className='icofont-3x d-inline-block'/>
                                    </Link>
                                </Card>
                            </Col>
                            :
                            <Col md={12} className='mb-4 product-list-item'>
                                <Link
                                    to="/myaccount/products/new"
                                    className="w-100 d-flex align-items-center justify-content-center"
                                >
                                    <h6 className="mt-3 mb-3 text-secondary">Neues Produkt hinzufügen</h6>
                                    <Icofont icon='plus-circle ml-3' className='icofont-3x d-inline-block'/>
                                </Link>
                                <div className="product-list-bottom"/>
                            </Col>
                        }

                        {this.state.loading ?
                            <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            :
                            null
                        }
                        {this.state.error ?
                            <Alert variant="danger">{this.state.error}</Alert>
                            :
                            this.state.showCards ?
                                this.state.products.map((item) =>
                                    <Col md={6} key={item.ID} className='mb-4'>
                                        <ProductCard
                                            id={item.ID}
                                            showPromoted={item.Promoted}
                                            showSale={item.Sale}
                                            boxClass="shadow-sm"
                                            icoIcon='fast-delivery'
                                            iconclassName='icofont-3x'
                                            imgUrl={typeof item.PreviewImage !== 'undefined' ? item.PreviewImage : ''}
                                            title={item.Title}
                                            price={item.Price}
                                            onDeleteClick={() => this.showDeleteModalForAddress(item.ID, item.Title)}
                                        />
                                    </Col>
                                )
                                :
                                this.state.products.map((item) =>
                                    <Col md={12} key={item.ID} className='mb-4'>
                                        <Row className="product-list-item justify-content-center">
                                            <Col xs={2}>
                                                {typeof item.PreviewImage !== 'undefined' ?
                                                    <LazyImage src={item.PreviewImage}
                                                               className="img-fluid product-list-img"/>
                                                    :
                                                    null
                                                }
                                            </Col>
                                            <Col xs={10}>
                                                <Row className="h-100">
                                                    <Col lg={4} className='mb-1 align-self-center'>
                                                        <h6 className="mb-1 text-secondary">{item.Title}</h6>
                                                        {item.Promoted ?
                                                            <Badge variant='primary' className='p-1'>TOP</Badge>
                                                            :
                                                            ""
                                                        }
                                                        {item.Sale ?
                                                            <Badge variant='danger' classsName='p-1'>SALE %</Badge>
                                                            : ""
                                                        }
                                                    </Col>
                                                    <Col lg={2} className='mb-1 text-center align-self-center'>
                                                        <p className="text-black">{item.Price.toFixed(2)} €</p>
                                                    </Col>
                                                    <Col lg={3} className='mb-1 align-self-center'>
                                                        <Button
                                                            as={Link}
                                                            variant='link'
                                                            className="text-primary w-100"
                                                            to={"/myaccount/products/" + item.ID}
                                                        >
                                                            <Icofont icon="ui-edit"/> BEARBEITEN
                                                        </Button>
                                                    </Col>
                                                    <Col lg={3} className='mb-1 align-self-center'>
                                                        <Button
                                                            variant="link"
                                                            className="text-danger w-100"
                                                            to="#"
                                                            onClick={() => this.showDeleteModalForAddress(item.ID, item.Title)}
                                                        >
                                                            <Icofont icon="ui-delete"/> LÖSCHEN
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12}>
                                                <div className="product-list-bottom"/>
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                        }
                    </Row>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Addresses);
