import React, {Component} from "react";
import {Nav, NavDropdown} from "react-bootstrap";
import {Link, NavLink, withRouter} from "react-router-dom";
import DropDownTitle from "../Dropdown/DropDownTitle";
import UserActions from "../../stores/user/actions";
import BasketAction from "../../stores/basket/actions";
import CheckoutAction from "../../stores/checkout/actions";
import BookmarkCollectionAction from "../../stores/bookmarkCollections/actions";
import {connect} from "react-redux";
import Icofont from "../Icofont/Icofont";
import DSGVOAction from "../../stores/dsgvo/actions";

class CustomNavigation extends Component {

    constructor(props) {
        super(props);

        this.handleLinkClick = this.handleLinkClick.bind(this)
        this.handleDSGVOClick = this.handleDSGVOClick.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }

    handleLinkClick() {
        if (typeof this.props.onLinkClick === 'function') {
            this.props.onLinkClick();
        }
    }

    handleDSGVOClick() {
        this.props.setCookieOverlayState(true)
    }


    handleLogout() {
        this.props.logout();
        this.props.clearBasket();
        this.props.clearCollections();
        this.props.clearCheckout();
        this.props.history.push('/');
    }


    render() {
        return (
            <Nav className="ml-auto" onSelect={this.props.handleSelect}>
                <Nav.Link onClick={this.handleLinkClick} as={NavLink} exact activeClassName="active" to="/">
                    Home
                </Nav.Link>
                <Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName="active" to="/marktplatz">
                    Marktplatz
                </Nav.Link>
                {/*<Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName="active" to="/marktplatz?sale=1">*/}
                {/*    SALE %*/}
                {/*</Nav.Link>*/}
                <Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName="active" to="/news">
                    News
                </Nav.Link>
                <Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName="active" to="/rezepte">
                    Rezepte
                </Nav.Link>
                <Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName="active" to="/händlerübersicht">
                    Händler
                </Nav.Link>
                <Nav.Link onClick={this.handleLinkClick} as={NavLink} activeClassName="active" to="/ueber-uns">
                    Über uns
                </Nav.Link>
                {this.props.isLoggedIn ?
                    <NavDropdown
                        className='login-nav-item'
                        alignRight
                        title={
                            <><Icofont
                                icon="user"/>&nbsp;
                                {(this.props.user.FirstName ?? '') + ' ' + (this.props.user.Surname ?? '')}</>
                        }
                        id="account">
                        <NavDropdown.Item onClick={this.handleLinkClick} as={Link} activeClassName="active"
                                          to="/myaccount/orders">
                            <Icofont icon='food-cart'/> Bestellungen
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={this.handleLinkClick} as={Link} activeClassName="active"
                                          to="/myaccount/bookmarks">
                            <Icofont icon='list'/> Merklisten
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={this.handleLinkClick} as={Link} activeClassName="active"
                                          to="/myaccount/">
                            <Icofont icon='user'/> Accountverwaltung
                        </NavDropdown.Item>
                        {this.props.user.VendorID !== 0 ?
                            <NavDropdown.Item onClick={this.handleLinkClick} as={Link}
                                              to='/myaccount/vendorregistration'>
                                <Icofont icon='icofont-euro'/> Händlerdaten bearbeiten
                            </NavDropdown.Item>
                            :
                            null
                        }
                        <NavDropdown.Item
                            onClick={this.handleDSGVOClick}
                            activeClassName="active">
                            <Icofont icon='database-locked'/> DSGVO Einstellungen
                        </NavDropdown.Item>
                        <NavDropdown.Item activeClassName="" onClick={() => {
                            this.handleLogout();
                            this.handleLinkClick()
                        }}>
                            <Icofont icon='icofont-logout'/> Abmelden
                        </NavDropdown.Item>

                    </NavDropdown>
                    :
                    <Nav.Link as={NavLink} activeClassName="active" className='login-nav-item' to="/login"
                              onClick={this.handleLinkClick}>
                        <Icofont icon={'sign-in'}/> Login / Registrieren
                    </Nav.Link>}

                <Nav.Link className='icon-only' onClick={this.props.toggleCard}>
                    <DropDownTitle
                        className='d-inline-block'
                        icon='shopping-cart'
                        iconClass='mr-1'
                        title=''
                        badgeClass='ml-1'
                        badgeVariant='success'
                        badgeValue={typeof this.props.itemCount !== 'undefined' ? this.props.itemCount : 0}
                    />
                </Nav.Link>
                <Nav.Link className='icon-only searchIcon' onClick={this.props.searchToggle}>
                    <Icofont icon='search'/>
                </Nav.Link>
            </Nav>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    itemCount: state.basket.itemCount,
    memberID: state.user.memberID,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(UserActions.logout()),
    clearBasket: () => dispatch(BasketAction.clearBasket()),
    clearCollections: () => dispatch(BookmarkCollectionAction.clearCollections()),
    setCookieOverlayState: (setCookieOverlayState) => dispatch(DSGVOAction.setCookieOverlayState(setCookieOverlayState)),
    clearCheckout: () => dispatch(CheckoutAction.clearCheckout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(CustomNavigation));
