import React from 'react';
import {connect} from "react-redux";
import {Col, Row, Spinner} from "react-bootstrap";
import UserActions from "../../stores/user/actions";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";

class StripePaymentOnBoarding extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userIsLoading: true,
            currentDeadline: '',
            currentlyDue: '',
            disabledReason: '',
            errors: '',
            isLoading: ''
        }
        this.getOnBoardingLink = this.getOnBoardingLink.bind(this)
        this.getStripAccountInfo = this.getStripAccountInfo.bind(this)
        this.getTranslatedDisabledReasonCode = this.getTranslatedDisabledReasonCode.bind(this)
        this.getTranslatedErrorCode = this.getTranslatedErrorCode.bind(this)
    }

    componentDidMount() {
        this.props.fetchUser();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            typeof this.props.userIsLoading !== 'undefined' &&
            typeof prevProps.userIsLoading !== 'undefined' &&
            prevProps.userIsLoading !== this.props.userIsLoading
        ) {
            this.setState({userIsLoading: this.props.userIsLoading})
            this.getStripAccountInfo();
        }
    }

    getOnBoardingLink() {
        return process.env.REACT_APP_BASE_URL + '/Vendor/onBoardingDirect/' + this.props.user.StripeAccountID + '/'
    }

    getTranslatedDisabledReasonCode($val) {
        switch ($val) {
            case 'requirements.past_due':
                return 'Zusätzliche Überprüfungsinformationen sind erforderlich!'
            case 'requirements.pending_verification':
            case 'under_review':
                return 'Überprüfung der Informationen zum verbundenen Konto.'
            case 'rejected.fraud':
                return 'Das Konto wird wegen mutmaßlichen Betrugs oder illegaler Aktivitäten abgelehnt.'
            case 'rejected.terms_of_service':
                return 'Das Konto wird aufgrund vermuteter Verstöße gegen die Nutzungsbedingungen abgelehnt.'
            case 'rejected.listed':
            case 'listed':
                return 'Das Konto wird abgelehnt, weil es auf der Liste der verbotenen Personen oder ' +
                    'Unternehmen Dritter (z. B. Finanzdienstleister oder Behörden) steht.'
            case 'rejected.other':
            case 'other':
                return 'Konto wird aus einem anderen Grund abgelehnt.'
            default:
                return $val
        }
    }

    getTranslatedErrorCode($val) {
        switch ($val) {
            case 'invalid_address_city_state_postal_code':
                return 'Die Kombination von Stadt, Bundesland und Postleitzahl in der angegebenen Adresse konnte nicht überprüft werden.'
            case 'invalid_street_address':
                return 'Der Straßenname und / oder die Straßennummer für die angegebene Adresse konnten nicht überprüft werden.'
            case 'invalid_value_other':
                return 'Für das ein Feld wurde ein ungültiger Wert angegeben..'
            case 'verification_document_address_mismatch':
                return 'Die Adresse auf dem Dokument stimmte nicht mit der Adresse auf dem Konto überein. Laden Sie ein Dokument mit einer übereinstimmenden Adresse hoch oder aktualisieren Sie die Adresse im Konto'
            case 'verification_document_address_missing':
                return 'Die Firmenadresse fehlte im Dokument. Laden Sie ein Dokument hoch, das die Adresse enthält.'
            case 'verification_document_corrupt':
                return 'Die hochgeladene Datei für das Dokument war ungültig oder beschädigt. Laden Sie eine neue Datei des Dokuments hoch.'
            case 'verification_document_country_not_supported':
                return 'Das bereitgestellte Dokument stammte aus einem nicht unterstützten Land.'
            case 'verification_document_dob_mismatch':
                return 'Das Geburtsdatum (GB) auf dem Dokument stimmte nicht mit dem DOB auf dem Konto überein. Laden Sie ein Dokument mit einem passenden GB hoch oder aktualisieren Sie das GB im Konto.'
            case 'verification_document_duplicate_type':
                return 'Der gleiche Dokumenttyp wurde zweimal verwendet. Für die Überprüfung sind zwei eindeutige Dokumenttypen erforderlich. Laden Sie zwei verschiedene Dokumente hoch.'
            case 'verification_document_expired':
                return 'Das Dokument konnte nicht zur Überprüfung verwendet werden, da es abgelaufen ist. Wenn es sich um ein Ausweisdokument handelt, muss das Ablaufdatum nach dem Datum liegen, an dem das Dokument eingereicht wurde. Wenn es sich um ein Adressdokument handelt, muss das Ausstellungsdatum innerhalb der letzten sechs Monate liegen.'
            case 'verification_document_failed_copy':
                return 'Das Dokument konnte nicht überprüft werden, da es als Kopie erkannt wurde (z. B. Foto oder Scan). Laden Sie das Originaldokument hoch.'
            case 'verification_document_failed_greyscale':
                return 'Das Dokument konnte nicht zur Überprüfung verwendet werden, da es in Graustufen vorliegt. Laden Sie eine Farbkopie des Dokuments hoch.'
            case 'verification_document_failed_other':
                return 'Das Dokument konnte aus einem unbekannten Grund nicht überprüft werden.'
            case 'verification_document_fraudulent':
            case 'verification_document_manipulated':
            case 'verification_document_photo_mismatch':
                return 'Das Dokument wurde als geändert oder gefälscht identifiziert.'
            case 'verification_document_id_number_mismatch':
                return 'Die Firmen-ID auf dem Konto konnte nicht überprüft werden. Korrigieren Sie alle Fehler im Feld ID-Nummer oder laden Sie ein Dokument hoch, das die ID-Nummer enthält..'
            case 'verification_document_id_number_missing':
                return 'Die Firmen-ID-Nummer fehlte im Dokument. Laden Sie ein Dokument hoch, das die ID-Nummer enthält.'
            case 'verification_document_incomplete':
                return 'Das Dokument wurde zugeschnitten oder es fehlten wichtige Informationen. Laden Sie einen vollständigen Scan des Dokuments hoch.'
            case 'verification_document_invalid':
            case 'verification_document_type_not_supported':
                return 'Die hochgeladene Datei war nicht einer der gültigen Dokumenttypen.'
            case 'verification_document_missing_back':
                return 'In der hochgeladenen Datei fehlte die Rückseite des Dokuments. Laden Sie einen vollständigen Scan des Dokuments hoch.'
            case 'verification_document_missing_front':
                return 'In der hochgeladenen Datei fehlte die Vorderseite des Dokuments. Laden Sie einen vollständigen Scan des Dokuments hoch.'
            case 'verification_document_name_mismatch':
                return 'Der Name auf dem Dokument stimmte nicht mit dem Namen auf dem Konto überein. Laden Sie ein Dokument mit einem passenden Namen hoch oder aktualisieren Sie den Namen des Kontos.'
            case 'verification_document_name_missing':
                return 'Der Firmenname fehlte im Dokument. Laden Sie ein Dokument hoch, das den Firmennamen enthält.'
            case 'verification_document_nationality_mismatch':
                return 'Die Nationalität des Dokuments stimmte nicht mit der angegebenen Nationalität der Person überein. Aktualisieren Sie die angegebene Nationalität der Person oder laden Sie ein entsprechendes Dokument hoch.'
            case 'verification_document_not_readable':
                return 'Das Dokument konnte nicht gelesen werden.'
            case 'verification_document_not_uploaded':
                return 'Es wurde kein Dokument hochgeladen. Laden Sie das Dokument erneut hoch.'
            case 'verification_document_too_large':
                return 'Die hochgeladene Datei hat die Größenbeschränkung von 10 MB überschritten. Ändern Sie die Größe des Dokuments und laden Sie die neue Datei hoch.'
            case 'verification_failed_address_match':
                return 'Die Adresse auf dem Konto konnte nicht überprüft werden. Korrigieren Sie alle Fehler im Adressfeld oder laden Sie ein Dokument hoch, das die Adresse enthält.'
            case 'verification_failed_document_match':
            case 'verification_failed_id_number_match':
                return 'Das Dokument konnte nicht überprüft werden. Laden Sie ein Dokument hoch, das die Felder Firmenname, ID-Nummer und Adresse enthält.'
            case 'verification_failed_keyed_identity':
            case 'verification_failed_keyed_match':
                return 'Die eingegebenen Identitätsinformationen der Person konnten nicht überprüft werden. Korrigieren Sie alle Fehler oder laden Sie ein Dokument hoch, das den eingegebenen Identitätsfeldern (z. B. Name und Geburtsdatum) entspricht.'
            case 'verification_failed_name_match':
                return 'Der Firmenname auf dem Konto konnte nicht überprüft werden. Korrigieren Sie alle Fehler im Feld Firmenname oder laden Sie ein Dokument hoch, das den Firmennamen enthält.'
            case 'verification_failed_other':
                return 'Die Überprüfung ist aus einem unbekannten Grund fehlgeschlagen. Korrigieren Sie alle Fehler und senden Sie die erforderlichen Felder erneut.'
            default:
                return $val
        }
    }

    getStripAccountInfo() {
        ApiService.authorizedClient(this.props.authKey).get("/Vendor/getStripeAccountObject/" + this.props.user.StripeAccountID)
            .then((result) => {
                let currentDeadline = "";
                let currentlyDue = "";
                let disabledReason = "";
                let error = "";
                if (
                    typeof result.data.requirements.current_deadline !== 'undefined' &&
                    result.data.requirements.current_deadline !== null
                ) {
                    currentDeadline = new Date(result.data.requirements.current_deadline).toLocaleString('de-DE');
                }
                if (
                    typeof result.data.requirements.currently_due !== 'undefined' &&
                    result.data.requirements.currently_due.length
                ) {
                    currentlyDue = 'Es fehlen noch (' + result.data.requirements.currently_due.length + ') Informationen';
                }
                if (
                    typeof result.data.requirements.disabled_reason !== 'undefined' &&
                    result.data.requirements.disabled_reason !== ""
                ) {
                    disabledReason = this.getTranslatedDisabledReasonCode(result.data.requirements.disabled_reason);
                }
                if (
                    typeof result.data.requirements.error !== 'undefined' &&
                    result.data.requirements.error !== ""
                ) {
                    disabledReason = this.getTranslatedErrorCode(result.data.requirements.error);
                }
                this.setState({
                    currentDeadline: currentDeadline,
                    currentlyDue: currentlyDue,
                    disabledReason: disabledReason,
                    error: error
                })
            })
            .catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten';
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message;
                }
                this.setState({loading: false});
                toastr.error('Fehler beim Laden der Bestellungen', msg);
            })
    }


    render() {
        return (
            <div className="p-4 bg-white shadow-sm">
                <Row>
                    <Col xs={12}>
                        {this.state.userIsLoading ?
                            <Spinner animation="border" role="status"
                                     className='mx-auto my-3 d-block align-self-center'>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            :
                            <>
                                {this.props.user.VendorStripeAccountCompleted ?
                                    <>
                                        <h3>Ihr Account ist aktiv</h3><br/>
                                        <a className='btn btn-primary' href={this.getOnBoardingLink()} target="_blank"
                                           rel="noopener noreferrer">
                                            Beim Zahlungsanbieter anmelden
                                        </a>
                                    </>
                                    :
                                    <>
                                        <h3>Ihr Account ist noch nicht freigegeben</h3><br/>
                                        {this.state.currentDeadline ?
                                            <>
                                                <p>Eigabefrsit: currentDeadline</p><br/>
                                            </>
                                            :
                                            null
                                        }
                                        {this.state.currentlyDue ?
                                            <>
                                                <p>{this.state.currentlyDue}</p><br/>
                                            </>
                                            :
                                            null
                                        }
                                        {this.state.disabledReason ?
                                            <>
                                                <p><b>{this.state.disabledReason}</b></p><br/>
                                            </>
                                            :
                                            null
                                        }
                                        {this.state.error ?
                                            <>
                                                <p>Eingabefehler: {this.state.error}</p><br/>
                                            </>
                                            :
                                            null
                                        }
                                        <a className='btn btn-primary' href={this.getOnBoardingLink()} target="_blank"
                                           rel="noopener noreferrer">
                                            Informationen eintragen
                                        </a>
                                    </>

                                }

                            </>


                        }
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    user: state.user.user
});
const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StripePaymentOnBoarding);
