import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import Wrapper from "./Wrapper";
import {AnimatePresence} from "framer-motion";
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import createStore from '../../stores'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

const {store, persistor} = createStore();
export {store}

export default class Root extends Component {
    state = {
        menuTransparency: true
    }

    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.pageYOffset > 0) {
            this.setState({menuTransparency: false});
        } else {
            this.setState({menuTransparency: true});
        }
    }

    render() {
        return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <div style={{position: "relative"}}>
                        <AnimatePresence>
                            <Router>
                                <Route path="/" component={Wrapper}/>
                            </Router>
                        </AnimatePresence>
                    </div>
                </PersistGate>
            </Provider>
        )
    }
}
