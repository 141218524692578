import React from 'react';
import {Alert, Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import DeleteShippingRateModal from '../Modals/DeleteShippingRateModal';
import Icofont from "../Icofont/Icofont";
import {Link} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import ShippingCard from "./ShippingCard";
import {HTMLService} from "../../services/HTMLService";

class Addresses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            vendor: {},
            UseBulkShipping: false,
            loading: true,
            showDeleteModal: false,
            showDeleteModalText: '',
            error: '',
            shippingRates: [],
            shippingRateID: 0,
            shippingRateTitle: '',
        };

        this.showDeleteModalForAddress = this.showDeleteModalForAddress.bind(this);
        this.fetchShippingRates = this.fetchShippingRates.bind(this);
        this.onShippingRateDelete = this.onShippingRateDelete.bind(this);
        this.updateBulkShipping = this.updateBulkShipping.bind(this);
    };

    onShippingRateDelete() {
        this.fetchShippingRates();
    }

    fetchShippingRates() {
        this.setState({loading: true});
        ApiService.client.get('/Vendor/' + this.props.user.VendorID)
            .then((response) => {
                this.setState({
                    vendor: response.data,
                    UseBulkShipping: response.data.UseBulkShipping,
                });
            })
            .catch((error) => {
            })
        ApiService.client.get('/SiteConfig/1')
            .then((response) => {
                this.setState({
                    bulkText: response.data.BulkDeliveryText
                });
            })
            .catch((error) => {
            })
        ApiService.client.get("/ShippingRate/?filter[VendorID]=" + this.props.user.VendorID)
            .then((response) => {
                this.setState({
                    shippingRates: response.data,
                    error: '',
                    loading: false
                });
            })
            .catch((error) => {

                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({error: msg, loading: false});
                toastr.error('Hoppla', msg);
            })
    }

    componentDidMount() {
        this.fetchShippingRates();
    }


    hideDeleteModal = () => this.setState({showDeleteModal: false});

    showDeleteModalForAddress(shippingRateID, shippingRateTitle) {
        this.setState({showDeleteModal: true, shippingRateID: shippingRateID, shippingRateTitle: shippingRateTitle})
    }

    updateBulkShipping() {
        ApiService.authorizedClient(this.props.authKey).put(
            '/Vendor/' + this.props.user.VendorID,
            {
                UseBulkShipping: this.state.UseBulkShipping,
            }
        )
            .then((response) => {
                toastr.success('Erfolgreich', 'Deine Sammelversand wurden aktualisiert')
            })
            .catch((error) => {
            })
    }

    render() {
        return (
            <>
                <DeleteShippingRateModal
                    show={this.state.showDeleteModal}
                    shippingRateID={this.state.shippingRateID}
                    shippingRateTitle={this.state.shippingRateTitle}
                    onHide={this.hideDeleteModal}
                    authKey={this.props.authKey}
                    onShippingRateDelete={() => this.onShippingRateDelete()}
                />
                <div className='p-4 bg-white shadow-sm'>
                    <Row>
                        <Col md={12}>
                            <h4 className="font-weight-bold mt-0 mb-3">Versandoption verwalten</h4>
                        </Col>
                        {this.state.vendor.AllowBulkShipping ?
                            <Col md={12}>
                                <div className="bulk-box shadow-sm mb-2">
                                    <Form.Check type='checkbox' className="my-2 mx-4" id={`formTopCheckbox`}>
                                        <Form.Check.Label className='w-100 d-flex align-items-center pb-3'>
                                            <Form.Check.Input
                                                type='checkbox'
                                                checked={this.state.UseBulkShipping}
                                                onChange={() => this.setState({UseBulkShipping: !this.state.UseBulkShipping}, () => this.updateBulkShipping())}
                                            />
                                            <div>
                                                <b>Am Sammelversand teilnehmen?</b>
                                            </div>
                                        </Form.Check.Label>
                                        <div
                                            dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.bulkText)}}/>
                                    </Form.Check>
                                </div>
                            </Col>
                            :
                            null
                        }
                        <Col md={6} className='mb-4'>
                            <Card className="bg-white addresses-item h-100 d-flex align-item-center shadow-sm">
                                <Link to="/myaccount/shippingsettings/new"
                                      className="gold-members p-4 text-cente text-center d-block">
                                    <h6 className="mt-3 mb-3 text-secondary">Neue Versandoption hinzufügen</h6>
                                    <Icofont icon='plus-circle' className='icofont-3x d-inline-block'/>
                                </Link>
                            </Card>
                        </Col>
                        {this.state.loading ?
                            <Spinner animation="border" role="status" className='mx-auto my-3 d-block'>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            :
                            null
                        }
                        {this.state.error ?
                            <Alert variant="danger">{this.state.error}</Alert>
                            :
                            this.state.shippingRates.map((item, index) => {
                                return (<Col md={6} key={item.ID} className='mb-4'>
                                        <ShippingCard
                                            id={item.ID}
                                            boxClass="shadow-sm"
                                            icoIcon='fast-delivery'
                                            iconclassName='icofont-3x'
                                            title={item.Title}
                                            rate={item.Rate}
                                            onDeleteClick={() => this.showDeleteModalForAddress(item.ID, item.Title)}
                                        />
                                    </Col>
                                )
                            })
                        }

                    </Row>
                </div>
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Addresses);
