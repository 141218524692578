/**
 * The initial values for the redux state.
 */
export const INITIAL_STATE = {
    basketItems: [],
    list: [],
    basketID: 0,
    basketLoading: false,
    lastEdited: null,
    itemCount: 0,
    total: 0,
};
