import React, {Component} from 'react';
import {Alert, Form, Spinner} from "react-bootstrap";
import countryList from '../../custom-country-list/CustomCountryList'
import Select from 'react-select'
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {withRouter} from 'react-router-dom';
import {HTMLService} from "../../services/HTMLService";

class AddressForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberID: 0,
            id: 0,
            street: '',
            streetError: '',
            street2: '',
            street2Error: '',
            number: '',
            numberError: '',
            city: '',
            cityError: '',
            country: '',
            countryError: '',
            companyDisabled: false,
            zip: '',
            zipError: '',
            loading: false,
            error: '',
            success: '',
            page: 1,
            company: '',
            companyError: '',
            uid: '',
            uidError: '',
            options: countryList().getData()
        };

        this.handleStreetChange = this.handleStreetChange.bind(this);
        this.handleStreet2Change = this.handleStreet2Change.bind(this);
        this.handleNumberChange = this.handleNumberChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.handleDataSubmit = this.handleDataSubmit.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleUIDChange = this.handleUIDChange.bind(this);

    };

    handleCompanyChange(e) {
        let text = e.target.value;
        this.setState({company: text});

    }

    handleUIDChange(e) {
        let text = e.target.value;
        this.setState({uid: text});
        if (text.length) {
            ApiService.vatClient.get(text)
                .then((response) => {
                    let company = this.state.company;
                    let companyDisabled = false;
                    if (response.data.name.length && response.data.name !== '---') {
                        company = response.data.name;
                        companyDisabled = true;
                    }
                    this.setState({
                        uidError: '',
                        company: company,
                        companyDisabled: companyDisabled
                    })
                })
                .catch((error) => {
                    let errormsg = '';
                    if (error.response.data.validationResult.messages.length) {
                        error.response.data.validationResult.messages.forEach((msg) => {
                            errormsg += msg.message + '<br>'
                        })
                    }
                    this.setState({
                        uidError: errormsg,
                        companyDisabled: false
                    })
                })
        } else {
            this.setState({
                uidError: '',
                companyDisabled: false
            })
        }

    }

    handleStreetChange(e) {
        let text = e.target.value;
        this.setState({street: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({streetError: 'Bitte gib eine gültige Straße ein!'});
        } else {
            this.setState({streetError: ''});
        }

    }

    handleStreet2Change(e) {
        let text = e.target.value;
        this.setState({street2: text});
    }

    handleNumberChange(e) {
        let text = e.target.value;
        this.setState({number: text});
        if (text.match(/^[0-9]+[a-zA-Z]{0,3}$/) === null) {
            this.setState({numberError: 'Bitte gib eine gültige Hausnummer ein!'});
        } else {
            this.setState({numberError: ''});
        }
    }

    handleCityChange(e) {
        let text = e.target.value;
        this.setState({city: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({cityError: 'Bitte gib eine gültige Ort ein!'});
        } else {
            this.setState({cityError: ''});
        }
    }

    handleCountryChange(target) {
        let text = target.value;
        this.setState({country: text});
    }

    handleZipChange(e) {
        let text = e.target.value;
        this.setState({zip: text});
    }

    handleDataSubmit() {
        if (
            this.state.streetError ||
            this.state.uidError ||
            this.state.numberError ||
            this.state.cityError ||
            this.state.countryError ||
            this.state.zipError
        ) {
            this.setState({error: 'Überprüfe deine Eingabe'});
            return;
        }
        if (
            this.state.street.length <= 0 ||
            this.state.number.length <= 0 ||
            this.state.city.length <= 0 ||
            this.state.country.length <= 0 ||
            this.state.zip.length <= 0
        ) {
            this.setState({error: 'Bitte fülle alle Felder aus!'});
            return;
        }
        const me = this;
        me.setState({loading: true});
        if (this.props.match.params.id === 'new') {
            ApiService.authorizedClient(this.props.authKey).post(
                '/Address',
                {
                    'Street': me.state.street,
                    'Number': me.state.number,
                    'City': me.state.city,
                    'Country': me.state.country,
                    'PostalCode': me.state.zip,
                    'MemberID': me.state.memberID,
                    'Company': me.state.company,
                    'UID': me.state.uid,
                }
            ).then(
                () => {
                    toastr.success('Erfolgreich', 'Adresse wurde hinzugefügt')
                    this.props.history.push('/myaccount/addresses');
                    me.setState({loading: false, error: '', success: ''});
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                    me.setState({loading: false, error: ''});
                },
            );
        } else {
            ApiService.authorizedClient(this.props.authKey).put(
                '/Address/' + this.props.match.params.id,
                {
                    'Street': me.state.street,
                    'Number': me.state.number,
                    'City': me.state.city,
                    'Country': me.state.country,
                    'Zip': me.state.zip,
                    'MemberID': me.state.memberID,
                    'Company': me.state.company,
                    'UID': me.state.uid,
                }
            ).then(
                () => {
                    //me.setState({loading: false, error: '', success: 'Erfolgreich geändert'});
                    toastr.success('Erfolgreich', 'Adresse wurde geändert')
                    this.props.history.push('/myaccount/addresses');
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                },
            );
        }

    }

    componentDidMount() {
        this.props.fetchUser();
        this.setState({
            memberID: this.props.memberID,
            loading: true,
        });
        if (this.props.match.params.id !== 'new') {
            const id = this.props.match.params.id;
            ApiService.authorizedClient(this.props.authKey).get("/Address/" + id)
                .then((response) => {
                    this.setState({
                        company: response.data.Company,
                        uid: response.data.UID,
                        street: response.data.Street,
                        street2: response.data.Street2,
                        number: response.data.Number,
                        city: response.data.City,
                        country: response.data.Country,
                        zip: response.data.PostalCode,
                        error: '',
                        loading: false
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message, loading: false});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                    }
                })
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    render() {
        return (
            <div className={'p-4 bg-white shadow-sm ' + (this.state.loading ? 'h-100 d-flex' : '')}>
                {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-auto d-block align-self-center'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    <>
                        {this.props.match.params.id === 'new' ?
                            <h4 className='font-weight-bold mt-0 mb-4'>Neue Adresse</h4>
                            :
                            <h4 className='font-weight-bold mt-0 mb-4'>Adresse bearbeiten</h4>
                        }
                        <Form>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputCompany"
                                    placeholder="Firmenname"
                                    onChange={this.handleCompanyChange}
                                    disabled={this.state.loading || this.state.companyDisabled}
                                    value={this.state.company}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputCompany">Firmenname</Form.Label>
                                <p className='text-danger m-0'>{this.state.companyError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputUID"
                                    placeholder="UID"
                                    onChange={this.handleUIDChange}
                                    disabled={this.state.loading}
                                    value={this.state.uid}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputUID">UID</Form.Label>
                                <p className='text-danger m-0'
                                   dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.uidError)}}/>
                                <p className='text-danger m-0'>&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputStreet"
                                    placeholder="Straße"
                                    onChange={this.handleStreetChange}
                                    disabled={this.state.loading}
                                    value={this.state.street}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputStreet">Straße</Form.Label>
                                <p className='text-danger m-0'>{this.state.streetError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputNumber"
                                    placeholder="Hausnummer"
                                    onChange={this.handleNumberChange}
                                    disabled={this.state.loading}
                                    value={this.state.number}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputNumber">Hausnummer</Form.Label>
                                <p className='text-danger m-0'>{this.state.numberError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputZip"
                                    placeholder="Postleitzahl"
                                    onChange={this.handleZipChange}
                                    disabled={this.state.loading}
                                    value={this.state.zip}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputZip">Postleitzahl</Form.Label>
                                <p className='text-danger m-0'>{this.state.zipError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputCity"
                                    placeholder="Ort"
                                    onChange={this.handleCityChange}
                                    disabled={this.state.loading}
                                    value={this.state.city}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputCity">Ort</Form.Label>
                                <p className='text-danger m-0'>{this.state.cityError}&nbsp;</p>
                            </div>
                            <div className="m-0">
                                <Form.Label htmlFor="inputCountry">Land</Form.Label>
                                <Select
                                    id='inputCountry'
                                    placeholder="Bitte auswählen"
                                    options={this.state.options}
                                    value={this.state.options.filter(option => option.value === this.state.country)}
                                    onChange={this.handleCountryChange}
                                />
                                <p className='text-danger m-0'>{this.state.countryError}&nbsp;</p>
                            </div>
                            {this.state.error ?
                                <Alert variant="danger">{this.state.error}</Alert>
                                : null
                            }
                            {this.state.success ?
                                <Alert variant="success">{this.state.success}</Alert>
                                : null
                            }
                            <div className='mb-4'></div>
                            <div onClick={this.handleDataSubmit}
                                 className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2">
                                {this.props.match.params.id === 'new' ?
                                    'Anlegen'
                                    :
                                    'Ändern'
                                }
                            </div>
                        </Form>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(AddressForm));
