import React, {Component} from "react";
import GoogleMapReact from 'google-map-react';
import Config from "../../config";
import {connect} from "react-redux";
import DSGVOAction from "../../stores/dsgvo/actions";

class Map extends Component {
    static defaultProps = {
        center: {
            lat: 48.247590,
            lng: 14.303290
        },
        searchLat: 0,
        zoom: 14,
        heightStyle: '80vh'
    };

    constructor(props) {
        super(props);

        this.handleMapChange = this.handleMapChange.bind(this)
        this.handleDSGVOClick = this.handleDSGVOClick.bind(this)
    }

    handleMapChange({center, zoom, bounds}) {
        if (typeof this.props.handleMapChange === "function") {
            this.props.handleMapChange({center, zoom, bounds})
        }
    }

    componentDidMount() {
    }

    handleDSGVOClick() {
        this.props.setCookieOverlayState(true)
    }

    render() {
        return (
            this.props.dsgvo.functionalCookies ?
                <div style={{height: this.props.heightStyle, width: '100%'}}>
                    <GoogleMapReact
                        key={parseInt(this.props.searchLat * 10000)}
                        bootstrapURLKeys={{
                            key: Config.GOOGLE_PLACES_API_KEY,
                            language: 'de',
                            region: 'at',
                        }}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        onChange={this.handleMapChange}
                        options={{
                            "styles": [
                                {
                                    "elementType": "labels.text.fill",
                                    "stylers": [
                                        {
                                            "color": "#383838"
                                        }
                                    ]
                                },
                                {
                                    "elementType": "labels.text.stroke",
                                    "stylers": [
                                        {
                                            "color": "#ebede8"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "administrative",
                                    "elementType": "geometry",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "landscape.man_made",
                                    "stylers": [
                                        {
                                            "color": "#ebede7"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "landscape.natural",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#d0dcaf"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "poi",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road",
                                    "elementType": "labels.icon",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#a2c800"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "road.highway",
                                    "elementType": "geometry.stroke",
                                    "stylers": [
                                        {
                                            "color": "#416500"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "transit",
                                    "stylers": [
                                        {
                                            "visibility": "off"
                                        }
                                    ]
                                },
                                {
                                    "featureType": "water",
                                    "elementType": "geometry.fill",
                                    "stylers": [
                                        {
                                            "color": "#cfeaff"
                                        }
                                    ]
                                }
                            ]
                        }}
                    >
                        {this.props.children}
                    </GoogleMapReact>
                </div>
                :
                <p onClick={this.handleDSGVOClick} className='text-center cursor-pointer'><b>Bitte erlauben Sie
                    funktionale Cookies um diesen Inhalt anzuzeigen</b></p>
        );
    }
}


const mapStateToProps = (state) => ({
    dsgvo: state.dsgvo
});
const mapDispatchToProps = (dispatch) => ({
    setCookieOverlayState: (setCookieOverlayState) => dispatch(DSGVOAction.setCookieOverlayState(setCookieOverlayState))
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Map);
