import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Col, Container, Form, Image, Nav, Navbar} from 'react-bootstrap';
import Icofont from "../Icofont/Icofont";
import Logo from "../../assets/img/logo.png";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import Basket from "../Basket/BasketDropdown";
import BasketAction from "../../stores/basket/actions";
import {AnimatePresence, motion} from 'framer-motion'
import BookmarkCollectionAction from "../../stores/bookmarkCollections/actions";
import CustomNavigation from "./CustomNavigation";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavExpanded: false,
            openCard: false,
            searchOpen: false,
            showMobile: false,
            searchTerm: ''
        };

        this.searchTimeout = null;

        this.toggleCard = this.toggleCard.bind(this)
        this.handleSearchTermChange = this.handleSearchTermChange.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.searchTerm !== prevState.searchTerm) {
            this.handleSearchTimeout();
        }
    }

    handleSearchTimeout = () => {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.handleSearch(), 1000);
    }

    handleSearchTermChange = e => {
        this.setState({
            searchTerm: e.target.value
        })
    }

    handleSearch() {
        this.setState({
            searchOpen: false
        })
        this.props.history.push('/marktplatz?search=' + this.state.searchTerm);
    }


    setIsNavExpanded = (isNavExpanded) => {
        this.setState({isNavExpanded: isNavExpanded});
    }

    closeMenu = () => {
        this.setState({isNavExpanded: false});
    }

    handleClick = (e) => {
        if (this.node.contains(e.target)) {
            // if clicked inside menu do something
        } else {
            // If clicked outside menu, close the navbar.
            this.setState({isNavExpanded: false});
        }
    }

    componentDidMount() {
        this.props.setBasketDoneLoading();
        document.addEventListener('click', this.handleClick, false);

    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, false);
        clearTimeout(this.searchTimeout);
    }

    toggleCard() {
        this.setState({
            openCard: !this.state.openCard,
            showMobile: false
        })
    }

    handleLogout() {
        this.props.logout();
        this.props.clearBasket();
        this.props.clearCollections();
        this.props.history.push('/');
    }


    render() {
        const handleSelect = (eventKey) => {
            this.closeMenu();
            if (eventKey === "logout") {

            }
        }
        return (
            <div ref={node => this.node = node}>
                <Navbar onToggle={this.setIsNavExpanded}
                        expanded={this.state.isNavExpanded} color="light" expand='lg'
                        className="navbar-light osahan-nav shadow-sm">
                    <Container>
                        <Navbar.Brand>
                            <Link to="/">
                                <Image
                                    src={Logo}
                                    className="img-fluid"
                                    style={{maxHeight: '60px'}}
                                />
                            </Link>
                        </Navbar.Brand>
                        <Navbar.Toggle onClick={() => this.setState({showMobile: !this.state.showMobile})}/>
                        {this.state.showMobile ?
                            <div className={"closeMenu"} onClick={() => this.setState({showMobile: false})}/>
                            :
                            null
                        }
                        <AnimatePresence exitBeforeEnter={true}>
                            {this.state.showMobile ?
                                <motion.div key={1}
                                            initial={{height: 0}}
                                            animate={{height: 'auto'}}
                                            exit={{height: 0}}
                                            className="mobile-nav"
                                            id="mobile-nav"
                                >
                                    <div className="headerForm d-flex mobile-header-search">
                                        <Form.Row className="align-items-center w-100 m-0 justify-content-end">
                                            <Col xs={12}>
                                                <div className="form-label-group m-0">
                                                    <Form.Control
                                                        id='searchInput'
                                                        type="text"
                                                        placeholder="Suche"
                                                        value={this.state.searchTerm}
                                                        onChange={this.handleSearchTermChange}
                                                        onKeyDown={event => {
                                                            if (event.key === 'Enter') {
                                                                this.handleSearch()
                                                            }
                                                        }}
                                                        size='sm'
                                                    />
                                                    <Form.Label htmlFor="searchInput">Suche</Form.Label>
                                                </div>

                                            </Col>
                                        </Form.Row>
                                    </div>
                                    <CustomNavigation
                                        handleSelect={this.closeMenu}
                                        onLinkClick={() => {
                                            this.setState({showMobile: false})
                                        }}
                                        toggleCard={this.toggleCard}
                                        searchToggle={() => {
                                            this.setState({searchOpen: !this.state.searchOpen})
                                        }}
                                    />
                                </motion.div>
                                :
                                null
                            }
                        </AnimatePresence>
                        <AnimatePresence exitBeforeEnter={true}>
                            {this.state.searchOpen && (
                                <motion.div key={1}
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            className="navbar-collapse collapse"
                                            id="navbarNavDropdown"
                                >
                                    <Nav className="ml-auto" onSelect={handleSelect}>
                                        <div className="headerForm d-flex">
                                            <Form.Row className="align-items-center w-100 m-0">
                                                <Col xs={11} lg={9}>
                                                    <div className="form-label-group m-0">
                                                        <Form.Control
                                                            id='searchInput'
                                                            type="text"
                                                            placeholder="Suche"
                                                            value={this.state.searchTerm}
                                                            onChange={this.handleSearchTermChange}
                                                            onKeyDown={event => {
                                                                if (event.key === 'Enter') {
                                                                    this.handleSearch()
                                                                }
                                                            }}
                                                            size='sm'
                                                        />
                                                        <Form.Label htmlFor="searchInput">Suche</Form.Label>
                                                    </div>

                                                </Col>
                                                <Col xs={1} lg={1}>
                                                    <Icofont
                                                        icon='close'
                                                        className='ml-2 cursor-pointer search-close'
                                                        onClick={() => {
                                                            this.setState({searchOpen: !this.state.searchOpen})
                                                        }}/>
                                                </Col>
                                            </Form.Row>
                                        </div>
                                    </Nav>
                                </motion.div>
                            )}
                            {!this.state.searchOpen && (
                                <motion.div key={2}
                                            initial={{opacity: 0}}
                                            animate={{opacity: 1}}
                                            exit={{opacity: 0}}
                                            className="navbar-collapse collapse"
                                            id="navbarNavDropdown"
                                >
                                    <CustomNavigation
                                        handleSelect={this.closeMenu}
                                        toggleCard={this.toggleCard}
                                        searchToggle={() => {
                                            this.setState({searchOpen: !this.state.searchOpen})
                                        }}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>

                    </Container>
                </Navbar>
                <Basket openCard={this.state.openCard} toggleCard={() => {
                    this.toggleCard()
                }}/>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    basketItems: state.basket.basketItems,
    memberID: state.user.memberID,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(UserActions.logout()),
    clearBasket: () => dispatch(BasketAction.clearBasket()),
    setBasketDoneLoading: () => dispatch(BasketAction.setBasketDoneLoading()),
    clearCollections: () => dispatch(BookmarkCollectionAction.clearCollections()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Header));

