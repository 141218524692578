import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Alert, Col, Container, Form, Row} from 'react-bootstrap';
import RegisterBackground from '../../assets/img/img_registration.jpg';
import {ApiService} from "../../services/ApiService";
import Button from "react-bootstrap/Button";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            firstNameError: '',
            surname: '',
            surnameError: '',
            email: '',
            emailError: '',
            password: '',
            passwordError: '',
            passwordRepeat: '',
            passwordRepeatError: '',
            loading: false,
            error: '',
            newsletter: true,
            page: 1,
        };

        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleSurnameChange = this.handleSurnameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePasswordRepeatChange = this.handlePasswordRepeatChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.thankYouContent = this.thankYouContent.bind(this);
        this.handleToLogin = this.handleToLogin.bind(this);
    };

    handleFirstNameChange(e) {
        let text = e.target.value;
        this.setState({firstName: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({firstNameError: 'Bitte gib einen gültigen Vornamen ein'});
        } else {
            this.setState({firstNameError: ''});
        }
    }

    handleSurnameChange(e) {
        let text = e.target.value;
        this.setState({surname: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({surnameError: 'Bitte gib einen gültigen Nachname ein!'});
        } else {
            this.setState({surnameError: ''});
        }
    }

    handleEmailChange(e) {
        let text = e.target.value;
        this.setState({email: text});
        let emailValidation = text.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        if (emailValidation === null) {
            this.setState({emailError: 'Bitte gib eine gültige E-Mail Adresse ein!'});
            ;
        } else {
            this.setState({emailError: ''});
        }
    }

    handlePasswordChange(e) {
        let text = e.target.value;
        this.setState({password: text});
        if (text.length < 8) {
            this.setState({passwordError: 'Das Passwort muss mindestens 8 Zeichen beinhalten!'});
        } else {
            this.setState({passwordError: ''});
        }
    }

    handlePasswordRepeatChange(e) {
        let text = e.target.value;
        this.setState({passwordRepeat: text});
        if (text !== this.state.password) {
            this.setState({passwordRepeatError: 'Die Passwörter stimmen nicht überein!'});
        } else {
            this.setState({passwordRepeatError: ''});
        }

    }

    handleSubmit() {
        if (
            this.state.firstNameError ||
            this.state.surnameError ||
            this.state.emailError ||
            this.state.passwordError ||
            this.state.passwordRepeatError
        ) {
            this.setState({error: 'Überprüfe deine Eingabe'});
            return;
        }
        if (
            this.state.firstName.length <= 0 ||
            this.state.surname.length <= 0 ||
            this.state.email.length <= 0 ||
            this.state.password.length <= 0 ||
            this.state.passwordRepeat.length <= 0
        ) {
            this.setState({error: 'Bitte fülle alle Felder aus!'});
            return;
        }

        const me = this;
        me.setState({loading: true});
        ApiService.client.post(
            '/Member',
            {
                'FirstName': me.state.firstName,
                'Surname': me.state.surname,
                'Email': me.state.email,
                'Password': me.state.password,
                'Newsletter': me.state.newsletter,
            }
        ).then(
            () => {
                me.setState({loading: false, error: '', page: 2});
            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                me.setState({loading: false, error: errorMsg, page: 1});
            },
        );
    }

    handleToLogin() {
        this.props.history.push('/login');
    }

    thankYouContent() {
        return (
            <Row>
                <Col md={12} className='text-center'>
                    <h1>Herzlich Willkommen!</h1>
                    <p className='my-5'>
                        Du kannst dich jetzt mit deinen Daten anmelden.
                    </p>
                    <Button
                        className='btn pl-1 pr-1 btn-lg text-white btn-block text-uppercase btn btn-primary'
                        onClick={this.handleToLogin} disabled={this.state.loading}>Jetzt Anmelden</Button>
                </Col>

            </Row>
        );
    }

    render() {
        return (
            <Container fluid className='bg-transparent'>
                <CustomMetaTags
                    title={'Regionlinemarkt - Registrierung'}
                />
                <Row>
                    <Col lg={6} className="d-none d-lg-flex bg-image"
                         style={{backgroundImage: `url(${RegisterBackground})`}}/>
                    <Col md={12} lg={6}>
                        <div className="login d-flex align-items-center py-5">
                            <Container>
                                <Row>
                                    <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
                                        {this.state.page > 1 ?
                                            this.thankYouContent()
                                            :
                                            <>
                                                <h3 className="login-heading mb-4">Freut uns, deine Bekanntschaft zu
                                                    machen!</h3>
                                                <Form>
                                                    <div className="form-label-group m-0">
                                                        <Form.Control
                                                            type="text"
                                                            id="inputFirstName"
                                                            placeholder="Dein Vorname"
                                                            onChange={this.handleFirstNameChange}
                                                            disabled={this.state.loading}
                                                            value={this.state.firstName}
                                                            className='input-foreground'
                                                        />
                                                        <Form.Label htmlFor="inputFirstName">Dein Vorname</Form.Label>
                                                        <p className='text-danger m-0'>{this.state.firstNameError}&nbsp;</p>
                                                    </div>
                                                    <div className="form-label-group m-0">
                                                        <Form.Control
                                                            type="text"
                                                            id="inputLastName"
                                                            placeholder="Dein Nachname"
                                                            onChange={this.handleSurnameChange}
                                                            disabled={this.state.loading}
                                                            value={this.state.surname}
                                                            className='input-foreground'
                                                        />
                                                        <Form.Label htmlFor="inputLastName">Dein Nachname</Form.Label>
                                                        <p className='text-danger m-0'>{this.state.surnameError}&nbsp;</p>
                                                    </div>
                                                    <div className="form-label-group m-0">
                                                        <Form.Control
                                                            type="email"
                                                            id="inputEmail"
                                                            placeholder="Deine E-Mail Adresse"
                                                            onChange={this.handleEmailChange}
                                                            disabled={this.state.loading}
                                                            value={this.state.email}
                                                            className='input-foreground'
                                                        />
                                                        <Form.Label htmlFor="inputEmail">Deine E-Mail
                                                            Adresse</Form.Label>
                                                        <p className='text-danger m-0'>{this.state.emailError}&nbsp;</p>
                                                    </div>
                                                    <div className="form-label-group m-0">
                                                        <Form.Control
                                                            type="password"
                                                            id="inputPassword"
                                                            placeholder="Passwort"
                                                            onChange={this.handlePasswordChange}
                                                            disabled={this.state.loading}
                                                            value={this.state.password}
                                                            className='input-foreground'
                                                        />
                                                        <Form.Label htmlFor="inputPassword">Passwort</Form.Label>
                                                        <p className='text-danger m-0'>{this.state.passwordError}&nbsp;</p>
                                                    </div>
                                                    <div className="form-label-group m-0">
                                                        <Form.Control
                                                            type="password"
                                                            id="inputPasswordRepeat"
                                                            placeholder="Passwort wiederholen"
                                                            onChange={this.handlePasswordRepeatChange}
                                                            disabled={this.state.loading}
                                                            value={this.state.passwordRepeat}
                                                            className='input-foreground'
                                                        />
                                                        <Form.Label htmlFor="inputPasswordRepeat">Passwort
                                                            wiederholen</Form.Label>
                                                        <p className='text-danger m-0'>{this.state.passwordRepeatError}&nbsp;</p>
                                                    </div>
                                                    <Form.Group controlId="formBasicCheckbox" className="mt-3">
                                                        <Form.Check type="checkbox"
                                                                    label="Newsletter abonnieren"
                                                                    checked={this.state.newsletter}
                                                                    onChange={() => {
                                                                        this.setState({
                                                                            newsletter: !this.state.newsletter
                                                                        });
                                                                    }}/>
                                                    </Form.Group>
                                                    {this.state.error ?
                                                        <Alert variant="danger">{this.state.error}</Alert>
                                                        : null
                                                    }
                                                    <div onClick={this.handleSubmit} disabled={this.state.loading}
                                                         className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2 mt-5">
                                                        Fertig
                                                    </div>
                                                    <div className="text-center pt-3">
                                                        Du warst schonmal bei uns? <Link className="font-weight-bold"
                                                                                         to="/login">Zum Login</Link>
                                                    </div>
                                                </Form>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}


export default withRouter(Register);
