import React, {Component} from 'react';
import {Alert, Button, Form, Spinner} from 'react-bootstrap'
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";

class ShippingRateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberID: 0,
            id: 0,
            title: '',
            rate: 0,
            titleError: '',
            rateError: '',
            loading: false,
            disableButton: false,
            error: '',
            success: '',
        };

        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleRateChange = this.handleRateChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    };

    componentDidMount() {
        this.props.fetchUser();
        this.setState({
            memberID: this.props.memberID,
            loading: true,
        });
        if (this.props.match.params.id !== 'new') {
            const id = this.props.match.params.id;
            ApiService.authorizedClient(this.props.authKey).get("/ShippingRate/" + id)
                .then((response) => {
                    this.setState({
                        title: response.data.Title,
                        rate: response.data.Rate,
                        error: '',
                        loading: false
                    });
                })
                .catch((error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                    this.setState({loading: false, error: ''});
                })
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    handleTitleChange(e) {
        let text = e.target.value;
        this.setState({title: text});
    }

    handleRateChange(e) {
        let text = e.target.value.replace(',', '.');
        this.setState({rate: text});
        if (isNaN(text)) {
            this.setState(
                {
                    rateError: 'Bitte gib einen gültigen Betrag ein!',
                    disableButton: true,
                }
            )
        } else {
            this.setState(
                {
                    rateError: '',
                    disableButton: false
                }
            )
        }
    }

    handleSubmit() {
        if (
            this.state.titleError ||
            this.state.rateError
        ) {
            this.setState({error: 'Überprüfe deine Eingabe'});
            return;
        }
        if (
            this.state.title.length <= 0 ||
            this.state.title.length <= 0
        ) {
            this.setState({error: 'Bitte fülle alle Felder aus!'});
            return;
        }
        const me = this;
        if (this.props.match.params.id === 'new') {
            ApiService.authorizedClient(this.props.authKey).post(
                '/ShippingRate',
                {
                    Title: me.state.title,
                    Rate: me.state.rate,
                    VendorID: me.props.user.VendorID,
                }
            ).then(
                () => {
                    toastr.success('Erfolgreich', 'Adresse wurde hinzugefügt')
                    this.props.history.push('/myaccount/shippingsettings');
                    me.setState({loading: false, error: '', success: ''});
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                    me.setState({loading: false, error: ''});
                },
            );
        } else {
            ApiService.authorizedClient(this.props.authKey).put(
                '/ShippingRate/' + this.props.match.params.id,
                {
                    Title: me.state.title,
                    Rate: me.state.rate,
                    VendorID: me.props.user.VendorID,
                }
            ).then(
                () => {
                    //me.setState({loading: false, error: '', success: 'Erfolgreich geändert'});
                    toastr.success('Erfolgreich', 'Versandoption wurde geändert')
                    this.props.history.push('/myaccount/shippingsettings');
                },
                (error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                },
            );
        }
    }


    render() {
        return (
            <div className={'p-4 bg-white shadow-sm ' + (this.state.loading ? 'h-100 d-flex' : '')}>
                {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-auto d-block align-self-center'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    <>
                        {this.props.match.params.id === 'new' ?
                            <h4 className='font-weight-bold mt-0 mb-4'>Neue Versandoption</h4>
                            :
                            <h4 className='font-weight-bold mt-0 mb-4'>Versandoption bearbeiten</h4>
                        }
                        <Form>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputTitle"
                                    placeholder="Titel"
                                    onChange={this.handleTitleChange}
                                    disabled={this.state.loading}
                                    value={this.state.title}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputTitle">Titel</Form.Label>
                                <p className='text-danger m-0'>{this.state.titleError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="nubmer"
                                    step={0.01}
                                    min={0}
                                    id="inputRate"
                                    placeholder="Bruttopreis (Euro)"
                                    onChange={this.handleRateChange}
                                    disabled={this.state.loading}
                                    value={this.state.rate}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputRate">Bruttopreis (Euro)</Form.Label>
                                <p className='text-danger m-0'>{this.state.rateError}&nbsp;</p>
                            </div>
                            <div className='mb-4'/>
                            {this.state.error ?
                                <Alert variant="danger">{this.state.error}</Alert>
                                : null
                            }
                            <Button onClick={this.handleSubmit} disabled={this.state.disableButton}
                                    className="btn btn-block text-uppercase btn-primary text-white">
                                {this.props.match.params.id === 'new' ?
                                    'Anlegen'
                                    :
                                    'Ändern'
                                }
                            </Button>
                        </Form>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ShippingRateForm));
