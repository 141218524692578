import React, {Component} from 'react';
import {Badge, Image} from "react-bootstrap";
import {Link} from "react-router-dom/";
import Icofont from "../Icofont/Icofont";
import {connect} from "react-redux";

class GoogleProductMarker extends Component {

    static defaultProps = {};

    state = {
        show: false,
        elementHeight: 0,
    }

    recalcHeight() {
        let elementHeight = this.ref.clientHeight;
        this.setState({
            elementHeight: elementHeight
        })
    }


    componentDidMount() {
        this.recalcHeight();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.show !== this.state.show) {
            this.recalcHeight();
        }
    }

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const deg2rad = (deg) => {
            return deg * (Math.PI / 180)
        }
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d.toFixed(1);
    }

    render() {
        if (!this.state.show) {
            return (
                <span className='customPin' onClick={() => this.setState({show: !this.state.show})}>
                    <span className='pin-fill'/>
                     <Icofont
                         ref={ref => this.ref = ref}
                         icon="location-pin"
                         className="map-pin"
                     />
                </span>
            );
        }

        return (
            <div className={'customMarker product-marker'}
                 ref={ref => this.ref = ref}
                 style={{top: -(this.state.elementHeight + 20)}}
                 onClick={() => this.setState({show: !this.state.show})}
            >
                <Icofont
                    icon='close-line'
                    className='closeMapPin'
                />
                {this.state.show ?
                    <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                        <div className='h-100 d-flex flex-column'>
                            <div className="list-card-image">
                                <Link to={this.props.linkTo}>
                                    <Image src={this.props.image}
                                           className={'product-card-img ' + this.props.imageClass}
                                           alt={this.props.imageAlt}/>
                                </Link>
                            </div>
                            <div className="p-3 position-relative d-flex flex-column justify-content-end flex-grow-1">
                                <div className="list-card-body">
                                    <h6 className="mb-1">
                                        <Link
                                            to={this.props.linkTo}
                                            className="text-black">
                                            {this.props.title}
                                        </Link>
                                    </h6>
                                    {this.props.desc ?
                                        <p className="text-gray mb-3">{this.props.desc}</p>
                                        :
                                        null
                                    }

                                    <p className="text-gray time mb-0">
                                        {this.props.coords.latitude ?
                                            <span className="bg-light rounded-sm pb-1 pt-1 pr-2">
                                        <Icofont icon='map-pins'/>&nbsp;
                                                {
                                                    this.getDistanceFromLatLonInKm(
                                                        this.props.lat,
                                                        this.props.lng,
                                                        this.props.coords.latitude,
                                                        this.props.coords.longitude,
                                                    )
                                                } km
                                    </span>
                                            :
                                            null
                                        }
                                        {this.props.price ?
                                            <span className="float-right text-dark">€{this.props.price}</span>
                                            : null
                                        }
                                    </p>
                                </div>
                                {this.props.offerText ? (
                                        <div className="list-card-badge mt-3">
                                            <Badge variant={this.props.offerColor}>ANGEBOT</Badge>
                                            <small>{this.props.offerText}</small>
                                        </div>
                                    )
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
});


export default connect(
    mapStateToProps,
)(GoogleProductMarker);
