import React from 'react';
import {NavLink, Route, Switch} from 'react-router-dom';
import {ButtonGroup, Col, Container, FormControl, Row} from 'react-bootstrap';
import Icofont from "../../components/Icofont/Icofont";
import PageTitle from '../../components/PageTitle/PageTitle';
import MarketplaceBackground from '../../assets/img/header_marktplatz.jpg';
import Map from "./Map";
import Grid from "./Grid";
import {connect} from "react-redux";
import LocationField from "../../components/LocationField/LocationField";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";
import InfoBadge from "../../components/InfoBadge/InfoBadge";

class VendorOverview extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedCategories: [],
            categories: [],
            editLocation: false,
            searchTitle: '',
            vendorCount: 0,
            address: '',
            geolocationAvailable: true,
            ready: false,
            sort: 'Distance ASC',
            sortTitle: 'in der Nähe',
            metaTitle: '',
            metaDesc: '',
        }

        this.setVendorCountCallback = this.setVendorCountCallback.bind(this)
        this.handleSearchTitleChange = this.handleSearchTitleChange.bind(this);
    }

    updateGeoPosition(coords) {
        if (this.props.coords.geocodeAddress) {
            this.setState({address: this.props.coords.geocodeAddress.formatted_address ?? ''},
                () => this.setState({
                    geolocationAvailable: false
                }))
        }

    }

    setVendorCountCallback(count) {
        this.setState({
            vendorCount: count
        })
    }

    handleSearchTitleChange(e) {
        let text = e.target.value;
        this.setState({searchTitle: text});
    }


    componentDidMount() {
        ApiService.client.get('/SiteConfig/1')
            .then((response) => {
                this.setState({
                    metaTitle: response.data.VendorMetaTitle ?? 'Regionlinemarkt - Händler',
                    metaDesc: response.data.VendorMetaDescription ?? 'Händler aus der Nähe',
                });
            })
            .catch((error) => {
                console.log(error);
            })
        ApiService.client.get('/Category/?filter[ParentID]=0')
            .then((result) => {
                this.setState({
                    categories: result.data,
                })
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten'
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            this.setState({
                    loading: false
                }
            );
            toastr.error('Fehler beim laden der Adresse', msg)
        })
        if (this.props.coords) {
            this.updateGeoPosition();
        }
        if ("geolocation" in navigator) {
            this.setState({geolocationAvailable: true})
        } else {
            this.setState({geolocationAvailable: false})
        }

        this.setState({
                ready: true
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (typeof prevProps.coords.geocodeAddress !== 'undefined' && JSON.stringify(prevProps.coords.geocodeAddress) !== JSON.stringify(this.props.coords.geocodeAddress)) {
            this.updateGeoPosition();
        }
    }

    render() {
        return (
            <>
                {this.state.metaTitle.length ?
                    <CustomMetaTags
                        title={this.state.metaTitle}
                        desc={this.state.metaDesc}
                    />
                    :
                    null
                }
                <PageTitle
                    title="Händler in deiner Nähe"
                    subTitle="Finde all deine regionalen Händler"
                    green={true}
                    image={MarketplaceBackground}
                />
                <section className="section pt-5 pb-5 products-listing">
                    <Container fluid>
                        <Row className="mb-3">
                            <Col xl={7} lg={6}>
                                <h4 className="font-weight-bold mt-0 mb-3">
                                    Händlersuche
                                    <small className="h6 mb-0 ml-2">{this.state.vendorCount} Händler in der Nähe
                                        von &nbsp;
                                        <span className="d-inline-block">
                                                <span
                                                    className={'d-flex align-items-center ' + (this.state.editLocation ? 'inline-location-container-info' : '')}>
                                                    {this.state.editLocation ?
                                                        <div style={{
                                                            display: "inline-block",
                                                            width: '85%'
                                                        }}>
                                                            <div
                                                                className="form-row justify-content-start custom-location-field">
                                                                <LocationField className='col-12 mb-0'/>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='d-inline-block inlineLocationField'>
                                                            {this.state.address ?? ''} <Icofont icon='ui-edit'
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        editLocation: !this.state.editLocation
                                                                                                    })
                                                                                                }
                                                                                                }
                                                                                                className='text-muted ml-2 cursor-pointer'/>
                                                        </div>

                                                    }
                                                    <InfoBadge type={'AddressInfoText'}/>
                                                </span>
                                        </span>
                                    </small>
                                </h4>
                            </Col>
                            <Col xl={5} lg={6} className="d-md-flex align-items-center justify-content-lg-end">
                                <ButtonGroup className="mr-2">
                                    <NavLink
                                        to={`${this.props.match.url}`}
                                        exact
                                        activeClassName="active"
                                        className="btn btn-outline-primary"
                                    >
                                        <Icofont icon="listing-box"/> Liste
                                    </NavLink>
                                    <NavLink
                                        to={`${this.props.match.url}/map`}
                                        activeClassName="active"
                                        className="btn btn-outline-primary"
                                    >
                                        <Icofont icon="map"/> Karte
                                    </NavLink>
                                </ButtonGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3} lg={4} className='mb-3 mb-xl-0 mb-lg-0'>
                                <div className="filters shadow-sm rounded bg-white">
                                    <div className="filters-header border-bottom pl-4 pr-4 pt-3 pb-3">
                                        <h5 className="m-0">Suche</h5>
                                    </div>
                                    <div className="filters-body">
                                        <div className="filters-card border-bottom p-4">
                                            <FormControl
                                                placeholder="Suchbegriff eingeben..."
                                                value={this.state.searchTitle}
                                                onChange={this.handleSearchTitleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xl={9} lg={8}>
                                {this.state.ready ?
                                    <Switch>
                                        <Route path={`${this.props.match.path}/map`}
                                               render={
                                                   (props) => (
                                                       <Map {...props}
                                                            title={this.state.searchTitle}
                                                            setCountFunction={this.setVendorCountCallback}/>
                                                   )
                                               }
                                        />
                                        <Route path={`${this.props.match.path}`}
                                               render={
                                                   (props) => (
                                                       <Grid {...props}
                                                             title={this.state.searchTitle}
                                                             lat={this.props.coords.latitude ?? 48.247590}
                                                             lng={this.props.coords.longitude ?? 14.303290}
                                                             sort={this.state.sort}
                                                             setCountFunction={this.setVendorCountCallback}
                                                       />
                                                   )
                                               }
                                        />
                                    </Switch>
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                    </Container>

                </section>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
});


export default connect(
    mapStateToProps,
)(VendorOverview);
