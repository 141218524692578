import React from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {ApiService} from "../../services/ApiService";
import {toastr} from 'react-redux-toastr'

class DeleteAddressModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            authKey: false,
            id: '',
            error: '',
            success: ''
        };

        this.deleteAddress = this.deleteAddress.bind(this);
    };

    deleteAddress() {
        const me = this;
        me.setState({loading: true});
        ApiService.authorizedClient(me.props.authKey).put(
            '/Address/' + me.props.addressID,
            {
                'MemberID': 0
            }
        ).then(
            () => {
                toastr.success('Adresse wurde gelöscht', me.props.addressTitle)
                this.props.onHide();
                if (typeof this.props.onAddressDelete !== "undefined") {
                    this.props.onAddressDelete();
                }
            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error('Hoppla', errorMsg)
            },
        );
    }


    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onHide}
                centered
                size="sm"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title as='h5' id="delete-address">Löschen</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="mb-0 text-black">Möchtes du diese Adresse löschen?</p>
                    <p className="mb-0 text-muted">"{this.props.addressTitle}"</p>
                </Modal.Body>

                <Modal.Footer>
                    <Row>
                        <Col md={6}>
                            <Button type='button' onClick={this.props.onHide} variant="outline-primary"
                                    className="d-flex text-center justify-content-center">ABBRECHEN</Button>
                        </Col>
                        <Col md={6}>
                            <Button
                                type='button'
                                variant="primary"
                                className='d-flex text-center justify-content-center'
                                onClick={() =>
                                    this.deleteAddress()
                                }
                            >LÖSCHEN</Button>
                        </Col>
                    </Row>

                </Modal.Footer>
            </Modal>
        );
    }
}

export default DeleteAddressModal;