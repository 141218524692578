import React, {Component} from 'react';
import {connect} from "react-redux";
import DSGVOAction from "../../stores/dsgvo/actions";
import {Link, withRouter} from "react-router-dom";
import {Toggle} from "rsuite";
import {Button} from "react-bootstrap";


class DSGVOOverlay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            performanceCookies: this.props.dsgvo.performanceCookies,
            functionalCookies: this.props.dsgvo.functionalCookies,
            targetingCookies: this.props.dsgvo.targetingCookies,
        }

        this.handlePerformanceCookies = this.handlePerformanceCookies.bind(this);
        this.handleFunctionalCookies = this.handleFunctionalCookies.bind(this);
        this.handleTargetingCookies = this.handleTargetingCookies.bind(this);
        this.handleDSGVOSave = this.handleDSGVOSave.bind(this);
        this.handleDSGVOAllowAll = this.handleDSGVOAllowAll.bind(this);
    }

    handlePerformanceCookies(checked) {
        this.setState({
            performanceCookies: checked
        })
    }

    handleFunctionalCookies(checked) {
        this.setState({
            functionalCookies: checked
        })
    }

    handleTargetingCookies(checked) {
        this.setState({
            targetingCookies: checked
        })
    }

    handleDSGVOSave() {
        this.props.updateCookies(
            this.state.performanceCookies,
            this.state.functionalCookies,
            this.state.targetingCookies,
        )
        this.props.setCookieOverlayState(false)
    }


    handleDSGVOAllowAll() {
        this.setState({
            performanceCookies: true,
            functionalCookies: true,
            targetingCookies: true,
        })
        this.props.updateCookies(
            true,
            true,
            true,
        )
        this.props.setCookieOverlayState(false)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        return (
            this.props.dsgvo.showCookieOverlay && (this.props.location.pathname !== '/content/datenschutzerklärung' && this.props.location.pathname !== '/content/impressum') ?
                <>
                    <div className='dsgvo-widget__popup-bg'/>
                    <div className='dsgvo-widget__popup'>
                        <span className="dsgvo-widget__popup-welcome">
                            Cookie-Einstellungen
                        </span>
                        <div className='dsgvo-widget__popup-main'>
                            <div className="dsgvo-widget__popup-text">
                                Wir verwenden Cookies, um Ihnen ein optimales Webseiten-Erlebnis zu bieten. Dazu zählen
                                Cookies, die für den Betrieb der Seite und für die Steuerung unserer kommerziellen
                                Unternehmensziele notwendig sind, sowie solche, die lediglich zu anonymen
                                Statistikzwecken, für Komforteinstellungen oder zur Anzeige personalisierter Inhalte
                                genutzt werden. Sie können selbst entscheiden, welche Kategorien Sie zulassen möchten.
                                Bitte beachten Sie, dass auf Basis Ihrer Einstellungen womöglich nicht mehr alle
                                Funktionalitäten der Seite zur Verfügung stehen.

                                <br/>
                                <br/><Link to="/content/datenschutzerklärung">Weitere Informationen finden Sie unter
                                »Datenschutz«</Link>
                                <br/><Link to="/content/impressum">Impressum</Link>

                            </div>
                            <div className='dsgvo-widget__popup-categories'>
                                <div className='dsgvo-widget__popup-categories-item'>
                                    <div className='dsgvo-widget__popup-categories-item-name'>
                                        <strong>Essenzielle Cookies</strong>
                                        <div className="dsgvo-widget__popup-categories-item-count">
                                            - Session cookies
                                            <br/>- Login cookies
                                            <br/>
                                        </div>
                                    </div>
                                    <div className='dsgvo-widget__popup-categories-item-toggle'>
                                        <Toggle disabled={true} defaultChecked={true}/>
                                    </div>
                                </div>
                                <div className='dsgvo-widget__popup-categories-item'>
                                    <div className='dsgvo-widget__popup-categories-item-name'>
                                        <strong>Performance Cookies</strong>
                                        <div className="dsgvo-widget__popup-categories-item-count">
                                            - Google Analytics
                                        </div>
                                    </div>
                                    <div className='dsgvo-widget__popup-categories-item-toggle'>
                                        <Toggle disabled={false} checked={this.state.performanceCookies}
                                                onChange={this.handlePerformanceCookies}/>
                                    </div>
                                </div>
                                <div className='dsgvo-widget__popup-categories-item'>
                                    <div className='dsgvo-widget__popup-categories-item-name'>
                                        <strong>Funktionelle Cookies</strong>
                                        <div className="dsgvo-widget__popup-categories-item-count">
                                            - Google Maps
                                        </div>
                                    </div>
                                    <div className='dsgvo-widget__popup-categories-item-toggle'>
                                        <Toggle disabled={false} checked={this.state.functionalCookies}
                                                onChange={this.handleFunctionalCookies}/>
                                    </div>
                                </div>
                                <div className='dsgvo-widget__popup-categories-item'>
                                    <div className='dsgvo-widget__popup-categories-item-name'>
                                        <strong>Targeting Cookies</strong>
                                        <div className="dsgvo-widget__popup-categories-item-count">
                                            - Facebook Pixel
                                        </div>
                                    </div>
                                    <div className='dsgvo-widget__popup-categories-item-toggle'>
                                        <Toggle disabled={false} checked={this.state.targetingCookies}
                                                onChange={this.handleTargetingCookies}/>
                                    </div>
                                </div>
                                <div className="dsgvo-widget__popup-footer w-100 d-md-flex justify-content-between">
                                    <Button variant='secondary' onClick={this.handleDSGVOSave} className='mt-2 mt-md-0'>
                                        Einstellungen speichern
                                    </Button>
                                    <Button variant='primary' onClick={this.handleDSGVOAllowAll}
                                            className='mt-2 mt-md-0'>
                                        Alle Akzeptieren
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : null

        )
    }
}

const mapStateToProps = (state) => ({
    dsgvo: state.dsgvo
});

const mapDispatchToProps = (dispatch) => ({
    updateCookies: (performanceCookies, functionalCookies, targetingCookies) => dispatch(DSGVOAction.updateCookies(performanceCookies, functionalCookies, targetingCookies)),
    setCookieOverlayState: (setCookieOverlayState) => dispatch(DSGVOAction.setCookieOverlayState(setCookieOverlayState))
});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(DSGVOOverlay));
