import React from 'react';
import PropTypes from 'prop-types';

class PageTitle extends React.Component {

    render() {
        return (
            <>
                <section className="breadcrumb-osahan pt-5 pb-5 bg-white bg-image position-relative text-center"
                         style={this.props.image ? {backgroundImage: `url(${this.props.image})`} : undefined}>
                    <div className={(this.props.green ? 'header-bg-green' : 'header-bg') + ' mb-2'}>
                        <h1 className={'mb-1 font-weight-bold ' + (this.props.green ? 'text-primaryNeon-green' : 'text-white') + ' text-shadow'}>{this.props.title}</h1>
                        {this.props.subTitle ?
                            <h6 className={'font-weight-normal  text-primaryNew'}>{this.props.subTitle}</h6>
                            : ""
                        }
                    </div>
                </section>
                {this.props.bannerText ?
                    <div className="header-banner">{this.props.bannerText}</div>
                    :
                    null
                }
            </>
        );
    }
}

PageTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    image: PropTypes.string,
};

PageTitle.defaultProps = {
    subTitle: ''
}

export default PageTitle;
