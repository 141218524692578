import React, {Component} from 'react';
import './InfoBadge.style.scss'
import Icofont from "../Icofont/Icofont";
import {Tooltip, Whisper} from "rsuite";
import {HTMLService} from "../../services/HTMLService";
import {ApiService} from "../../services/ApiService";


class InfoBadge extends Component {
    constructor(props) {
        super(props);

        this.state = {
            text: ''
        }
    }

    componentDidMount() {
        if (this.props.type) {
            ApiService.client.get('/SiteConfig/1')
                .then((response) => {
                    if (typeof response.data[this.props.type] !== 'undefined') {
                        this.setState({
                            text: response.data[this.props.type] ?? '',
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }

    render() {
        const tooltip = (
            <Tooltip className="custom-tooltip">
                <div dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.text)}}/>
            </Tooltip>
        );
        return (
            this.state.text ?
                <div className="info-tooltip d-inline-block">
                    <Whisper placement="autoVertical" trigger="click" speaker={tooltip}>
                        <Icofont icon="info-circle" className="ml-2 mr-2 cursor-pointer"/>
                    </Whisper>
                </div>
                : null
        )
    }
}


export default (InfoBadge);
