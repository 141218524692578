import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import Icofont from "../Icofont/Icofont";
import {Link} from "react-router-dom";
import LazyImage from "../LazyImage/LazyImage";
import {ApiService} from "../../services/ApiService";
import {connect} from "react-redux";
import DefaultImage from "../../assets/img/default_img.png";

class VendorCard extends Component {

    state = {
        img: '',
        address: {}
    }

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const deg2rad = (deg) => {
            return deg * (Math.PI / 180)
        }
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d.toFixed(1);
    }

    componentDidMount() {
        if (this.props.vendor.LogoID) {
            ApiService.client.get('/Image/' + this.props.vendor.LogoID + '/FitMax/100/100/')
                .then((result) => {
                    this.setState({
                        img: result.data.AbsoluteURL
                    })
                })
                .catch((error) => {
                    this.setState({
                        img: DefaultImage
                    })
                })
        }
        if (this.props.vendor.AddressID) {
            ApiService.client.get('/Address/' + this.props.vendor.AddressID)
                .then((result) => {
                    this.setState({
                        address: result.data
                    })
                }).catch((error) => {
            })
        }
    }

    render() {
        return (
            <div className="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm vendor-card">
                <div className="p-3 position-relative h-100">
                    <div className="list-card-body h-100 d-flex flex-column ">
                        <div className='vendor-card-header d-flex align-items-center'>
                            <Row className="w-100">
                                <Col xs={12}>
                                    {this.props.vendor.PreviewImage ?
                                        <LazyImage src={this.props.vendor.PreviewImage}
                                                   className='mx-auto d-block vendor-new-img' fluid/>
                                        :
                                        <LazyImage src={this.props.vendor.PreviewImage ?? this.state.img}
                                                   className='mx-auto d-block' fluid/>
                                    }
                                </Col>
                                <Col xs={12} className='mt-3'>
                                    <h5>
                                        <Link to={`/händler/${this.props.vendor.ID}`}
                                              className="text-black">{this.props.vendor.Title}
                                        </Link>
                                    </h5>
                                </Col>
                            </Row>
                        </div>
                        <div className='d-flex flex-column justify-content-between flex-grow-1'>
                            <div className='vendor-card-desc mt-3 mb-3'>
                                <p>{this.props.vendor.ShortDescription}</p>
                            </div>
                            <div className="text-gray time mb-0">
                                <Row className="align-items-center">
                                    <Col xs={4}>
                                        {this.props.coords.latitude ?
                                            <span className="bg-light rounded-sm pb-1 pt-1 pr-2">
                                                <small>
                                                     <Icofont icon='map-pins'/>&nbsp;
                                                    {this.props.vendor.Distance ?
                                                        this.props.vendor.Distance
                                                        :
                                                        this.getDistanceFromLatLonInKm(
                                                            this.props.vendor.Latitude,
                                                            this.props.vendor.Longitude,
                                                            this.props.coords.latitude,
                                                            this.props.coords.longitude,
                                                        )
                                                    } km
                                                </small>

                                    </span>
                                            :
                                            null
                                        }
                                    </Col>
                                    <Col xs={8}>
                                        {this.props.vendor.Address ?
                                            <small>
                                                <span className="float-right text-dark">
                                                    {this.props.vendor.Address ?? ''}
                                                </span>
                                            </small>
                                            :
                                            <>
                                                {this.state.address ?
                                                    <small>
                                                        <span  className="float-right text-dark">
                                                            {this.state.address.Title ?? ''}
                                                        </span>
                                                    </small>
                                                    :
                                                    null
                                                }
                                            </>
                                        }
                                    </Col>
                                </Row>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
});


export default connect(
    mapStateToProps,
)(VendorCard);
