import React from 'react';
import {Link} from 'react-router-dom';
import {Badge, Button, Card, Media} from 'react-bootstrap';
import Icofont from "../Icofont/Icofont";
import PropTypes from 'prop-types';
import LazyImage from "../LazyImage/LazyImage";
import {ApiService} from "../../services/ApiService";

class ProductCard extends React.Component {

    state = {
        imgUrl: ''
    }

    componentDidMount() {
        if (this.props.img !== 0) {
            ApiService.client.get("/Image/" + this.props.img + '/FitMax/200/100')
                .then((response) => {
                    this.setState({
                        imgUrl: response.data.AbsoluteURL,
                    });
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    render() {
        return (
            <Card className={"bg-white addresses-item h-100 d-flex align-item-center " + (this.props.boxClass)}>
                <div className="gold-members p-4">
                    <Media>
                        <div className="mr-3">
                            <LazyImage src={this.props.imgUrl ?? ''}/>
                        </div>
                        <div className="media-body">
                            {this.props.showPromoted ? (
                                    <div className="member-plan position-absolute" style={{right: 15, top: 15}}>
                                        <Badge variant='primary' className='p-1'>TOP</Badge>
                                    </div>
                                )
                                : ""
                            }
                            {this.props.showSale ?
                                <div className="member-plan position-absolute" style={{right: 15, top: 35}}>
                                    <Badge variant='danger' classsName='p-1'>SALE %</Badge>
                                </div>
                                : ""
                            }
                            <h6 className="mb-1 text-secondary">{this.props.title}</h6>
                            <p className="text-black">{this.props.price.toFixed(2)} €
                            </p>
                            <p className="mb-0 text-black font-weight-bold">
                                <Button as={Link} variant='link' className="text-primary mr-3"
                                        to={"/myaccount/products/" + this.props.id}><Icofont
                                    icon="ui-edit"/> BEARBEITEN</Button>
                                <Button variant="link" className="text-danger" to="#"
                                        onClick={this.props.onDeleteClick}><Icofont
                                    icon="ui-delete"/> LÖSCHEN</Button></p>
                        </div>
                    </Media>
                </div>
            </Card>
        );
    }
}

ProductCard.propTypes = {
    title: PropTypes.string.isRequired,
    icoIcon: PropTypes.string.isRequired,
    iconclassName: PropTypes.string,
    address: PropTypes.string,
    onDeleteClick: PropTypes.func.isRequired
};

export default ProductCard;
