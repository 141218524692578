import React, {Component} from "react";
import BasketAction from "../../stores/basket/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Col, Row, Spinner} from "react-bootstrap";
import LazyImage from "../LazyImage/LazyImage";
import DefaultImage from "../../assets/img/default_img.png";

class CheckoutBasket extends Component {

    constructor(props) {
        super(props);

        this.state = {
            vendorsIds: [],
            vendors: [],
            basketItems: [],
            height: 0,
            total: 0
        }
    }

    render() {
        return (
            <>
                <div className='basket-content'>
                    {typeof this.props.list === 'undefined' || this.props.list.length <= 0 ?
                        <p className='p-5 text-center'>Du hast keine Produkte im Warenkorb</p>
                        :
                        this.props.basketLoading ?
                            <Spinner animation="border" role="status"
                                     className='mx-auto d-block align-self-center'>
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                            :
                            this.props.list.map((vendor, index) => {
                                return (
                                    <div key={vendor.ID} className='pb-2 mb-2 border-bottom border-width-2'>
                                        <div className="py-2 px-3">
                                            <h6 className="mb-0">{vendor.Title}</h6>
                                        </div>
                                        {vendor.Items.map((item, index) => {
                                            return (
                                                <div key={index} className='py-2 px-3 border-top'>
                                                    <Row>
                                                        <Col xs={2}>
                                                            {item.Product.PreviewImage ?
                                                                <LazyImage
                                                                    src={item.Product.PreviewImage}
                                                                    fluid
                                                                    className='checkout-product-img'
                                                                />
                                                                :
                                                                <LazyImage
                                                                    src={DefaultImage}
                                                                    fluid
                                                                    className='checkout-product-img'
                                                                />
                                                            }

                                                        </Col>
                                                        <Col xs={4} className='d-flex align-items-center'>
                                                            <b>{item.Product.Title}</b>
                                                        </Col>
                                                        <Col xs={3} className='d-flex align-items-center'>
                                                            <span className='w-100'>Menge: <b>{item.Quantity}</b></span>
                                                        </Col>
                                                        <Col xs={3} className='d-flex align-items-center text-right'>
                                                            <b className='w-100'>€ {(item.Product.Price * item.Quantity).toFixed(2)}</b>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            )
                                        })}

                                        {vendor.Shipping.length ?
                                            <>
                                                <div
                                                    key={index}
                                                    className="dropdown-cart-top-body border-top py-1 pt-3 px-3">
                                                    <Row>
                                                        <Col xs={8}>
                                                            <b>{vendor.Title} - Versandkosten:</b>
                                                        </Col>
                                                    </Row>

                                                </div>
                                                {vendor.Shipping.map(
                                                    (item, index) =>
                                                        (item.ID !== 0 && typeof item.Rate !== 'undefined') ?
                                                            <div key={index} className="py-1 px-3 ">
                                                                <Row>
                                                                    <Col xs={8} className='d-flex align-items-center'>
                                                                        {item.Title}
                                                                    </Col>
                                                                    <Col xs={4}
                                                                         className='d-flex align-items-center text-right'>
                                                                        <b className='w-100'>€ {item.Rate.toFixed(2)}</b>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            :
                                                            null
                                                )
                                                }
                                            </>
                                            :
                                            null
                                        }
                                    </div>

                                )
                            })}
                </div>

                <div className="p-2 text-right">
                    <div className="mb-3">
                        <h4 className="mb-0">Gesamt:
                            € {typeof this.props.total !== 'undefined' ? this.props.total.toFixed(2) : '--'}</h4>
                        <small className="text-black">inkl. MwSt</small>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    list: state.basket.list,
    total: state.basket.total,
    basketLoading: state.basket.basketLoading,
});

const mapDispatchToProps = (dispatch) => ({
    fetchBasket: () => dispatch(BasketAction.fetchBasket())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(CheckoutBasket));
