import React, {Component} from 'react';
import {Alert, Button, Form, FormGroup, Spinner} from 'react-bootstrap'
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {Cascader} from 'rsuite';
import UploadField from "../UploadField/UploadField";

class ProductForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberID: 0,
            id: 0,
            title: '',
            titleError: '',
            description: '',
            descriptionError: '',
            price: 0,
            priceError: '',
            refPrice: 0,
            refPriceError: '',
            promoted: 0,
            sale: 0,
            categories: [],
            category: 0,
            categoryError: '',
            availableShippingRates: [],
            shippingRates: [],
            shippingRatesError: '',
            availableTaxRates: [],
            taxRate: 0,
            taxRateError: '',
            mainImg: {},
            images: [],
            imagesError: '',
            loading: false,
            error: '',
            disableButton: false,
            requestOnly: 0,
            saleProductPrice: 0,
            topProductPrice: 0,
            saleProductDuration: '',
            topProductDuration: '',
            success: '',
        };

        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleRefPriceChange = this.handleRefPriceChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleShippingRatesChange = this.handleShippingRatesChange.bind(this);
        this.handleImagesChange = this.handleImagesChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.mapChildren = this.mapChildren.bind(this);
        this.handleImageStatusChange = this.handleImageStatusChange.bind(this);
        this.handleImagesDelete = this.handleImagesDelete.bind(this);
        this.submitUpdate = this.submitUpdate.bind(this);
        this.submitProduct = this.submitProduct.bind(this);
        this.submitShipping = this.submitShipping.bind(this);
        this.submitImages = this.submitImages.bind(this);
        this.handleTaxRateChange = this.handleTaxRateChange.bind(this);
        this.handleMainImgStatusChange = this.handleMainImgStatusChange.bind(this);
        this.handleMainImgDelete = this.handleMainImgDelete.bind(this);
    }

    mapChildren(item, index) {
        let data = {
            value: item.ID,
            label: item.Title,
        }
        if (typeof item.Children !== 'undefined') {
            data.children = item.Children.map(
                this.mapChildren
            )
        }
        return data;
    }

    componentDidMount() {
        const me = this;
        this.setState({
            loading: true,
        });
        ApiService.client.get('Category/tree')
            .then((response) => {
                this.setState({
                    categories: response.data.map(me.mapChildren)
                })

            }).catch((error) => {
            let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
            if (error.response && error.response.data && error.response.data.message) {
                errorMsg = error.response.data.message
            }
            toastr.error('Hoppla', errorMsg)
        })
        ApiService.client.get('/SiteConfig/1')
            .then((response) => {
                this.setState({
                    saleProductPrice: response.data.SaleProductPrice ?? 0,
                    saleProductDuration: response.data.SaleProductDuration ?? '',
                    topProductPrice: response.data.TopProductPrice ?? 0,
                    topProductDuration: response.data.TopProductDuration ?? '0'
                })

            })
            .catch((error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                toastr.error('Hoppla', errorMsg)
            })
        ApiService.client.get("/ShippingRate/?filter[VendorID]=" + this.props.user.VendorID)
            .then((response) => {
                this.setState({
                    availableShippingRates: response.data,
                });
            })
            .catch((error) => {

                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({error: msg});
                toastr.error('Hoppla', msg);
            })
        ApiService.client.get("/TaxRate/")
            .then((response) => {
                let taxRates = response.data;
                ApiService.client.get('/Vendor/' + this.props.user.VendorID)
                    .then((response) => {
                        if (response.data.UID) {
                            taxRates = taxRates.filter((item) => item.Rate !== 0)
                        } else {
                            taxRates = taxRates.filter((item) => item.Rate === 0)
                        }
                        this.setState({
                            availableTaxRates: taxRates,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            availableTaxRates: taxRates,
                        });
                    })

            })
            .catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({error: msg, loading: false});
                toastr.error('Hoppla', msg);
            })
        if (this.props.match.params.id !== 'new') {
            const id = this.props.match.params.id;
            ApiService.authorizedClient(this.props.authKey).get("/Product/" + id)
                .then((response) => {
                    this.setState({
                        title: response.data.Title,
                        description: response.data.Description,
                        price: response.data.Price,
                        category: response.data.CategoryID,
                        requestOnly: response.data.RequestOnly,
                        refPrice: response.data.RefPrice,
                        promoted: response.data.Promoted,
                        sale: response.data.Sale,
                        taxRate: response.data.TaxRateID,
                        error: '',
                        loading: false
                    });
                    if (response.data.MainImageID) {
                        ApiService.authorizedClient(this.props.authKey).get('/Image/' + response.data.MainImageID)
                            .then((response) => {
                                this.setState({
                                    mainImg: response.data
                                });
                            })
                            .catch((error) => {

                            })
                    }

                })
                .catch((error) => {
                    let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                    if (error.response && error.response.data && error.response.data.message) {
                        errorMsg = error.response.data.message
                    }
                    toastr.error('Hoppla', errorMsg)
                    this.setState({loading: false, error: ''});
                })
            ApiService.client.get('/Product/' + this.props.match.params.id + '/many/Images/')
                .then((response) => {
                    this.setState({
                        images: response.data
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten'});
                    }
                })
            ApiService.client.get('/Product/' + this.props.match.params.id + '/many/ShippingRates/')
                .then((response) => {
                    this.setState({
                        shippingRates: response.data
                    });
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message, loading: false});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                    }
                })
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.state.taxRate && this.state.availableTaxRates.length) {
            this.setState({
                taxRate: this.state.availableTaxRates[0].ID
            })
        }
    }

    handleTitleChange(e) {
        let text = e.target.value;
        this.setState({title: text});
    }

    handleDescriptionChange(e) {
        let text = e.target.value;
        this.setState({description: text});
    }

    handlePriceChange(e) {
        let text = e.target.value.replace(',', '.');
        ;
        this.setState({price: text});
        if (isNaN(text)) {
            this.setState(
                {
                    priceError: 'Bitte gib einen gültigen Betrag ein!',
                    disableButton: true,
                }
            )
        } else {
            this.setState(
                {
                    priceError: '',
                    disableButton: false
                }
            )
        }

    }

    handleMainImgStatusChange = (currentFiles) => {
        this.setState({mainImg: currentFiles[0]});
    }

    handleMainImgDelete(file, currentFiles) {
        this.setState({mainImg: {}});

    }


    handleRefPriceChange(e) {
        let text = e.target.value.replace(',', '.');
        ;
        this.setState({refPrice: text});
        if (isNaN(text)) {
            this.setState(
                {
                    priceError: 'Bitte gib einen gültigen Betrag ein!',
                    disableButton: true,
                }
            )
        } else {
            if (parseFloat(text) < 0.99) {
                this.setState(
                    {
                        priceError: 'Der Wert muss mindestens 0.99€ sein.',
                        disableButton: true,
                    }
                )
            } else {
                this.setState(
                    {
                        priceError: '',
                        disableButton: false
                    }
                )
            }

        }

    }

    handleCategoryChange(value) {
        this.setState({category: value});
    }

    handleTaxRateChange(e) {
        this.setState({taxRate: e.target.value});
    }

    handleShippingRatesChange(rateID) {
        if (this.state.shippingRates.find((item, index) => {
            return item.ID === rateID
        })) {
            this.setState({
                    shippingRates: this.state.shippingRates.filter((item, index) => {
                        return rateID !== item.ID;
                    }),
                }
            )
        } else {
            this.setState({
                    shippingRates: [...this.state.shippingRates, {ID: rateID, ClassName: 'ShippingRate'}],
                }
            )
        }
    }

    handleImagesChange(e) {
        let text = e.target.value;
        this.setState({images: text});
    }

    handleImageStatusChange = (currentFiles) => {
        this.setState({images: currentFiles});
    }

    handleImagesDelete(file, currentFiles) {
        if (this.props.match.params.id !== 'new') {
            if (this.props.match.params.id !== 0) {
                ApiService.authorizedClient(this.props.authKey).delete(
                    `/Product/${this.props.match.params.id}/many/Images/`,
                    {data: [file]}
                ).then().catch((error) => {
                    let msg = 'Ein Fehler ist aufgetreten';
                    if (error.response && error.response.data && error.response.data.message) {
                        msg = error.response.data.message
                    }
                    toastr.error('Fehler beim Löschen', msg)
                })
            }
        }

        this.setState({images: currentFiles})

    }

    handleSubmit() {
        if (
            this.state.title.length <= 0 ||
            !this.state.category ||
            this.state.shippingRates.length <= 0 ||
            !this.state.taxRate
        ) {
            this.setState({error: 'Bitte fülle alle Felder gekennzeichneten Felder aus!'});
            return;
        }
        const me = this;
        me.setState({loading: true});
        if (this.props.match.params.id === 'new') {
            this.submitProduct();
        } else {
            this.submitUpdate()
        }
    }

    submitUpdate() {
        const me = this;
        ApiService.authorizedClient(this.props.authKey).put(
            '/Product/' + me.props.match.params.id,
            {
                Title: me.state.title,
                Description: me.state.description,
                Price: me.state.price,
                CategoryID: me.state.category,
                VendorID: me.props.user.VendorID,
                RequestOnly: me.state.requestOnly,
                RefPrice: me.state.refPrice,
                Promoted: me.state.promoted,
                Sale: me.state.sale,
                TaxRateID: me.state.taxRate,
                MainImage: me.state.mainImg.ID,
            }
        )
            .then((response) => {
                me.submitShipping(response.data)
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten';
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            me.setState({loading: false});
            toastr.error('Fehler beim Produktanlegen', msg)
        })
    }

    submitProduct() {
        const me = this;
        ApiService.authorizedClient(this.props.authKey).post(
            '/Product',
            {
                Title: me.state.title,
                Description: me.state.description,
                Price: me.state.price,
                CategoryID: me.state.category,
                VendorID: me.props.user.VendorID,
                RequestOnly: me.state.requestOnly,
                RefPrice: me.state.refPrice,
                Promoted: me.state.promoted,
                Sale: me.state.sale,
                TaxRateID: me.state.taxRate,
                MainImage: me.state.mainImg.ID,
            }
        )
            .then((response) => {
                me.submitShipping(response.data)

            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten';
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            me.setState({loading: false});
            toastr.error('Fehler beim Produktanlegen', msg)
        })
    }

    submitShipping(product) {
        const me = this;
        ApiService.authorizedClient(this.props.authKey).patch(
            '/Product/' + product.ID + '/many/ShippingRates/deleteOld',
            me.state.shippingRates
        )
            .then((response) => {
                me.submitImages(product)
                toastr.success('Erfolgreich', 'Produkt wurde angelegt/aktualisiert')
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten';
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            toastr.error('Fehler beim Produktanlegen', msg)
        })
    }

    submitImages(product) {
        const me = this;
        ApiService.authorizedClient(this.props.authKey).patch(
            '/Product/' + product.ID + '/many/Images',
            this.state.images
        ).then(
            () => {
                me.setState({loading: false, error: ''});
                this.props.history.push('/myaccount/products/' + product.ID);
            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                me.setState({loading: false});
                toastr.error('Fehler beim hochladen der Bilder', errorMsg)
            },
        );
    }


    render() {
        return (
            <div className={'p-4 bg-white shadow-sm ' + (this.state.loading ? 'h-100 d-flex' : '')}>
                {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-auto d-block align-self-center'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    <>
                        {this.props.match.params.id === 'new' ?
                            <h4>Neues Produkt</h4>
                            :
                            <h4>Produkt bearbeiten</h4>
                        }
                        <Form>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputTitle"
                                    placeholder="Titel*"
                                    onChange={this.handleTitleChange}
                                    disabled={this.state.loading}
                                    value={this.state.title}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputTitle">Titel*</Form.Label>
                                <p className='text-danger m-0'>{this.state.titleError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="nubmer"
                                    step={0.01}
                                    min={0}
                                    id="inputPrice"
                                    placeholder="Bruttopreis (Euro)"
                                    onChange={this.handlePriceChange}
                                    disabled={this.state.loading}
                                    value={this.state.price}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputPrice">Bruttopreis (Euro)</Form.Label>
                                <p className='text-danger m-0'>{this.state.priceError}&nbsp;</p>
                            </div>
                            <Form.Group controlId="taxRate">
                                <Form.Label>MwSt.*</Form.Label>
                                <Form.Control as="select"
                                              value={this.state.taxRate}
                                              onChange={this.handleTaxRateChange}
                                >
                                    {this.state.availableTaxRates.map((item) =>
                                        <option key={item.ID}
                                                value={item.ID}>{item.Title + ' (' + item.Rate * 100 + '%)'}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            <div>
                                <Form.Label>Beschreibung</Form.Label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={this.state.description ?? ''}
                                    config={{removePlugins: ['ImageUpload']}}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        this.setState({description: data});
                                    }}
                                />
                            </div>
                            <div className="m-0 mt-2">
                                <Form.Label htmlFor="inputCategory">Kategorie*</Form.Label>
                                <Cascader block
                                          data={this.state.categories}
                                          placeholder='Auswählen'
                                          id='inputCategory'
                                          value={this.state.category}
                                          onChange={this.handleCategoryChange}
                                          menuWidth={200}
                                          menuHeight={250}
                                />
                                <p className='text-danger m-0'>{this.state.categoryError}&nbsp;</p>
                            </div>
                            <div className="m-0 mt-2">
                                <Form.Label htmlFor="inputCategory">Versandoptionen*</Form.Label>
                                {this.state.availableShippingRates.map((rate, index) => {
                                    return (
                                        <Form.Group controlId={rate.ID} key={rate.ID}>
                                            <Form.Check
                                                key={rate.ID}
                                                type="checkbox"
                                                label={[rate.Title + ' - ', <i>{rate.Rate.toFixed(2) + ' €'}</i>]}
                                                checked={this.state.shippingRates
                                                    .find(
                                                        (item, index) => {
                                                            return item.ID === rate.ID;
                                                        })
                                                }
                                                onChange={() => this.handleShippingRatesChange(rate.ID)}
                                            />
                                        </Form.Group>
                                    )
                                })}
                            </div>
                            <FormGroup>
                                <Form.Label>Hauptbild</Form.Label>
                                {typeof this.state.mainImg !== 'undefined' && this.state.mainImg.ID ?
                                    <UploadField
                                        onChange={this.handleMainImgStatusChange}
                                        inputContent='Ziehe dein Hauptbild hierher oder klicken Sie zum Durchsuchen'
                                        maxFiles={1}
                                        onDelete={this.handleMainImgDelete}
                                        files={[this.state.mainImg]}
                                    />
                                    :
                                    <UploadField
                                        onChange={this.handleMainImgStatusChange}
                                        onDelete={this.handleMainImgDelete}
                                        inputContent='Ziehe dein Hauptbild hierher oder klicken Sie zum Durchsuchen'
                                        maxFiles={1}
                                    />
                                }

                            </FormGroup>
                            <FormGroup>
                                <Form.Label>Bilder</Form.Label>
                                <UploadField
                                    onChange={this.handleImageStatusChange}
                                    onDelete={this.handleImagesDelete}
                                    files={this.state.images}
                                />
                            </FormGroup>
                            <Form.Check type='checkbox' className="form-check-paid mb-1" id={`formTopCheckbox`}>
                                <Form.Check.Label className='w-100 d-flex justify-content-between align-items-center'>
                                    <div className="check-title">
                                        <Form.Check.Input
                                            type='checkbox'
                                            checked={this.state.promoted}
                                            onChange={() => {
                                                this.setState({
                                                    promoted: !this.state.promoted
                                                });
                                            }}
                                        />
                                        <b>TOP Produkt</b>
                                    </div>
                                    <div className="check-price">€{this.state.topProductPrice.toFixed(2)}
                                        {this.state.topProductDuration ? ' / für ' + this.state.topProductDuration + ' Tage' : ''}
                                    </div>
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check type='checkbox' className="form-check-paid mb-1" id={`formSaleCheckbox`}>
                                <Form.Check.Label className='w-100 d-flex justify-content-between align-items-center'>
                                    <div className="check-title">
                                        <Form.Check.Input
                                            type='checkbox'
                                            checked={this.state.sale}
                                            onChange={() => {
                                                this.setState(
                                                    {sale: !this.state.sale}
                                                );
                                            }}
                                        />
                                        <b>SALE Produkt</b>
                                    </div>
                                    <div className="check-price">
                                        €{this.state.saleProductPrice.toFixed(2)}
                                        {this.state.saleProductDuration ? ' / für ' + this.state.saleProductDuration + ' Tage' : ''}
                                    </div>
                                </Form.Check.Label>
                            </Form.Check>
                            {/*<Form.Group controlId="formSaleCheckbox" className="mt-1">*/}
                            {/*    <Form.Check type="checkbox"*/}
                            {/*                label="ist im Sale und hat einen Stattpreis"*/}
                            {/*                checked={this.state.sale}*/}
                            {/*                onChange={() => {*/}
                            {/*                    this.setState({*/}
                            {/*                        sale: !this.state.sale*/}
                            {/*                    });*/}
                            {/*                }}/>*/}
                            {/*</Form.Group>*/}
                            {this.state.sale ?
                                <div className="form-label-group m-0">
                                    <Form.Control
                                        type="nubmer"
                                        step={0.01}
                                        min={0}
                                        id="inputRefPrice"
                                        placeholder="Stattpreis - Bruttopreis (Euro)"
                                        onChange={this.handleRefPriceChange}
                                        disabled={this.state.loading}
                                        value={this.state.refPrice}
                                        className='input-foreground'
                                    />
                                    <Form.Label htmlFor="inputRefPrice">Stattpreis - Bruttopreis (Euro)</Form.Label>
                                    <p className='text-danger m-0'>{this.state.refPriceError}&nbsp;</p>
                                </div>
                                :
                                null
                            }
                            <Form.Group controlId="formBasicCheckbox" className="mt-3">
                                <Form.Check type="checkbox"
                                            label="Nur auf Anfrage"
                                            checked={this.state.requestOnly}
                                            onChange={() => {
                                                this.setState({
                                                    requestOnly: !this.state.requestOnly
                                                });
                                            }}/>
                            </Form.Group>
                            <div className='mb-4'/>
                            {this.state.error ?
                                <Alert variant="danger">{this.state.error}</Alert>
                                : null
                            }
                            <Button onClick={this.handleSubmit} disabled={this.state.disableButton}
                                    className="btn btn-block text-uppercase btn-primary text-white">
                                {this.props.match.params.id === 'new' ?
                                    'Anlegen'
                                    :
                                    'Ändern'
                                }
                            </Button>
                        </Form>
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(ProductForm));
