import React from 'react';
import {Image, Media, Spinner} from 'react-bootstrap';
import Icofont from "../../components/Icofont/Icofont";
import {ApiService} from "../../services/ApiService";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

class Order extends React.Component {

    state = {
        order: {},
        vendorOrders: [],
        remarks: '',
        billingAddress: '',
        shippingAddress: '',
        vendorLoading: true,
        loading: true,
        total: 0
    }

    componentDidMount() {
        ApiService.authorizedClient(this.props.authKey).get("/Order/" + this.props.match.params.id)
            .then((response) => {
                this.setState({
                    order: response.data,
                }, () => {
                    this.fetchBillingAddress();
                    this.fetchShippingAddress();
                    this.getVendorOrders();
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    getVendorOrders() {
        let filter = '?filter[ID][]=0';
        this.state.order.VendorOrders.map((item) => {
            filter += '&filter[ID][]=' + item.ID
            return '';
        })
        ApiService.authorizedClient(this.props.authKey).get("/VendorOrder/" + filter)
            .then((response) => {
                this.setState({
                    vendorOrders: response.data,
                    loading: false
                }, () => this.getVendorOrderOrderItem());
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    getVendorOrderOrderItem() {
        const me = this;
        this.state.vendorOrders.forEach((item) => {
            let filter = '?filter[ID][]=0';
            item.OrderItems.map((item) => {
                filter += '&filter[ID][]=' + item.ID
                return '';
            })
            ApiService.authorizedClient(this.props.authKey).get("/OrderItem/" + filter)
                .then((response) => {
                    item.OrderItems = response.data;
                    me.setState({
                        vendorLoading: false
                    })
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message, loading: false});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                    }
                })
        })
    }

    fetchBillingAddress() {
        this.setState({loading: true});
        ApiService.authorizedClient(this.props.authKey).get("/Address/" + this.state.order.BillingAddressID)
            .then((response) => {
                this.setState({
                    billingAddress: response.data.Title,
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    fetchShippingAddress() {
        this.setState({loading: true});
        ApiService.authorizedClient(this.props.authKey).get("/Address/" + this.state.order.ShippingAddressID)
            .then((response) => {
                this.setState({
                    shippingAddress: response.data.Title,
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    render() {
        return (
            <div className="bg-white card mb-4 order-list shadow-sm">
                <div className="gold-members p-4">
                    {this.state.loading ?
                        <Spinner animation="border" role="status"
                                 className='mx-auto d-block align-self-center'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        :
                        <>
                            {this.state.order.Paid ?
                                (
                                    <span className="float-right text-info">Bezahlt am {this.state.order.Paid}
                                        <Icofont icon="check-circled" className="text-success ml-1"/>
			                      </span>
                                )
                                : ""
                            }
                            <p className="text-gray mb-3">
                                <Icofont icon="list"/> Bestellung: {this.state.order.Title}
                                <Icofont icon="clock-time" className="ml-2"/> {this.state.order.Placed}
                            </p>
                            <p className="text-gray mb-1">
                                <Icofont icon="location-pin"/> Rechnungsadresse: {this.state.billingAddress}<br/>
                                <Icofont icon="location-pin"/> Lieferadresse: {this.state.shippingAddress}
                            </p>

                            <p className="text-dark">
                                {this.state.orderProducts}
                            </p>
                            <p className="text-dark">
                                Anmerkungen: {this.state.order.Remarks ?? '----'}
                            </p>
                            <hr/>
                            {/*<div className="float-right">*/}
                            {/*    <Link className="btn btn-sm btn-outline-primary mr-1" to={this.props.helpLink}><Icofont icon="headphone-alt" /> HELP</Link>*/}
                            {/*    <Link className="btn btn-sm btn-primary" to={this.props.detailLink}><Icofont icon="refresh" /> REORDER</Link>*/}
                            {/*</div>*/}
                            {this.state.vendorLoading ?
                                <Spinner animation="border" role="status"
                                         className='mx-auto d-block align-self-center'>
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                                :
                                this.state.vendorOrders.map((item) =>
                                    <Media className='my-2 mb-4' key={item.ID}>
                                        <Image className="mr-4" src={item.VendorPreviewImage} alt={item.VendorTitle}/>
                                        <Media.Body>
                                            <h6 className="mb-2">
                                                {item.VendorTitle}
                                            </h6>
                                            {item.OrderItems.map((orderItem, index) =>
                                                <p>{orderItem.Quantity + 'x '}
                                                    {orderItem.ProductHidden ?
                                                        <>
                                                            {orderItem.Title}&nbsp;(€ {parseFloat(orderItem.Total).toFixed(2) + ')'}
                                                        </>
                                                        :
                                                        <>
                                                            <Link
                                                                to={'/produkt/' + orderItem.ProductID + '/' + orderItem.URLTitle}>{orderItem.Title}</Link>
                                                            &nbsp;(€ {parseFloat(orderItem.Total).toFixed(2) + ')'}
                                                        </>

                                                    }

                                                </p>
                                            )}
                                            {item.InvoiceLink ?
                                                <a href={item.InvoiceLink} className="btn-primary btn mt-3"
                                                   target="_blank" rel="noopener noreferrer">Rechnung</a>
                                                :
                                                null
                                            }
                                        </Media.Body>
                                    </Media>
                                )
                            }


                            <p className="mb-0 text-black text-primary pt-2">
                                    <span
                                        className="text-black font-weight-bold"> Gesamt:</span> €{parseFloat(this.state.order.OrderTotal).toFixed(2)}
                            </p>
                        </>
                    }

                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
});


export default connect(
    mapStateToProps,
    null,
)(Order);
