import React, {Fragment, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import rg4js from 'raygun4js';

function ScrollToTop({history, children}) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            rg4js('trackEvent', {type: 'pageView', path: window.location.pathname});
            window.scrollTo({top: 0, behavior: 'smooth'});
        });
        return () => {
            unlisten();
        }
    }, []);

    return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
