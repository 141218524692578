import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Icofont from "../../components/Icofont/Icofont";
import {ApiService} from "../../services/ApiService";
import {connect} from "react-redux";

class VendorOrderCard extends React.Component {

    state = {
        vendorOrder: this.props.vendorOrder,
        orderItems: [],
        order: {},
        remarks: '',
        billingAddress: '',
        shippingAddress: '',
        vendorLoading: true,
        total: 0
    }

    componentDidMount() {
        this.getOrder();
        this.getVendorOrderOrderItem();
    }

    getOrder() {
        if (typeof this.state.vendorOrder.OrderID !== "undefined" && this.state.vendorOrder.OrderID) {
            ApiService.authorizedClient(this.props.authKey).get("/Order/" + this.state.vendorOrder.OrderID)
                .then((response) => {
                    this.setState({
                            order: response.data,
                        }, () => {
                            this.fetchBillingAddress();
                            this.fetchShippingAddress();
                        }
                    );
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message, loading: false});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                    }
                })
        }

    }

    getVendorOrderOrderItem() {
        if (this.state.vendorOrder.ID) {
            ApiService.authorizedClient(this.props.authKey).get("/OrderItem/?filter[VendorOrderID]=" + this.state.vendorOrder.ID)
                .then((response) => {
                    this.setState({
                        orderItems: response.data ?? [],
                        vendorLoading: false
                    })
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.setState({error: error.response.data.message, loading: false});
                    } else {
                        this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                    }
                })
        }
    }

    fetchBillingAddress() {
        this.setState({loading: true});
        ApiService.authorizedClient(this.props.authKey).get("/Address/" + this.state.order.BillingAddressID)
            .then((response) => {
                let title = '';
                if (response.data.Company) {
                    title += response.data.Company + ' ';
                }
                title += response.data.Title
                this.setState({
                    billingAddress: title,
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    fetchShippingAddress() {
        this.setState({loading: true});
        ApiService.authorizedClient(this.props.authKey).get("/Address/" + this.state.order.ShippingAddressID)
            .then((response) => {
                let title = '';
                if (response.data.Company) {
                    title += response.data.Company + ' ';
                }
                title += response.data.Title
                this.setState({
                    shippingAddress: title,
                });
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    this.setState({error: error.response.data.message, loading: false});
                } else {
                    this.setState({error: 'Ein Fehler ist aufgetreten', loading: false});
                }
            })
    }

    render() {
        return (
            <div className="bg-white card mb-4 order-list shadow-sm">
                <div className="gold-members p-4">
                    <Row>
                        <Col lg={8}>
                            <p className="text-gray mb-3">
                                <Icofont icon="list"/> Bestellung: {this.state.order.Title}
                                <Icofont icon="clock-time" className="ml-2"/> {this.state.order.Placed}
                            </p>
                            <p className="text-gray mb-1">
                                <Icofont icon="user"/> Besteller: {this.state.order.MemberName}<br/>
                                <Icofont icon="location-pin"/> Rechnungsadresse: {this.state.billingAddress}<br/>
                                <Icofont icon="location-pin"/> Lieferadresse: {this.state.shippingAddress}
                            </p>

                            <p className="text-dark">
                                {this.props.orderProducts}
                            </p>
                            <p className="text-dark">
                                Anmerkungen: {this.state.order.Remarks ?? '----'}
                            </p>
                        </Col>
                        <Col lg={4} className="text-right">
                            <p className="mb-2 text-black text-primary pt-2">
                                    <span
                                        className="text-black font-weight-bold"
                                    >
                                        Gesamt:
                                    </span> €{parseFloat(this.state.vendorOrder.TotalWithShipping).toFixed(2)}
                            </p>
                            <a target="_blank" className='btn btn-primary mb-3'
                               href={this.state.vendorOrder.InvoiceLink}
                               rel="noopener noreferrer"
                            >Rechnung</a>
                            <a target="_blank" className='btn btn-primary'
                               href={this.state.vendorOrder.NoticeLink}
                               rel="noopener noreferrer"
                            >Lieferschein</a>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    authKey: state.user.authKey,
    memberID: state.user.memberID,
});


export default connect(
    mapStateToProps,
    null,
)(VendorOrderCard);
