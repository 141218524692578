import {put, select} from 'redux-saga/effects';
import CoordsActions from '../stores/coords/actions';
import {ApiService} from "../services/ApiService";

const getLocation = (state) => state.location;
const getCoords = (state) => state.coords;

/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* updateLocation() {
    const distance = (lat1, lon1, lat2, lon2) => {
        if (lat1 === 0 && lon1 === 0) {
            return '---'
        }
        const deg2rad = (deg) => {
            return deg * (Math.PI / 180)
        }
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d.toFixed(1);
    }
    // Dispatch a redux action using `put()`
    // @see https://redux-saga.js.org/docs/basics/DispatchingActions.html
    const location = yield select(getLocation);
    const coords = yield select(getCoords);
    if (location.position && location.position.coords && location.position.coords.latitude) {
        const val = distance(location.position.coords.latitude, location.position.coords.longitude, coords.latitude, coords.longitude)
        if (val > 0.5) {
            yield setGeoAddress()
            yield put(CoordsActions.updateLocation(location.position.coords.latitude, location.position.coords.longitude, false));
        }
    }
}


export function* setGeoAddress() {
    const coords = yield select(getCoords);
    if (coords && coords.latitude) {
        const response = yield ApiService.geocodeFromLatLng(coords.latitude, coords.longitude)
        if (response.data && response.data.result && response.data.result[0]) {
            yield  put(CoordsActions.setGeocodeAddress(response.data.result[0]));
        }
    }
}
