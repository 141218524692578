//@flow
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Badge, Col, Row} from 'react-bootstrap';
import Icofont from "../Icofont/Icofont";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import LazyImage from "../LazyImage/LazyImage";
import {connect} from "react-redux";
import DefaultImage from "../../assets/img/default_img.png";
import AddToBookmarkCollectionModal from "../Modals/AddToBookmarkCollectionModal";

class ProductCardItem extends Component {
    static defaultProps = {
        imageAlt: '',
        imageClass: '',
        imageID: 0,
        vendorID: 0,
        vendorTitle: 0,
        categoryID: 0,
        offerText: '',
        offerColor: 'success',
        subTitle: '',
        distance: '',
        price: '',
        showPromoted: false,
        promotedVariant: 'dark',
        favIcoIconColor: '',
        rating: '',
        latitude: 0,
        longitude: 0,
        showBookmarkModal: false,
    }

    state = {
        img: '',
        subLine: '',
        address: {}
    }

    componentDidMount() {
        if (this.props.vendorID) {
            ApiService.client.get('/Vendor/' + this.props.vendorID)
                .then((result) => {
                    this.setState({
                        vendorTitle: result.data.Title
                    })
                    this.getAddress(result.data.AddressID)
                }).catch((error) => {
            })
        }

        if (this.props.categoryID) {
            ApiService.client.get('/Category/' + this.props.categoryID)
                .then((result) => {
                    this.setState({
                        subLine: result.data.Title
                    })
                }).catch((error) => {
            })
        }

    }

    getAddress(addressID) {
        ApiService.client.get('/Address/' + addressID)
            .then((result) => {
                this.setState({
                    address: result.data
                })
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten'
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            this.setState({
                    loading: false
                }
            );
            toastr.error('Fehler laden der Entferneung', msg)
        })
    }


    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        const deg2rad = (deg) => {
            return deg * (Math.PI / 180)
        }
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d.toFixed(1);
    }

    hideBookmarkModal = () => this.setState({showBookmarkModal: false});


    render() {
        return (
            <>
                <AddToBookmarkCollectionModal
                    show={this.state.showBookmarkModal}
                    productID={this.props.productID}
                    productTitle={this.props.title}
                    onHide={this.hideBookmarkModal}
                />
                <div
                    className={(!this.props.noHeight100 ? 'h-100' : 'mb-3') + ' list-card bg-white rounded overflow-hidden position-relative shadow-sm'}>
                    <div className={(!this.props.noHeight100 ? 'h-100' : '') + ' d-flex flex-column'}>
                        <div className="list-card-image">
                            {this.props.rating ? (
                                    <div className="star position-absolute">
                                        <Badge variant="success">
                                            <Icofont icon='star'/> {this.props.rating}
                                        </Badge>
                                    </div>
                                )
                                : ""
                            }
                            <div className={`favourite-heart position-absolute ${this.props.favIcoIconColor}`}>
                                <Icofont icon='plus' className='cursor-pointer'
                                         onClick={() => this.setState({showBookmarkModal: true})}/>
                            </div>
                            {this.props.showPromoted ? (
                                    <div className="member-plan position-absolute">
                                        <Badge variant='primary'>TOP</Badge>
                                    </div>
                                )
                                : ""
                            }
                            {this.props.showSale ?
                                <div className="member-plan position-absolute">
                                    <Badge variant='danger'>SALE %</Badge>
                                </div>
                                : ""
                            }
                            <Link to={this.props.linkUrl}>
                                <LazyImage src={this.props.image ?? DefaultImage}
                                           className={'product-card-img ' + this.props.imageClass}
                                           alt={this.props.imageAlt}/>
                            </Link>
                        </div>
                        <div className="px-3 pt-3 position-relative d-flex flex-column justify-content-end flex-grow-1">
                            <div className="list-card-body">
                                <Row>
                                    <Col lg={12}>
                                        {this.props.refPrice && this.props.showSale ? (
                                                <div className="list-card-badge mb-1 d-flex justify-content-end">
                                        <span>
                                            <Badge variant='success'>ANGEBOT</Badge> <small><del className="text-muted">statt €{this.props.refPrice.toFixed(2)}</del></small>
                                        </span>
                                                </div>
                                            )
                                            : ""
                                        }
                                    </Col>
                                    <Col lg={12}>
                                        {this.state.vendorTitle ?
                                            <p className='text-italic'>{this.state.vendorTitle}</p>
                                            :
                                            null
                                        }
                                    </Col>
                                </Row>


                                <h6 className="mb-1"><Link to={this.props.linkUrl}
                                                           className="text-black">{this.props.title}</Link></h6>
                                {this.state.subLine ?
                                    <p className="text-gray mb-3">{this.state.subLine}</p>
                                    :
                                    this.props.subTitle ? (
                                            <p className="text-gray mb-3">{this.props.subTitle}</p>
                                        )
                                        : ''
                                }

                                <div className="text-primaryNew time mb-3 w-100">
                                    {this.props.coords.latitude ?
                                        <span className="pb-1 pt-1 pr-2">
                                        <Icofont icon='map-pins'/>&nbsp;
                                            {
                                                this.getDistanceFromLatLonInKm(
                                                    this.props.latitude,
                                                    this.props.longitude,
                                                    this.props.coords.latitude,
                                                    this.props.coords.longitude,
                                                )
                                            } km
                                    </span>
                                        :
                                        null
                                    }
                                    {this.props.price ?
                                        <span className="float-right"
                                              style={{fontSize: '1.15rem'}}><b>{this.props.price}</b></span>
                                        : null
                                    }
                                    <div className="clearfix"/>
                                </div>
                                <Link to={this.props.linkUrl}
                                      className="product-card-bottom-link d-flex justify-content-between">
                                    Produktdetails anzeigen
                                    <Icofont icon="arrow-right"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
});


export default connect(
    mapStateToProps,
)(ProductCardItem);
