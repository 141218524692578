import React, {Component} from 'react';
import {Badge, Col, Container, Nav, Row, Spinner, Tab} from 'react-bootstrap';
import ItemsCarousel from '../../components/ItemsCarousel/ItemsCarousel';
import Icofont from "../../components/Icofont/Icofont";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import LazyImage from "../../components/LazyImage/LazyImage";
import MarketplaceBackground from '../../assets/img/marketplaceBackground.jpeg';
import SimpleGallery from "../../components/SimplleGallery/SimplleGallery";
import Map from "../../components/Map/Map";
import GoogleMarker from "../../components/GoogleMarker/GoogleMarker";
import ProductCardItem from "../../components/CardItem/ProductCardItem";
import DefaultImage from "../../assets/img/default_img.png";
import {HTMLService} from "../../services/HTMLService";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";

class VendorDetail extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            vendor: {},
            logo: '',
            images: [],
            address: {},
            showAddressModal: false,
            products: [],
            saleProducts: [],
            loadingProducts: true
        };
    }

    loadVendor() {
        ApiService.client.get('/Vendor/' + this.props.match.params.id)
            .then((result) => {
                this.setState({
                    vendor: result.data
                }, () => {
                    this.loadLogo();
                    this.loadImages();
                    this.loadAddress();
                    this.loadProducts(result.data.ID)
                })
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten'
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            this.setState({loading: false});
            toastr.error('Fehler', msg)
        })
    }

    loadProducts(vendorID) {
        ApiService.client.get('/Product/?filter[VendorID]=' + vendorID)
            .then((result) => {
                this.setState({
                    products: result.data,
                    saleProducts: result.data.filter((item) => item.Sale === 1),
                    loadingProducts: false
                })
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten'
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            this.setState({loadingProducts: false});
            toastr.error('Fehler laden der Produkte', msg)
        })
    }

    loadLogo() {
        if (this.state.vendor.LogoID) {
            ApiService.client.get('/Image/' + this.state.vendor.LogoID + '/FitMax/200/200/')
                .then((result) => {
                    this.setState({
                        logo: result.data.AbsoluteURL
                    })
                }).catch((error) => {
                this.setState({
                    logo: DefaultImage
                })
            })
        } else {
            this.setState({
                logo: DefaultImage
            })
        }

    }

    loadImages() {
        ApiService.client.get('/Vendor/' + this.state.vendor.ID + '/many/Images/')
            .then((response) => {
                this.setState({
                    images: response.data
                });
            })
            .catch((error) => {
                let msg = 'Ein Fehler ist aufgetreten'
                if (error.response && error.response.data && error.response.data.message) {
                    msg = error.response.data.message
                }
                this.setState({loading: false});
                toastr.error('Fehler laden des Bildes', msg)
            })
    }

    loadAddress() {
        ApiService.client.get('/Address/' + this.state.vendor.AddressID)
            .then((result) => {
                this.setState({
                    address: result.data
                })
            }).catch((error) => {
        })
    }

    getHeaderImage() {
        if (this.state.images.length > 0) {
            return this.state.images[0].AbsoluteURL
        }
        return MarketplaceBackground;
    }

    componentDidMount() {
        this.loadVendor();

    }

    render() {
        return (
            <>
                {typeof this.state.vendor.Title !== 'undefined' && this.state.logo ?
                    <CustomMetaTags
                        title={'Regionlinemarkt - ' + (this.state.vendor.Title ?? 'Händlerdetails')}
                        desc={this.state.vendor.ShortDescription}
                        img={this.state.logo}
                    />
                    :
                    <CustomMetaTags
                        title={'Regionlinemarkt - Händlerdetails'}
                    />
                }

                <section className="restaurant-detailed-banner">
                    <div className="text-center">
                        <LazyImage fluid className="cover" src={this.getHeaderImage()}/>
                    </div>
                    <div className="restaurant-detailed-header">
                        <Container>
                            <Row className="d-flex align-items-end">
                                <Col md={8}>
                                    <div className="restaurant-detailed-header-left">
                                        <LazyImage fluid className="mr-3 float-left" alt="osahan"
                                                   src={this.state.logo}/>
                                        <h2 className="text-white">{this.state.vendor.Title}</h2>
                                        <p className="text-white mb-1"><Icofont
                                            icon="location-pin"/> {this.state.address.Title}
                                        </p>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className="restaurant-detailed-header-right text-right">
                                        {typeof this.state.vendor.Categories !== 'undefined' ?
                                            this.state.vendor.Categories.map((item) =>
                                                <Badge key={item.ID} variant='primary'
                                                       className='p-2 category-tag ml-1 mt-1'>{item.Title}
                                                </Badge>
                                            )
                                            :
                                            null
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>

                </section>

                <Tab.Container defaultActiveKey="first">
                    <section className="offer-dedicated-nav bg-white border-top-0 shadow-sm">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <Nav id="pills-tab">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Angebote</Nav.Link>
                                        </Nav.Item>
                                        {this.state.images.length ?
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Bilder</Nav.Link>
                                            </Nav.Item>
                                            :
                                            null
                                        }
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Info</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="agb">AGB</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="returnPolicy">Widerrufsrecht</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="offer-dedicated-body-left">
                                        <Tab.Content className='h-100'>
                                            <Tab.Pane eventKey="first">
                                                {this.state.loadingProducts ?
                                                    <Spinner animation="border" role="status"
                                                             className='mx-auto d-block align-self-center'>
                                                        <span className="sr-only">Loading...</span>
                                                    </Spinner>
                                                    :
                                                    this.state.saleProducts.length ?
                                                        <>
                                                            <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                                                <h6 className="mb-3">Angebote
                                                                    {/*<Badge variant="success"> */}
                                                                    {/*    <Icofont icon="tags"/> */}
                                                                    {/*    15% Off All Items */}
                                                                    {/*</Badge>*/}
                                                                </h6>
                                                                <ItemsCarousel items={this.state.saleProducts}/>
                                                            </div>
                                                        </>
                                                        :
                                                        null

                                                }
                                                <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                                    <Row>
                                                        <h5 className="mb-4 mt-3 col-md-12">Produkte</h5>
                                                        {this.state.loadingProducts ?
                                                            <Spinner animation="border" role="status"
                                                                     className='mx-auto d-block align-self-center'>
                                                                <span className="sr-only">Loading...</span>
                                                            </Spinner>
                                                            :
                                                            this.state.products.length ?
                                                                this.state.products.map((product, index) => {
                                                                    return (
                                                                        <Col xl={3} lg={4} sm={6} className="mb-4 pb-2"
                                                                             key={product.ID}>
                                                                            <ProductCardItem
                                                                                title={product.Title}
                                                                                subTitle=''
                                                                                imageAlt={product.Title}
                                                                                image={product.PreviewImage}
                                                                                imageClass='img-fluid item-img'
                                                                                linkUrl={'/produkt/' + product.ID + '/' + product.URLTitle}
                                                                                price={'€' + product.Price.toFixed(2)}
                                                                                vendorID={product.VendorID}
                                                                                categoryID={product.CategoryID}
                                                                                distance="10km NOCH BERECHENEN!"
                                                                                latitude={product.Latitude}
                                                                                longitude={product.Longitude}
                                                                                showPromoted={product.Promoted}
                                                                                showSale={product.Sale}
                                                                                refPrice={product.RefPrice}
                                                                                favIcoIconColor='text-primary'
                                                                            />
                                                                        </Col>
                                                                    )
                                                                })
                                                                :
                                                                <Col xs={12}>
                                                                    <p>Dieser Händler hat noch keine Produkte</p>
                                                                </Col>
                                                        }
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <div className='p-4 mb-3'>
                                                    {this.state.images.length <= 0 ?
                                                        <p>Keine Bilder gefunden</p>
                                                        :
                                                        <SimpleGallery images={this.state.images}/>
                                                    }

                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <div id="restaurant-info"
                                                     className="bg-white rounded shadow-sm p-4 mb-4">
                                                    <Row>
                                                        <Col xs={12}>
                                                            <h1>{this.state.vendor.Title}</h1>
                                                            {this.state.vendor.Tel ?
                                                                <p className="mb-2 text-black"><Icofont
                                                                    icon="phone-circle text-primary mr-2"/> {this.state.vendor.Tel}
                                                                </p>
                                                                :
                                                                null
                                                            }
                                                            {this.state.vendor.Email ?
                                                                <p className="mb-2 text-black"><Icofont
                                                                    icon="email text-primary mr-2"/> {this.state.vendor.Email}
                                                                </p>
                                                                :
                                                                null
                                                            }
                                                            <div
                                                                className='mt-3 mb-3'
                                                                dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.vendor.Description)}}
                                                            />

                                                        </Col>
                                                    </Row>
                                                </div>
                                                {this.state.address.Latitude ?
                                                    <Map
                                                        heightStyle='400px'
                                                        center={{
                                                            lat: this.state.address.Latitude,
                                                            lng: this.state.address.Longitude
                                                        }}
                                                        zoom={12}
                                                    >
                                                        <GoogleMarker
                                                            lat={this.state.address.Latitude}
                                                            lng={this.state.address.Longitude}
                                                            text={this.state.vendor.Title}
                                                            title={this.state.vendor.Title}
                                                            image={this.state.logo}
                                                            desc={this.state.vendor.ShortDescription}
                                                            linkTo={'/händler/' + this.state.vendor.ID}
                                                            linkTitle={'Händlerprofil anzeigen'}
                                                            address={this.state.address.Title}
                                                        />
                                                    </Map>

                                                    :
                                                    null
                                                }
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="agb">
                                                <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                                    <div
                                                        className='mt-3 mb-3'
                                                        dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.vendor.AGB)}}
                                                    />
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="returnPolicy">
                                                <div className='bg-white rounded shadow-sm p-4 mb-3'>
                                                    <div
                                                        className='mt-3 mb-3'
                                                        dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.vendor.ReturnPolicy)}}
                                                    />
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                </Tab.Container>
            </>
        );
    }
}


export default VendorDetail;
