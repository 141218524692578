import React, {Component} from "react";
import {ApiService} from "../../services/ApiService";
import TeaserElement from "../../elements/TeaserElement/TeaserElement";
import ContentElement from "../../elements/ContentElement/ContentElement";
import DefaultElement from "../../elements/DefaultElement/DefaultElement";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";

export default class NewsPage extends Component {
    state = {
        elements: []
    }

    render() {
        return (
            <>
                <CustomMetaTags
                    title={'Regionlinemarkt - News'}
                />
                {this.state.elements.map((element, index) =>
                    <section className="py-5" key={element.ID} style={{backgroundColor: element.BackgroundColor}}>
                        {this.renderElement(element, index)}
                    </section>
                )}
            </>
        );
    }

    renderElement = (element, index) => {
        let elmentName = element.ClassName.replace("CIC\\Region\\Data\\", '');
        switch (elmentName) {
            case 'NewsTeaserElement':
                return <TeaserElement id={element.ID} elementClassPrefix="News"/>;
            case 'NewsContentElement':
                return <ContentElement id={element.ID} elementClassPrefix="News"/>;
            default:
                return <DefaultElement id={element.ID}/>;
        }

    }

    componentDidMount() {
        ApiService.client.get('/SiteConfig/1')
            .then((response) => {
                this.setState({elements: response.data.NewsElements ?? []});
            })
            .catch((error) => {
                console.log(error);
            })
    }
}
