import React, {Component} from "react";
import {Col, Container, Row, Spinner} from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom';
import Icofont from "../../components/Icofont/Icofont";
import SearchBackground from "../../assets/img/header_startseite.jpg";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";
import ProductCardItem from "../../components/CardItem/ProductCardItem";
import CustomMetaTags from "../../components/CustomMetaTags/CustomMetaTags";
import {connect} from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {HTMLService} from "../../services/HTMLService";
import LazyImage from "../../components/LazyImage/LazyImage";
import LocationField from "../../components/LocationField/LocationField";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 5
    },
    desktop: {
        breakpoint: {max: 3000, min: 1399},
        items: 5
    },
    desktopSmall: {
        breakpoint: {max: 1399, min: 1199},
        items: 4
    },
    tablet: {
        breakpoint: {max: 1199, min: 767},
        items: 2
    },
    mobile: {
        breakpoint: {max: 767, min: 0},
        items: 1
    }
};

class Home extends Component {
    state = {
        showProductSearch: false,
        address: '',
        searchTerm: '',
        homeProducts: [],
        productLoading: true,
        loadingStartText: true,
        regHeader: 'Mitglied werden',
        regSubHeading: 'Registriere dich jetzt um das volle Vorteilspotenzial auszuschöpfe',
        homeText: '',
        homeTeaser: ''
    }

    componentDidMount() {
        this.loadHomeProducts();
        this.fetchStartText();
        if (typeof this.props.coords.geocodeAddress !== 'undefined' && typeof this.props.coords.geocodeAddress.formatted_address !== "undefined") {
            this.setState(
                {
                    showProductSearch: true,
                    address: this.props.coords.geocodeAddress.formatted_address
                }
            );
        }
    }


    fetchStartText() {
        ApiService.client.get('/SiteConfig/1')
            .then((response) => {
                this.setState({
                    loadingStartText: false,
                    homeText: response.data.HomeText ?? '',
                    homeTeaser: response.data.HomeTeaserItems ?? [],
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    loadingRegText: false
                });
            })
    }

    loadHomeProducts() {
        let initFilter = '&sort=RAND()'
        if (this.props.coords.latitude && this.props.coords.longitude) {
            initFilter = '&sort=Distance ASC,RAND()&lat=' + this.props.coords.latitude + '&lng=' + this.props.coords.longitude
        }
        ApiService.client.get('/Product/?filter[VendorID:not]=0&filter[Hidden]=0' + initFilter + '&customLimit=16')
            .then((result) => {
                this.setState({
                    homeProducts: result.data,
                    productLoading: false
                })
            }).catch((error) => {
            let msg = 'Ein Fehler ist aufgetreten'
            if (error.response && error.response.data && error.response.data.message) {
                msg = error.response.data.message
            }
            this.setState({loadingProducts: false});
            toastr.error('Fehler laden der Produkte', msg)
        })
    }

    onKeyDownSearch = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            this.props.history.push('/marktplatz?search=' + this.state.searchTerm)
        }
    }


    render() {
        const ButtonGroup = ({next, previous}) => {
            return (
                <div className="carousel-button-group d-flex align-items-center justify-content-center">
                    <div className="home-carousel-button" onClick={() => previous()}>
                        <div className="d-flex align-items-center">
                            <Icofont icon="arrow-left" className="mr-4"/>nach links
                        </div>
                    </div>
                    <div className="divider ">
                    </div>
                    <div className="home-carousel-button" onClick={() => next()}>
                        <div className="d-flex align-items-center">
                            nach rechts<Icofont icon="arrow-right" className="ml-4"/>
                        </div>
                    </div>
                </div>
            );
        };

        return (
            <>
                <CustomMetaTags
                    title={'Regionlinemarkt - Startseite'}
                />
                <section className="homepage-search-block position-relative"
                         style={{backgroundImage: `url(${SearchBackground})`}}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} className="d-flex justify-content-center">
                                <div className="header-bg text-center mb-5">
                                    <h1 className="mb-1 text-white font-weight-bold">Angebote in deiner Nähe
                                        finden!</h1>
                                    <h5 className="text-primaryNew font-weight-normal">
                                        Suche nach regionalen Händlern, Landwirten, Produzenten, Dienstleistern, ...
                                    </h5>
                                </div>
                            </Col>
                            <Col lg={12} className="d-flex justify-content-center">
                                <div className="col-lg-7 col-md-7 col-sm-12">
                                    <div className="w-100 form-row justify-content-start custom-location-field mb-4">
                                        <LocationField className='mb-0 w-100'/>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={12} className="d-flex justify-content-center">
                                <Link to={'/marktplatz'} className='start-page-link'>ZUM MARKTPLATZ</Link>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {this.state.loadingStartText ?
                    <section className="section pt-5 pb-3 bg-white products-section">
                        <Spinner animation="border" role="status"
                                 className='mx-auto d-block align-self-center'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </section>
                    :
                    this.state.homeText || this.state.homeTeaser.length ?
                        <section className="section pt-4 pb-3 bg-white home-teaser-section">
                            <Container>
                                {this.state.homeText ?
                                    <div
                                        dangerouslySetInnerHTML={{__html: HTMLService.parseVideo(this.state.homeText)}}/>
                                    :
                                    null
                                }
                                {this.state.homeTeaser.length ?
                                    <Row className="mt-4 justify-content-center">
                                        {this.state.homeTeaser.map((teaser, index) => {
                                            return (
                                                <div className="col-lg-3 col-md-6 home-teaser-item" key={index}>
                                                    {teaser.ImgURL ?
                                                        <LazyImage
                                                            src={teaser.ImgURL}
                                                            className='teaser-image'
                                                            alt={teaser.Title}
                                                        />
                                                        :
                                                        null
                                                    }
                                                    <div className="home-teaser-content">
                                                        <span className="home-teaser-title">{teaser.Title}</span>
                                                        <p>{teaser.Content}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </Row>
                                    :
                                    null
                                }
                            </Container>
                        </section>
                        :
                        null
                }
                {this.state.productLoading ?
                    <section className="section pt-3 pb-3 bg-white products-section">
                        <Spinner animation="border" role="status"
                                 className='mx-auto d-block align-self-center'>
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </section>
                    :
                    this.state.homeProducts.length ?
                        <section className="section pt-3 pb-3 bg-white products-section">
                            <Carousel
                                customButtonGroup={<ButtonGroup/>}
                                responsive={responsive}
                                infinite={true}
                                centerMode={true}
                                arrows={false}
                            >
                                {this.state.homeProducts.map((product, index) => {
                                    return (
                                        <div className='home-carousel-item' key={index}>
                                            <ProductCardItem
                                                title={product.Title}
                                                subTitle=''
                                                imageAlt={product.Title}
                                                image={product.PreviewImage}
                                                imageClass='img-fluid item-img'
                                                linkUrl={'/produkt/' + product.ID + '/' + product.URLTitle}
                                                price={'€' + product.Price.toFixed(2)}
                                                vendorID={product.VendorID}
                                                categoryID={product.CategoryID}
                                                distance="10km NOCH BERECHENEN!"
                                                latitude={product.Latitude}
                                                longitude={product.Longitude}
                                                showPromoted={product.Promoted}
                                                refPrice={product.RefPrice}
                                                favIcoIconColor='text-primary'
                                            />
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </section>
                        :
                        null
                }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    coords: state.coords,
});


export default connect(
    mapStateToProps,
    null
)(withRouter(Home));
