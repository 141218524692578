import {put, select} from 'redux-saga/effects';
import BookmarkCollectionAction from '../stores/bookmarkCollections/actions';
import {ApiService} from '../services/ApiService';

const getKey = (state) => state.user.authKey;
const getID = (state) => state.user.memberID;

/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* fetchCollections() {
    // Dispatch a redux action using `put()`
    // @see https://redux-saga.js.org/docs/basics/DispatchingActions.html
    yield put(BookmarkCollectionAction.fetchCollectionsLoading());
    const key = yield select(getKey);
    const memberID = yield select(getID);

    // Fetch user informations from an API
    if (!memberID) {
        yield put(BookmarkCollectionAction.fetchCollectionsFailure('Keine MemberID!'));
    }
    try {
        const collections = yield ApiService.authorizedClient(key).get('/BookmarkCollection/?filter[MemberID]=' + memberID);
        yield put(BookmarkCollectionAction.fetchCollectionsSuccess(collections.data));
    } catch (e) {
        yield put(BookmarkCollectionAction.fetchCollectionsFailure(e.message));
    }
}


/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* addItem({listID, productID}) {
    // Dispatch a redux action using `put()`
    // @see https://redux-saga.js.org/docs/basics/DispatchingActions.html
    const key = yield select(getKey);

    // Fetch user informations from an API
    try {
        yield ApiService.authorizedClient(key).post('/BookmarkCollection/' + listID + '/toggle/add/' + productID, {});
        yield fetchCollections();
    } catch (e) {
        yield put(BookmarkCollectionAction.fetchCollectionsFailure(e.message));
    }
}

/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* removeItem({listID, productID}) {
    // Dispatch a redux action using `put()`
    // @see https://redux-saga.js.org/docs/basics/DispatchingActions.html
    const key = yield select(getKey);

    // Fetch user informations from an API
    try {
        //RemoveCall
        yield ApiService.authorizedClient(key).post('/BookmarkCollection/' + listID + '/toggle/remove/' + productID, {});
        yield fetchCollections();
    } catch (e) {
        yield put(BookmarkCollectionAction.fetchCollectionsFailure(e.message));
    }
}

/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* updateOrCreateCollection({listID, title}) {
    // Dispatch a redux action using `put()`
    // @see https://redux-saga.js.org/docs/basics/DispatchingActions.html
    const key = yield select(getKey);
    const memberID = yield select(getID);
    if (!listID) {
        try {
            //CreateCall
            yield ApiService.authorizedClient(key).post('/BookmarkCollection/', {Title: title, MemberID: memberID});
            yield fetchCollections();
        } catch (e) {
            yield put(BookmarkCollectionAction.fetchCollectionsFailure(e.message));
        }
    } else {
        try {
            //UpdateCall
            yield ApiService.authorizedClient(key).put('/BookmarkCollection/' + listID, {Title: title});
            yield fetchCollections();
        } catch (e) {
            yield put(BookmarkCollectionAction.fetchCollectionsFailure(e.message));
        }
    }
}


/**
 * A saga can contain multiple functions.
 *
 * This example saga contains only one to fetch fake user informations.
 * Feel free to remove it.
 */
export function* deleteCollection({listID}) {
    // Dispatch a redux action using `put()`
    // @see https://redux-saga.js.org/docs/basics/DispatchingActions.html
    const key = yield select(getKey);
    if (listID) {
        try {
            //CreateCall
            yield ApiService.authorizedClient(key).delete('/BookmarkCollection/' + listID);
            yield fetchCollections();
        } catch (e) {
            yield put(BookmarkCollectionAction.fetchCollectionsFailure(e.message));
        }
    } else {
        yield put(BookmarkCollectionAction.fetchCollectionsFailure('Keine Liste ausgewählt'));
    }
}
