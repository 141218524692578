import React, {Component} from 'react';
import {Alert, Form, Spinner} from "react-bootstrap";
import UserActions from "../../stores/user/actions";
import {connect} from "react-redux";
import {ApiService} from "../../services/ApiService";
import {toastr} from "react-redux-toastr";

class ProfilData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memberID: 0,
            firstName: '',
            surname: '',
            email: '',
            firstNameError: '',
            surnameError: '',
            emailError: '',
            password: '',
            passwordError: '',
            passwordRepeat: '',
            passwordRepeatError: '',
            loading: true,
            errorMain: '',
            succesMain: '',
            errorPWD: '',
            succesPWD: ''
        };

        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleSurnameChange = this.handleSurnameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleMainDataSubmit = this.handleMainDataSubmit.bind(this);
        this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePasswordRepeatChange = this.handlePasswordRepeatChange.bind(this);
    }

    handleFirstNameChange(e) {
        let text = e.target.value;
        this.setState({firstName: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({firstNameError: 'Bitte gib einen gültigen Vornamen ein'});
        } else {
            this.setState({firstNameError: ''});
        }
        this.setState({succesMain: ''});
    }

    handleSurnameChange(e) {
        let text = e.target.value;
        this.setState({surname: text});
        if (text.match(/^[^0-9\n]+$/) === null) {
            this.setState({surnameError: 'Bitte gib einen gültigen Nachname ein!'});
        } else {
            this.setState({surnameError: ''});
        }
        this.setState({succesMain: ''});
    }

    handleEmailChange(e) {
        let text = e.target.value;
        this.setState({email: text});
        let emailValidation = text.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);
        if (emailValidation === null) {
            this.setState({emailError: 'Bitte gib eine gültige E-Mail Adresse ein!'});
            ;
        } else {
            this.setState({emailError: ''});
        }
        this.setState({succesMain: ''});
    }

    onKeyDownMain = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.handleMainDataSubmit();
        }
    }

    onKeyDownPWD = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.handlePasswordSubmit();
        }
    }

    handleMainDataSubmit() {
        if (
            this.state.firstNameError ||
            this.state.surnameError ||
            this.state.emailError
        ) {
            this.setState({errorMain: 'Überprüfe deine Eingabe'});
            return;
        }
        if (
            this.state.firstName.length <= 0 ||
            this.state.surname.length <= 0 ||
            this.state.email.length <= 0
        ) {
            this.setState({errorMain: 'Bitte fülle alle Felder aus!'});
            return;
        }

        const me = this;
        me.setState({loading: true});
        ApiService.authorizedClient(this.props.authKey).put(
            '/Member/' + this.state.memberID,
            {
                'FirstName': me.state.firstName,
                'Surname': me.state.surname,
                'Email': me.state.email,
            }
        ).then(
            () => {
                me.setState({loading: false, errorMain: '', page: 2});
                this.props.fetchUser();
                //this.setState({succesMain: 'Die Daten wurden erfolgreich geändert'});
                toastr.success('Erfolgreich', 'Die Daten wurden erfolgreich geändert')
            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                me.setState({loading: false});
                toastr.success('Hoppla', errorMsg)
            },
        );
    }

    handlePasswordChange(e) {
        let text = e.target.value;
        this.setState({password: text});
        if (text.length < 8) {
            this.setState({passwordError: 'Das Passwort muss mindestens 8 Zeichen beinhalten!'});
        } else {
            this.setState({passwordError: ''});
        }
    }

    handlePasswordRepeatChange(e) {
        let text = e.target.value;
        this.setState({passwordRepeat: text});
        if (text !== this.state.password) {
            this.setState({passwordRepeatError: 'Die Passwörter stimmen nicht überein!'});
        } else {
            this.setState({passwordRepeatError: ''});
        }

    }

    handlePasswordSubmit() {
        if (
            this.state.passwordError ||
            this.state.passwordRepeatError
        ) {
            this.setState({errorPWD: 'Überprüfe deine Eingabe'});
            return;
        }
        if (
            this.state.password.length <= 0 ||
            this.state.passwordRepeat.length <= 0
        ) {
            this.setState({errorPWD: 'Bitte fülle alle Felder aus!'});
            return;
        }

        const me = this;
        me.setState({loading: true});
        ApiService.authorizedClient(this.props.authKey).put(
            '/Member/' + this.state.memberID,
            {
                'Password': me.state.password,
            }
        ).then(
            () => {
                me.setState({loading: false, errorPWD: '', page: 2});
                this.props.fetchUser();
                toastr.success('Erfolgreich', 'Dein Passwort wurde geändert')
            },
            (error) => {
                let errorMsg = 'Ein unbekannter Fehler ist aufgetreten!';
                if (error.response && error.response.data && error.response.data.message) {
                    errorMsg = error.response.data.message
                }
                me.setState({loading: false});
                this.setState({errorPWD: ''});
                toastr.error('Hoppla', errorMsg)
            },
        );
    }

    componentDidMount() {
        this.props.fetchUser();
        this.setState({
            memberID: this.props.memberID,
            firstName: this.props.user.FirstName,
            surname: this.props.user.Surname,
            email: this.props.user.Email,
            loading: false,
        });
    }

    render() {
        return (
            <div className={'p-4 bg-white shadow-sm ' + (this.state.loading ? 'h-100 d-flex' : '')}>
                {this.state.loading ?
                    <Spinner animation="border" role="status" className='mx-auto my-3 d-block align-self-center'>
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                    :
                    <>
                        <h4 className="font-weight-bold mt-0 mb-4">Meine Kontaktdaten</h4>
                        <Form>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputFirstName"
                                    placeholder="Dein Vorname"
                                    onChange={this.handleFirstNameChange}
                                    disabled={this.state.loading}
                                    value={this.state.firstName}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputFirstName">Dein Vorname</Form.Label>
                                <p className='text-danger m-0'>{this.state.firstNameError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="text"
                                    id="inputLastName"
                                    placeholder="Dein Nachname"
                                    onChange={this.handleSurnameChange}
                                    disabled={this.state.loading}
                                    value={this.state.surname}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputLastName">Dein Nachname</Form.Label>
                                <p className='text-danger m-0'>{this.state.surnameError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="email"
                                    id="inputEmail"
                                    placeholder="Deine E-Mail Adresse"
                                    onChange={this.handleEmailChange}
                                    disabled={this.state.loading}
                                    value={this.state.email}
                                    onKeyDown={this.onKeyDownMain}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputEmail">Deine E-Mail
                                    Adresse</Form.Label>
                                <p className='text-danger m-0'>{this.state.emailError}&nbsp;</p>
                            </div>
                            {/* <Form.Check
                                                className='mb-3'
                                                custom
                                                type="checkbox"
                                                id="custom-checkbox"
                                                label="Passwort merken"
                                            />*/}
                            {this.state.errorMain ?
                                <Alert variant="danger" className='mt-3'>{this.state.errorMain}</Alert>
                                : null
                            }
                            <div onClick={this.handleMainDataSubmit}
                                 className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2 col-lg-6 offset-lg-3">Speichern
                            </div>
                        </Form>
                        <h4 className="font-weight-bold mt-5 mb-4">Passwort ändern</h4>
                        <Form>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="password"
                                    id="inputPassword"
                                    placeholder="Passwort"
                                    onChange={this.handlePasswordChange}
                                    disabled={this.state.loading}
                                    value={this.state.password}
                                    className='input-foreground'
                                />
                                <Form.Label htmlFor="inputPassword">Passwort</Form.Label>
                                <p className='text-danger m-0'>{this.state.passwordError}&nbsp;</p>
                            </div>
                            <div className="form-label-group m-0">
                                <Form.Control
                                    type="password"
                                    id="inputPasswordRepeat"
                                    placeholder="Passwort wiederholen"
                                    onChange={this.handlePasswordRepeatChange}
                                    disabled={this.state.loading}
                                    value={this.state.passwordRepeat}
                                    className='input-foreground'
                                    onKeyDown={this.onKeyDownPWD}
                                />
                                <Form.Label htmlFor="inputPasswordRepeat">Passwort
                                    wiederholen</Form.Label>
                                <p className='text-danger m-0'>{this.state.passwordRepeatError}&nbsp;</p>
                            </div>
                            {this.state.errorPWD ?
                                <Alert variant="danger" className='mt-3'>{this.state.errorPWD}</Alert>
                                : null
                            }
                            <div onClick={this.handlePasswordSubmit}
                                 className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2 col-lg-6 offset-lg-3">Speichern
                            </div>
                        </Form>
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.user.isLoggedIn,
    user: state.user.user,
    authKey: state.user.authKey,
    memberID: state.user.memberID,
    userIsLoading: state.user.userIsLoading,
    userErrorMessage: state.user.userErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUser: () => dispatch(UserActions.fetchUser()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfilData);
