import axios from 'axios';

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 15000,
});

const baseClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_NO_API,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 15000,
});

const authorizedClient = (key) => axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorize': key,
    },
    timeout: 15000,
});

const vatClient = axios.create({
    baseURL: process.env.REACT_APP_VAT_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 15000,
});

const geocodeFromLatLng = (lat, lng) => {
    const client = axios.create({
        baseURL: process.env.REACT_APP_GEO_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        timeout: 15000,
    })
    return client.get('?latlng=' + lat + ',' + lng);
}

const getAddressObject = (data) => {
    let address = {};
    data.forEach((item) => {
        address[item.types[0]] = item
    })

    return address;
}

const serialize = (obj, prefix) => {
    let str = [],
        p;

    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            let k = prefix ? prefix + '[' + p + ']' : p,
                v = obj[p];

            str.push((v !== null && typeof v === 'object') ?
                serialize(v, k) :
                encodeURIComponent(k) + '=' + encodeURIComponent(v));
        }
    }

    return str.join('&');
};
const stripClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_STRIPE,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 15000,
});

export const ApiService = {
    client,
    baseClient,
    vatClient,
    authorizedClient,
    serialize,
    geocodeFromLatLng,
    getAddressObject,
    stripClient
};
